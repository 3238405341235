import {
  saveListBankAccounts,
  saveCountBankAccounts,
  deleteSelectedBankAccount,
  savePostListBankAccounts,
  saveSelectedBankAccount,
} from "../Infrastructure/CompaniesBankDetails.store";
import {
  getByCompanyLogic,
  getBankAccountsLogic,
  deleteBankAccountLogic,
  postBankAccountsLogic,
  getBankAccountLogic,
} from "./CompaniesBankDetails.logic";

export async function getBankAccounts(params) {
  const bankAccounts = await getBankAccountsLogic(params);
  saveListBankAccounts(bankAccounts);
}
export async function getByCompany(id) {
  const { count, resources: bankAccounts } = await getByCompanyLogic(id);
  saveListBankAccounts(bankAccounts);
  saveCountBankAccounts(count);
}

export async function deleteBankAccount(id) {
  await deleteBankAccountLogic(id);
  deleteSelectedBankAccount(id);
}
export async function getBankAccount(id) {
  const bankAccount = await getBankAccountLogic(id);
  saveSelectedBankAccount(bankAccount);
}
export async function postBankAccounts(params) {
  const bankAccounts = await postBankAccountsLogic(params);
  savePostListBankAccounts(bankAccounts);
}
