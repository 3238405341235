import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Col, Form, Row, Select } from "antd";
import Button from "../../Layout/DesignSystem/Button/Button";
import { Title } from "../../Utils/UI/Text";
import { getUsersSelector } from "../Infrastructure/Users.reducer";
import { getUsers, getUser } from "../Application/Users.business";

const { Option } = Select;

const styles = {
  background: {
    margin: "0.7em 0em",
    padding: "1.2em",
    background: "#fafafa",
    color: "rgba(0, 0, 0, 0.85)",
    fontWeight: 500,
    textAlign: "left",
  },
  label: {
    fontFamily: "MontserratSemiBold",
  },
};

export default function Searcher() {
  const records = useSelector(getUsersSelector);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [selectedUserId, setSelectedUserId] = useState(null);

  const onFinish = async ({ id }) => {
    getUser(selectedUserId);
    navigate(`/users/${selectedUserId}`);
  };

  const onReset = () => {
    form.resetFields();
    setSelectedUserId(null);
  };
  const handleSelectChange = (value) => {
    setSelectedUserId(value);
  };

  function onSearch(val) {
    getUsers({ filter: { q: val } });
  }

  return (
    <>
      <Row>
        <Title>Usuarios</Title>
        <Col span={24}>
          <div style={styles.background}>
            <Form
              layout="horizontal"
              form={form}
              onFinish={onFinish}
              onReset={onReset}
            >
              <div className="grid-searcher-user">
                <div>
                  <div className="searcher-user">
                    <div>
                      <Select
                        className="searcher-user-select"
                        name="id"
                        clearIcon
                        showSearch
                        onSearch={onSearch}
                        placeholder="Selecciona un usuario"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={handleSelectChange}
                      >
                        {records?.map((record) => (
                          <Option value={record.id} key={record.id}>
                            {`${record.email} (${record.name} ${record.lastname})`}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div className="searcher-user-button">
                      <Button htmlType="submit" text={"Seleccionar"} />
                    </div>
                  </div>
                </div>
                <div></div>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </>
  );
}
