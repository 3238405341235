import {
  getTechnologyLogic,
  deleteTechnologyLogic,
  deleteAllTechnologyLogic,
} from "./FetchData.logic";

export async function getTechnology(params) {
  const technology = await getTechnologyLogic(params);
  return technology;
}
export async function deleteTechnology(params) {
  const technology = await deleteTechnologyLogic(params);
  return technology;
}
export async function deleteAllTechnology(params) {
  const technology = await deleteAllTechnologyLogic(params);
  return technology;
}
