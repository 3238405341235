import { useNavigate } from "react-router-dom";
import { logout } from "../Application/Auth.logic";
import { message } from "antd";

const Logout = () => {
  const navigate = useNavigate();

  const onclick = async () => {
    await logout();
    message.success("Cierre de sesión exitoso");
    navigate("/login");
  };

  return {
    onclick,
  };
};

export default Logout;
