class Ipfs {
    constructor({ _id, key,hash, type, url,name,description,project }) {
      this.id = _id;
      this.key = key;
      this.hash = hash;
      this.type = type;
      this.url = url;
      this.name= name;
      this.description= description;
      this.project=project;
    }
  }
  export default Ipfs;
  