import { useState, useEffect } from "react";
import { Form, Input, Row, message, DatePicker, Select, Button } from "antd";
// import { useDispatch } from "react-redux";
import { updateScriptOffset } from "../Application/ScriptOffsets.business";
import { getScriptOffsetByUser } from "../Application/ScriptOffsets.business";

import moment from "moment";

const ScriptOffsetsDetail = ({
  form,
  record,
  userId,
  Offid,
  currentPagination,
  setIsModalVisible,
}) => {
  const [values, setValues] = useState({
    consumption: "",
    factor: "",
    total_co2_kg: "",
    total_co2_tons: "",
    scope: "",
    accountingDate: null,
    amountTotal: "",
    subScope: "",
    subCategory: "",
    area: "",
    project: "",
  });

  const [loading, setLoading] = useState(false);
  // const dispatch = useDispatch();
  useEffect(() => {
    if (userId) {
      getScriptOffsetByUser(userId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, getScriptOffsetByUser]);

  useEffect(() => {
    if (record) {
      setValues({
        consumption: record.consumption || "",
        factor: record.factor || "",
        total_co2_kg: record.total_co2_kg || "",
        total_co2_tons: record.total_co2_tons || "",
        scope: record.scope || "",
        accountingDate: record.accountingDate || null,
        amountTotal: record.amountTotal || "",
        subScope: record.subScope || "",
        subCategory: record.subCategory || "",
        area: record.area || "",
        project: record.project || "",
      });

      form.setFieldsValue({
        consumption: record.consumption || "",
        factor: record.factor || "",
        total_co2_kg: record.total_co2_kg || "",
        total_co2_tons: record.total_co2_tons || "",
        scope: record.scope || "",
        accountingDate: record.accountingDate
          ? moment(record.accountingDate)
          : null,
        amountTotal: record.amountTotal || "",
        subScope: record.subScope || "",
        subCategory: record.subCategory || "",
        area: record.area || "",
        project: record.project || "",
      });
    }
  }, [record, form]);

  const onHandleChange = (e) => {
    setValues((oldValues) => ({
      ...oldValues,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSelectorChange = (value) => {
    setValues({
      ...values,
      scope: value || "",
    });
  };

  const onHandleChangeDate = (input) => (date, dateString) => {
    setValues({
      ...values,
      [input]: dateString,
    });
  };

  const handleUpdate = async () => {
    setLoading(true);

    const data = {
      consumption: values.consumption,
      factor: parseFloat(values.factor) || 0,
      total_co2_kg: parseFloat(values.total_co2_kg) || 0,
      total_co2_tons: parseFloat(values.total_co2_tons) || 0,
      scope: values.scope || "scope 1",
      amountTotal: parseFloat(values.amountTotal) || 0,
    };
    if (values.accountingDate) {
      data.accountingDate = moment(values.accountingDate).format("YYYY-MM-DD");
    }
    if (values.subScope?.trim()) {
      data.subScope = values.subScope;
    }
    if (values.subCategory?.trim()) {
      data.subCategory = values.subCategory;
    }
    if (values.area?.trim()) {
      data.area = values.area;
    }
    if (values.project?.trim()) {
      data.project = values.project;
    }
    try {
      await updateScriptOffset(Offid, data);
      message.success("Script actualizado con éxito");
      setIsModalVisible(false);
      getScriptOffsetByUser(userId, currentPagination);
    } catch (error) {
      message.error("Error al actualizar el script offset");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Row type="flex" justify="center" align="middle">
        <Form
          name="basic"
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          form={form}
          style={{ width: "100%" }}
        >
          <div className="grid-form-scriptOffsets">
            <div>
              <label className="grid-label-scriptOffsets">Consumo:</label>
              <Input
                name="consumption"
                value={values.consumption}
                onChange={onHandleChange}
              />
            </div>
            <div>
              <label className="grid-label-scriptOffsets">Factor:</label>
              <Input
                name="factor"
                value={values.factor}
                onChange={onHandleChange}
              />
            </div>
            <div>
              <label className="grid-label-scriptOffsets">
                KG Totales CO₂:
              </label>
              <Input
                name="total_co2_kg"
                value={values.total_co2_kg}
                onChange={onHandleChange}
              />
            </div>
            <div>
              <label className="grid-label-scriptOffsets">
                Ton. Totales de CO₂:
              </label>
              <Input
                name="total_co2_tons"
                value={values.total_co2_tons}
                onChange={onHandleChange}
              />
            </div>
            <div>
              <label className="grid-label-scriptOffsets">Scope:</label>
              <Select
                style={{ width: "100%" }}
                name="scope"
                value={values?.scope}
                options={[
                  { value: "scope 1", label: "Scope 1" },
                  { value: "scope 2", label: "Scope 2" },
                  { value: "scope 3", label: "Scope 3" },
                ]}
                onChange={handleSelectorChange}
                allowClear
              />
            </div>
            <div>
              <label className="grid-label-scriptOffsets">Fecha:</label>
              <DatePicker
                style={{ width: "100%" }}
                name="accountingDate"
                value={
                  values.accountingDate ? moment(values.accountingDate) : null
                }
                onChange={onHandleChangeDate("accountingDate")}
              />
            </div>
            <div>
              <label className="grid-label-scriptOffsets">Monto total:</label>
              <Input
                name="amountTotal"
                value={values.amountTotal}
                onChange={onHandleChange}
              />
            </div>
            <div>
              <label className="grid-label-scriptOffsets">Categoría:</label>
              <Input
                name="subScope"
                value={values.subScope}
                onChange={onHandleChange}
              />
            </div>
            <div>
              <label className="grid-label-scriptOffsets">Subcategoría:</label>
              <Input
                name="subCategory"
                value={values.subCategory}
                onChange={onHandleChange}
              />
            </div>
          </div>
          <div style={{ textAlign: "center" }}>
            <Button
              type="primary"
              onClick={handleUpdate}
              loading={loading}
              style={{ marginTop: "20px" }}
            >
              Actualizar
            </Button>
          </div>
        </Form>
      </Row>
    </>
  );
};

export default ScriptOffsetsDetail;
