import React from "react";
import { Card, Col, Row, Typography } from "antd";
import NewAreas from "./NewAreas";
import AreasBoard from "./AreasBoard";

const { Text } = Typography;
const styles = {
  text: {
    fontFamily: "MontserratBold",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "24px",
    color: "#041370",
  },
};
const Areas = () => {
  return (
    <div className="grid-emission">
      <div>
        <Card className="card-content">
          <Row>
            <Col
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 20,
              }}
              span={24}
            >
              <Text style={styles.text}>Areas</Text>
              <NewAreas />
            </Col>
          </Row>
          <AreasBoard />
        </Card>
      </div>
    </div>
  );
};

export default Areas;
