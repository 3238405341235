import React, { useEffect, useState } from "react";
import FormItem from "antd/lib/form/FormItem";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Form, Input, Button, message } from "antd";
import "antd/dist/antd.min.css";
import "./companiesBankDetails.css";
import { postBankAccount } from "../Infrastructure/CompaniesBankDetails.service";
import {
  getBankAccounts,
  getBankAccount,
} from "../Application/CompaniesBankDetails.business";
import Document from "../../DocumentUpload/Domain/Document";
import DocumentUpload from "../../DocumentUpload/UI/DocumentUpload";

import { getSelectedDocumentSelector } from "../../DocumentUpload/Infrastructure/DocumentUpload.reducer";

const UploadCompaniesBankDetails = () => {
  const { id } = useParams();
  const document = useSelector(getSelectedDocumentSelector);

  const [form] = Form.useForm();
  useEffect(() => {
    getBankAccounts();
    if (id) {
      getBankAccount(id);
    }
  }, [id]);

  const [values, setValues] = useState({
    owner: "",
    bank_name: "",
    sort_code: "",
    account_number: "",
    swiftbic: "",
    iba: "",
    user: id,
  });

  const [enabledButton, setEnabledButton] = useState(false);
  const [idDocument, setIdDocument] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    const { id } = document || {};
    setIdDocument(id);
    setEnabledButton(values.owner && values.bank_name && id);
  }, [values, document]);

  const onHandleChange = (input) => (e) => {
    setValues({
      ...values,
      [input]: e.target.value,
    });
  };

  const onReset = () => {
    form.resetFields();
  };

  async function handleSubmit() {
    const { owner, bank_name, sort_code, account_number, swiftbic, iba, user } =
      values;
    try {
      await postBankAccount({
        owner,
        bank_name,
        sort_code,
        account_number,
        swiftbic,
        iba,
        user,
        document: idDocument,
      });
      setValues({ ...values, visible: false });
      message.success("Se agregó una nuevo documento BankAccounts ");
    } catch (error) {
      message.error(" Se produjo un error, vuelve a intentarlo");
    }
    dispatch(getBankAccounts());
  }

  return (
    <Form
      name="basic"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      autoComplete="off"
      form={form}
    >
      <FormItem label="Owner" name="owner" hasFeedback>
        <Input
          value={values.owner}
          name={"owner"}
          onChange={onHandleChange("owner")}
          placeholder="Owner"
          maxLength={50}
          schema={{ default: "", type: "string" }}
        />
      </FormItem>
      <FormItem label="Bank name" name="bank_name" hasFeedback>
        <Input
          value={values.bank_name}
          name={"bank_name"}
          onChange={onHandleChange("bank_name")}
          placeholder="Bank_name"
          maxLength={50}
          schema={{ default: "", type: "string" }}
        />
      </FormItem>
      <FormItem label="Sort code" name="sort_code" hasFeedback>
        <Input
          value={values.sort_code}
          name={"sort_code"}
          onChange={onHandleChange("sort_code")}
          placeholder="Sort code"
          maxLength={50}
          schema={{ default: "", type: "string" }}
        />
      </FormItem>
      <FormItem label="Account N°" name="account_number" hasFeedback>
        <Input
          value={values.account_number}
          name={"account_number"}
          onChange={onHandleChange("account_number")}
          placeholder="Account N°"
          maxLength={50}
          schema={{ default: "", type: "string" }}
        />
      </FormItem>
      <FormItem label="Swiftbic" name="swiftbic" hasFeedback>
        <Input
          value={values.swiftbic}
          name={"swiftbic"}
          onChange={onHandleChange("swiftbic")}
          placeholder="Swiftbic"
          maxLength={50}
          schema={{ default: "", type: "string" }}
        />
      </FormItem>
      <FormItem label="IBA" name="iba" hasFeedback>
        <Input
          value={values.iba}
          name={"iba"}
          onChange={onHandleChange("iba")}
          placeholder="IBA"
          maxLength={50}
          schema={{ default: "", type: "string" }}
        />
      </FormItem>
      <FormItem label={"Documento"}>
        <div className="document">
          <DocumentUpload
            type={Document.BANK_ACCOUNT_DETAILS}
            id={id}
            buttonText="Seleccionar Archivo "
          />
        </div>
      </FormItem>
      <Form.Item className={"content"}>
        <div>
          <div className="button">
            <Button
              type="primary"
              htmlType="submit"
              disabled={!enabledButton}
              onClick={function () {
                handleSubmit();
                onReset();
              }}
            >
              Agregar!
            </Button>
          </div>
        </div>
      </Form.Item>
    </Form>
  );
};
export default UploadCompaniesBankDetails;
