import store from "../../Redux/store";
import {
  setBankAccounts,
  selectBankAccount,
  countBankAccounts,
  deleteBankAccount,
  addBankAccounts,
} from "./CompaniesBankDetails.reducer";

export function saveListBankAccounts(records) {
  store.dispatch(setBankAccounts(records));
}

export function saveSelectedBankAccount(bankAccount) {
  store.dispatch(selectBankAccount(bankAccount));
}

export function saveCountBankAccounts(count) {
  store.dispatch(countBankAccounts(count));
}
export function deleteSelectedBankAccount(id) {
  store.dispatch(deleteBankAccount(id));
}
export function savePostListBankAccounts(records) {
  store.dispatch(addBankAccounts(records));
}
