import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "areas",
  initialState: {
    list: [],
  },
  reducers: {
    setAreas: (state, { payload: list }) => {
      return {
        ...state,
        list,
      };
    },
    deleteArea: (state, { payload: id }) => {
      return {
        ...state,
        list: [...state.list.filter((elem) => elem.id !== id)],
      };
    },
  },
});

export const { setAreas, deleteArea } = slice.actions;

export default slice.reducer;

export const getAreasSelector = (store) => store.areas.list;
export const deleteAreaSelector = (store) => store.areas.id;
