import Response from "../../Data/Domain/Response";
import Admin from "../Domain/Administrator";
import { getAdmins, getAdmin } from "./Administrator.service";

export async function getAdminsRepository(params) {
  const data = await getAdmins(params);
  return new Response(data, Admin).getMultiple().resources;
}

export async function getAdminRepository(id) {
  const data = await getAdmin(id);
  return new Response(data, Admin).getSingle();
}
