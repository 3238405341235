import Response from "../../Data/Domain/Response";
import BankAccount from "../Domain/CompaniesBankDetails";
import {
  getBankAccounts,
  getByCompany,
  deleteBankAccount,
  postBankAccount,
  getBankAccount,
} from "./CompaniesBankDetails.service";

export async function getBankAccountsRepository(params) {
  const data = await getBankAccounts(params);
  return new Response(data, BankAccount).getMultiple().resources;
}
export async function getByCompanyRepository(id) {
  const data = await getByCompany(id);
  return new Response(data, BankAccount).getMultiple();
}
export async function deleteBankAccountRepository(id) {
  const data = await deleteBankAccount(id);
  return new Response(data).onlyStatus();
}
export async function postBankAccountsRepository(params) {
  const data = await postBankAccount(params);
  return new Response(data, BankAccount).getMultiple().resources;
}
export async function getBankAccountRepository(id) {
  const data = await getBankAccount(id);
  return new Response(data, BankAccount).getSingle();
}
