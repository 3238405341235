import Formatter from "../../Data/Domain/Formatter";
import Commission from "./Commission";

class Commissions {
  constructor({ total, bonds, offers, others = 0, count = 0, resources = [] }) {
    this.total = total;
    this.bonds = bonds;
    this.offers = offers;
    this.others = others;
    this.count = count;
    this.resources = resources;
    this.setResources();
  }

  get calculateTotal() {
    return `${Formatter.round(this.total)}`;
  }

  get calculateBonds() {
    return `${Formatter.round(this.bonds)}`;
  }

  get calculateOffer() {
    return `${Formatter.round(this.offers)}`;
  }

  get calculateOthers() {
    return `${Formatter.round(this.others)}`;
  }

  setResources() {
    this.resources = this.resources.map(
      (commission) => new Commission(commission)
    );
  }
}
export default Commissions;
