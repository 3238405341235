import Response from "../../Data/Domain/Response";
import Collaborator from "../Domain/Collaborator";
import {
  getCollaborators,
  getByCompany,
  deleteCollaborator,
  revokeCollaborator,
} from "./Collaborators.service";

export async function getCollaboratorsRepository(params) {
  const data = await getCollaborators(params);
  return new Response(data, Collaborator).getMultiple();
}

export async function getByCompanyRepository() {
  const data = await getByCompany();
  return new Response(data, Collaborator).getMultiple().resources;
}
export async function deleteCollaboratorRepository(id) {
  const data = await deleteCollaborator(id);
  return new Response(data).onlyStatus();
}
export async function revokeCollaboratorRepository(id, params) {
  const data = await revokeCollaborator(id, params);
  return new Response(data).onlyStatus();
}
