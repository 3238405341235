import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Col, Row, Typography, Card, Modal, message } from "antd";
import { ArrowDownOutlined } from "@ant-design/icons";
import "./Saving.css";
import OffsetData from "../../OffssetData/OffsetData";
import { getReductionsSelector } from "../Infrastructure/Saving.reducer";
import { getReductions, deleteReduction } from "../Application/Saving.business";

const styles = {
  container: {
    marginBottom: 50,
    marginTop: 30,
  },
  ton: {
    fontFamily: "MontserratBold",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "24px",
    color: "#041370",
  },
  down: {
    color: "#00A389",
    fontSize: 15,
    fontWeight: "bold",
  },
};

const { Text } = Typography;

const Saving = () => {
  const reductions = useSelector(getReductionsSelector);
  // const count = useSelector(getReductionsCountSelector);

  useEffect(() => {
    getReductions();
  }, []);

  const handleDelete = async (id) => {
    try {
      Modal.confirm({
        title: "Está seguro de que desea eliminar la reducción?",
        okText: "Si",
        okType: "danger",
        onOk: () => {
          deleteReduction(id);
          message.success("Se eliminó la reducción");
        },
      });
    } catch (error) {
      message.error("Se produjo un error, vuelva a intentarlo");
    }
  };

  return (
    <div className="grid-emission">
      <div>
        <Card className="card-content">
          <Row style={styles.container}>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Text style={styles.ton}>Reduction Targets</Text>
            </Col>
          </Row>

          <Row gutter={5}>
            {reductions.map(({ id, quantity, name, percent, items }) => (
              <Col xs={24} sm={24} md={24} lg={24}>
                <OffsetData
                  quantity={quantity}
                  name={name}
                  items={items}
                  id={id}
                  percent={percent}
                  arrowDown={<ArrowDownOutlined style={styles.down} />}
                  icons={true}
                  deleteIcon={true}
                  actions={true}
                  handleDelete={() => handleDelete(id)}
                />
              </Col>
            ))}
          </Row>
        </Card>
      </div>
    </div>
  );
};

export default Saving;
