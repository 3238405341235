import API from "../../Data/Domain/API";
import URL from "../../Data/Domain/URL";

export async function getProjects(params) {
  return await new API(URL.setParams(URL.buildUrl("projects"), params)).get();
}

export async function getProject(id) {
  return await new API(URL.setId(URL.buildUrl("projects"), id)).get();
}

export async function updateBonds(id, data) {
  return await new API(URL.buildUrl(`projects/${id}/carbonCredits`)).put(data);
}

export async function updateProject(id, data) {
  return await new API(URL.buildUrl(`projects/${id}`)).put(data);
}

export async function updateCoordinates(id, data) {
  return await new API(URL.buildUrl(`projects/${id}/coordinates`)).post(data);
}

export async function patchProject(id, info) {
  const url = URL.setId(URL.buildUrl("projects"), id);
  return await new API(url).patch(info);
}

export async function postProject({
  name,
  standard_id,
  is_private,
  country,
  co2Goal,
  ton_price,
  add_on,
  technology,
  vintage,
  url,
  url_file,
  area,
  biomass,
  co2_annual_estimated,
  contract_start_date,
  contract_end_date,
  description,
  green_project_type,
  region,
  volume,
  ton_co2_issued,
  province,
}) {
  return await new API(URL.buildUrl("projects")).post({
    project_name: name,
    real_name: name,
    comercial_name: name,
    standard_id,
    is_private,
    country,
    co2Goal,
    ton_price,
    add_on,
    technology,
    vintage,
    url,
    url_file,
    area,
    biomass,
    co2_annual_estimated,
    contract_start_date,
    contract_end_date,
    description,
    green_project_type,
    region,
    volume,
    ton_co2_issued,
    province,
  });
}

export async function postContactProjects(id, data) {
  return await new API(URL.buildUrl(`projects/${id}/contacts`)).post(data);
}
export async function getProjectIpfs(id) {
  return await new API(URL.buildUrl(`projects/${id}/ipfs`)).get();
}
export async function removeContact(id, email) {
  return await new API(
    URL.buildUrl(`projects/${id}/contacts/${email}`)
  ).delete();
}

export async function postIpfs({ name, type,description,hash,url,project}) {
  return await new API(URL.buildUrl("ipfs")).post({
    name,
    type,
    description,
    hash,
    url ,
    project
  });
}
export async function deleteFileIpfs(id, type) {
  console.log( `projects/${id}/ipfs/${type}`);
  return await new API(
    URL.buildUrl(`projects/${id}/ipfs/${type}`)
  ).delete();
}
export async function postImpacts({ id, title, subtitle }) {
  return await new API(URL.buildUrl(`projects/${id}/impacts`)).post({
    title,
    subtitle,
  });
}

export async function postSdgs({ id, title, data }) {
  return await new API(URL.buildUrl(`projects/${id}/sdgs`)).post({
    title,
    data,
  });
}
export async function postKeys({ id, name, data, type }) {
  if (type === "key" || type === "projectDescription") {
    return await new API(URL.buildUrl("keys")).post({
      name,
      data,
      type,
    });
  }
}
export async function getProjectImages(id) {
  return await new API(URL.buildUrl(`projects/${id}/images`)).get();
}

export async function deleteFileImage(id, id_image) {
  console.log( `projects/${id}/images/${id_image}`);
  return await new API(
    URL.buildUrl(`projects/${id}/images/${id_image}`)
  ).delete();
}

export async function getProjectTransactions(id, state=["accepted"], type = ["buy_nft", "buy_sbt"] ) {
  const stateData = JSON.stringify(state);
  const typeData = JSON.stringify(type);
 const url = URL.buildUrl(`projects/${id}/transactions?state=${stateData}&type=${typeData}`);
return await new API(url).get();
}