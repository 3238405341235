import React from "react";
import { DatePicker } from "antd";
import "antd/dist/antd.min.css";
import "./Picker.css";

const { RangePicker } = DatePicker;

const Picker = ({ onChange }) => {
  return <RangePicker className="picker" onChange={onChange} />;
};

export default Picker;
