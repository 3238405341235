import API from "../../Data/Domain/API";
import URL from "../../Data/Domain/URL";

export async function getAdmins(params) {
  return await new API(URL.setParams(URL.buildUrl("admins"), params)).get();
}

export async function getAdmin(id) {
  return await new API(URL.setId(URL.buildUrl("admins"), id)).get();
}

export async function postAdmin({ name, password, email }) {
  return await new API(URL.buildUrl("admins")).post({
    name,
    password,
    email,
  });
}
