import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "keys",
  initialState: {
    keys: []
  },
  reducers: {
    setKeys: (state, { payload: keys}) => {
      return {
        ...state,
        keys,
      };
    },
  },
});

export const { setKeys} = slice.actions;

export default slice.reducer;

export const getKeysSelector = (store) => store.keys.keys;
