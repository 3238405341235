import {
  getByCompanyLogic,
  deleteDocumentLogic,
  PostDocumentLogic,
  getDocumentByIdLogic,
  getByUserLogic,
  getAllLogic,
} from "./DocumentUpload.logic";
import {
  saveListDocuments,
  saveCountDocuments,
  deleteSelectedDocument,
  saveSelectedDocument,
} from "../Infrastructure/DocumentUpload.store";

export async function getDocumentById(id) {
  const document = await getDocumentByIdLogic(id);
  saveSelectedDocument(document);
  return document;
}

export async function saveDocument(params) {
  const document = await PostDocumentLogic(params);
  saveSelectedDocument(document);
}

export async function getByCompany(id) {
  const { count, resources: documents } = await getByCompanyLogic(id);
  saveListDocuments(documents);
  saveCountDocuments(count);
}

export async function deleteDocument(id) {
  await deleteDocumentLogic(id);
  deleteSelectedDocument(id);
}
export async function getByUser(id, params) {
  const { count, resources: documents } = await getByUserLogic(
    id, params  );
  saveListDocuments(documents);
  saveCountDocuments(count);
}
export async function getAll(type,params) {
  const { count, resources: documents } = await getAllLogic(
    type,params  );
  saveListDocuments(documents);
  saveCountDocuments(count);
}

