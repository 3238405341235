import Date from "../../Data/Domain/CustomDate";
import Formatter from "../../Data/Domain/Formatter";

class Commission {
  static get TYPE_DICT() {
    return {
      bonds: "Bonos de Carbono",
      offers: "Ofertas",
    };
  }
  constructor({ _id, date, user, fee, type }) {
    this.id = _id;
    this.date = new Date(date).formatted_date;
    this.user = user;
    this.type = type;
    this.fee = fee;
  }

  get typeValue() {
    return Commission.TYPE_DICT[this.type];
  }

  get calculateFee() {
    return this.type === Commission.TYPE_DICT["bonds"]
      ? Formatter.round(this.fee / 100)
      : Formatter.round(this.fee);
  }
}

export default Commission;
