class Reduction {
  constructor({
    _id,
    is_reduction,
    items = [],
    category,
    subcategory,
    user,
    name,
    percent,
    quantity,
    initial_date,
    final_date,
    assigned,
  }) {
    this.id = _id;
    this.is_reduction = is_reduction;
    this.items = items;
    this.category = category;
    this.subcategory = subcategory;
    this.user = user;
    this.name = name;
    this.percent = percent;
    this.quantity = quantity;
    this.initial_date = initial_date;
    this.final_date = final_date;
    this.assigned = assigned;
  }
}

export default Reduction;
