import {
  saveListCategories,
  saveCountCategories,
  saveSelectedCategory,
  saveSelectedSubcategory,
  saveListSubcategories,
  saveListSubcategoriesByCateg,
  saveListCategoriesByUser,
} from "../Infrastructure/Categories.store";
import {
  getCategoriesLogic,
  getCategoryLogic,
  updateSubcategoriesLogic,
  getSubcategoryLogic,
  getSubcategoriesByCategAndUserLogic,
  getCategoriesByUserLogic,
} from "./Categories.logic";

export async function getCategories(params) {
  const { resources: categories, count } = await getCategoriesLogic(params);
  saveListCategories(categories);
  saveCountCategories(count);
}

export async function getSubcategoriesData(id) {
  const { resources: subcategories } = await getCategoryLogic(id);
  saveSelectedCategory(id);
  saveListSubcategories(subcategories);
}
export async function updateSubcategory(id, params) {
  await updateSubcategoriesLogic(id, params);
  const subcategory = await getSubcategoryLogic(id);
  saveSelectedSubcategory(subcategory);
}
// export async function getSubcategory(id) {
//   const subcategory = await getSubcategoryLogic(id);
//   saveSelectedSubcategory(subcategory);
// }
export async function getSubcategoriesByCategAndUser(params) {
  const { resources: subcategories } =
    await getSubcategoriesByCategAndUserLogic(params);
  saveListSubcategoriesByCateg(subcategories);
}
export async function getCategoriesByUser(id) {
  const categories = await getCategoriesByUserLogic(id);
  saveListCategoriesByUser(categories);
}
