import React, { useEffect } from "react";
import { Form, Input, Button, Space, message } from "antd";
import { Col, Row, Select } from "antd";
import FormItem from "antd/lib/form/FormItem";
import { updateCompany } from "../../Companies/Infrastructure/Companies.service";
import DocumentUpload from "../../DocumentUpload/UI/DocumentUpload";
import Countries from "../../Countries/Countries";

const countries = new Countries().allCountries;
const { Option } = Select;
const children = [];

const { TextArea } = Input;

export default function CompaniesInformation(props) {
  const { user } = props;
  const [form] = Form.useForm();

  for (let key in countries) {
    let country = countries[key];
    children.push(<Option key={key}>{country}</Option>);
  }

  const handleSubmit = async () => {
    try {
      const extras = { ...user.companyExtras, ...form.getFieldsValue() };
      await updateCompany(user.id, { extras });
      message.success("Se actualizo los valores de compañía");
    } catch (error) {
      message.error(" Se produjo un error, vuelve a intentarlo");
    }
  };

  useEffect(() => {
    form.setFieldsValue(user.companyExtras);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Form
      initialValues={user.companyExtras}
      form={form}
      style={{ padding: "0 20px" }}
    >
      <Row>
        <Col xs={24} xl={12}>
          <FormItem label="Nombre Legal" name={"legal_name"}>
            <Input />
          </FormItem>
        </Col>
        <Col xs={24} xl={12}>
          <FormItem label="Client ID" name={"client_id"}>
            <Input />
          </FormItem>
        </Col>
        <Col xs={24} xl={12}>
          <FormItem label="País" name={"country"}>
            <Select
              style={{
                width: "100%",
              }}
              placeholder="Seleccione un país"
            >
              {children}
            </Select>
          </FormItem>
        </Col>
        <Space size={4} />
        <Col xs={24} xl={12}>
          <FormItem label="Lugar de operación" name={"operation_place"}>
            <Select
              mode="single"
              allowClear
              style={{
                width: "100%",
              }}
              placeholder="Seleccione un lugar"
            >
              {children}
            </Select>
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col xs={24} xl={12}>
          <FormItem label="Tipo de negocio" name={"business_type"}>
            <Input
              style={{
                width: "100%",
              }}
            />
          </FormItem>
        </Col>
        <Space size={4} />
        <Col xs={24} xl={12}>
          <FormItem label="Cant. de empleados" name={"employees_number"}>
            <Input
              type={"number"}
              min={0}
              style={{
                width: "100%",
              }}
            />
          </FormItem>
        </Col>
      </Row>
      <Form.Item
        label="Sitio web"
        name={"website"}
        rules={[
          {
            type: "url",
            warningOnly: true,
          },
        ]}
      >
        <Input placeholder="ej: https://egreen.com" />
      </Form.Item>

      <FormItem label="Descripción" name={"description"}>
        <TextArea showCount maxLength={100} />
      </FormItem>
      <FormItem label={"Acta constitutiva de persona jurídica."}>
        <DocumentUpload
          type={"registration"}
          id={user.id}
          buttonText="Seleccionar Archivo "
        />
      </FormItem>
      <FormItem label={"Certificado de vigencia de Sociedad."}>
        <DocumentUpload
          type={"last_statement"}
          id={user.id}
          buttonText="Seleccionar Archivo "
        />
      </FormItem>

      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button type="primary" htmlType="submit" onClick={handleSubmit}>
          Guardar
        </Button>
      </Form.Item>
    </Form>
  );
}
