import API from "../../Data/Domain/API";
import URL from "../../Data/Domain/URL";

export async function getTransactions(params) {
  return await new API(
    URL.setParams(URL.buildUrl("transactions"), params)
  ).get();
}

export async function getTransaction(id) {
  return await new API(URL.setId(URL.buildUrl("transactions"), id)).get();
}

export async function patchTransaction(id) {
  return await new API(URL.setId(URL.buildUrl("transactions"), id)).patch([
    {
      op: "replace",
      path: "/status",
      value: "accepted",
    },
  ]);
}

export async function postTransaction(transaction) {
  return await new API(URL.buildUrl("transactions")).post(transaction);
}

export async function checkPaySession({ lan, idPretransaction }) {
  return await new API(URL.buildUrl("check-pay-session")).post({
    lan,
    idPretransaction,
  });
}
