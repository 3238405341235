import { Button, notification } from "antd";
import "./Notification.css";
import "antd/dist/antd.min.css";

const Notification = (props) => {
  const [api, contextHolder] = notification.useNotification();
  const openNotification = () => {
    api.open({
      avatar: props.avatar,
      message: props.message,
      className: props.notificacionStyle,
      description: props.description,
      duration: 0,
    });
  };
  return (
    <div className={props.className}>
      {contextHolder}
      <Button
        className={"iconButton"}
        type={props.type}
        onClick={openNotification}
        icon={props.icon}
      />
    </div>
  );
};
export default Notification;
