import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import InterfaceTable from "../../Table/InterfaceTable";
import { message, Form, Modal, Tooltip } from "antd";
import Formatter from "../../Data/Domain/Formatter";
import ScriptOffsetsDetail from "./ScriptOffsetsDetail";
import {
  getScriptOffsetSelector,
  getScriptOffsetCountSelector,
} from "../Infrastructure/ScriptOffsets.reducer";
import moment from "moment";
import { EditOutlined } from "@ant-design/icons";

import { getScriptOffsetByUser } from "../Application/ScriptOffsets.business";
const ScriptOffsetsBoard = ({ userId, startDate, finishDate }) => {
  const scriptOffsets = useSelector(getScriptOffsetSelector);
  const totalCount = useSelector(getScriptOffsetCountSelector);
  // const dates = useRef([]);

  const [values, setValues] = useState({
    page: 0,
    limit: 5,
  });
  // const [filters, setFilters] = useState("false");

  const [loading, setLoading] = useState(false);
  const [isManual, setIsManual] = useState(null);
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const handleEdit = (record) => {
    setSelectedRecord(record);
    setIsModalVisible(true);
  };

  const columnsScript = [
    {
      title: "Clasificación manual",
      dataIndex: "is_manual",
      key: "is_manual",
      render: (is_manual) => (is_manual ? "SI" : "NO"),
      filters: [
        {
          text: "SI",
          value: true,
        },
        {
          text: "NO",
          value: false,
        },
      ],
    },

    {
      title: "ID del Proveedor",
      dataIndex: "supplierId",
      key: "supplierId",
    },
    {
      title: "Nombre de cuenta",
      dataIndex: "accountName",
      key: "accountName",
    },
    {
      title: "Consumo",
      dataIndex: "consumption",
      key: "consumption",
    },
    {
      title: "Monto total",
      dataIndex: "amountTotal",
      key: "amountTotal",
    },
    {
      title: "Scope",
      dataIndex: "scope",
      key: "scope",
    },
    {
      title: "Categoría",
      dataIndex: "subScope",
      key: "subScope",
    },
    {
      title: "Subcategoría",
      dataIndex: "subCategory",
      key: "subCategory",
    },
    {
      title: "Factor",
      dataIndex: "factor",
      key: "factor",
    },
    {
      title: "KG Totales CO₂",
      dataIndex: "total_co2_kg",
      key: "total_co2_kg",
    },
    {
      title: "Ton. Totales de CO₂",
      dataIndex: "total_co2_tons",
      key: "total_co2_tons",
    },
    {
      title: "Fecha",
      dataIndex: "accountingDate",
      key: "accountingDate",
      render: (accountingDate) => {
        const date = accountingDate
          ? moment(accountingDate).format("YYYY-MM-DD")
          : "Fecha no disponible";
        return date;
      },
    },

    {
      title: "Acciones",
      dataIndex: "",
      key: "",
      render: (_, record) => {
        return (
          <Tooltip title="Editar Script Offset">
            <EditOutlined
              style={{ color: "red" }}
              onClick={() => handleEdit(record)}
            />
          </Tooltip>
        );
      },
    },
  ];

  const fetchData = async (params = {}) => {
    try {
      const filters = {
        ...params,
        start_date: startDate,
        finish_date: finishDate,
      };

      if (isManual !== null) {
        filters.is_manual = isManual;
      }
      const combinedParams = { ...params, filter: filters };
      await getScriptOffsetByUser(userId, combinedParams);
      if (Object.entries(params).length > 0) message.success("Filtrado");
      setLoading(false);
    } catch (error) {
      message.error("error");
    }
  };

  useEffect(() => {
    if (userId) {
      fetchData({ page: values.page, limit: values.limit });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isManual, userId]);

  const handleRequest = (newPagination = {}, filters = {}, sorter = {}) => {
    const { current: page = 1, pageSize: limit = values.limit } = newPagination;
    const { field: field_sort, order: sortOrder } = sorter;
    const sort = sortOrder === "ascend" ? "ascending" : "descending";
    const isManualFilter = filters.is_manual ? filters.is_manual : null;

    const clean = Formatter.cleanParamsFromTableToAPI({
      ...filters,
      limit,
      field_sort,
      sort,
      page: page - 1,
      dates: [],
    });

    setValues({
      ...values,
      limit,
      page: page - 1,
    });
    setIsManual(isManualFilter);

    fetchData(clean);
  };
  return (
    <div>
      <InterfaceTable
        rowKey="_id"
        onChange={handleRequest}
        pagination={{
          pageSize: values.limit,
          current: values.page + 1,
          onChange: (current) => {
            setValues({
              ...values,
              page: current - 1,
            });
            fetchData({
              page: current - 1,
              limit: values.limit,
            });
          },
          total: totalCount,
          showSizeChanger: true,
          pageSizeOptions: ["5", "10", "20", "50", "100"],
          onShowSizeChange: (current, size) => {
            setValues({
              ...values,
              limit: size,
              page: current - 1,
            });
            fetchData({
              page: current - 1,
              limit: size,
            });
          },
        }}
        data={scriptOffsets}
        columns={columnsScript}
        loading={loading}
      />
      {selectedRecord && (
        <Modal
          footer={null}
          closable={false}
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          title="Editar Script Offset"
        >
          <ScriptOffsetsDetail
            setIsModalVisible={setIsModalVisible}
            record={selectedRecord}
            form={form}
            userId={userId}
            Offid={selectedRecord.id || selectedRecord._id}
            currentPagination={{ page: values.page, limit: values.limit }}
          />
        </Modal>
      )}
    </div>
  );
};

export default ScriptOffsetsBoard;
