import API from "../../Data/Domain/API";
import URL from "../../Data/Domain/URL";
import Storage from "../../Data/Domain/Storage";

export async function getRepositoryProjects(params) {
  return await new API(
    URL.setParams(URL.buildUrl("repositoryProjects"), params)
  ).get();
}
export async function postRepositoryProjects({ name }) {
  return await new API(URL.buildUrl("repositoryProjects")).post({ name });
}
// export async function getRepositoryProject() {
//   const id = new Storage("id").getItem();
//   return await new API(URL.buildUrl(`users/${id}/repositoryProjects`)).get();
// }

export async function getRepositoryProjectByUser(id) {
  const internal_id = id || new Storage("id").getItem();
  return await new API(URL.buildUrl(`users/${internal_id}/repositoryProjects`)).get();
}
// export async function updateRepositoryProject(id, data) {
//     const url = URL.setId(URL.buildUrl("repositoryProjects"), id);
//     return await new API(url).put(data);
// }
export async function updateRepositoryProject(id, data) {
  return await new API(URL.buildUrl(`repositoryProjects/${id}`)).put(data);
}
// export async function deleteRepositoryProject(id) {
//   return await new API(URL.buildUrl(`repositoryProjects/${id}`)).delete();
// }
export async function deleteRepositoryProject(id) {
  return await new API(
    URL.setId(URL.buildUrl("repositoryProjects"), id)
  ).delete();
}
