import AuthBusiness from "./Auth.business";
import Login from "../UI/Login";
import "../UI/Login.css";
import SiteLayout from "../../Layout/SiteLayout";
import { Spin } from "antd";

export const Authorization =
  (allowedRoles) =>
  (Children, metadata = {}) => {
    const { isLoading, isUserAuth, isValidRole } = AuthBusiness({
      allowedRoles,
    });
    const { defaultPage } = metadata;

    switch (true) {
      case isLoading:
        return (
          <div className="container">
            <Spin />
          </div>
        );
      case isValidRole && isUserAuth:
        return <Children defaultPage={defaultPage} />;
      case !isValidRole:
        return <SiteLayout defaultPage={""} />;
      case !isUserAuth:
      default:
        return <Login />;
    }
  };
