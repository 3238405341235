import { React, useEffect } from "react";
import { Form, Input, Button, message } from "antd";
import FormItem from "antd/lib/form/FormItem";
import { updateCompany } from "../../Companies/Infrastructure/Companies.service";
import DocumentUpload from "../../DocumentUpload/UI/DocumentUpload";

export default function CompaniesContactForm(props) {
  const { user, setProfile } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      name: user.name,
      lastname: user.lastname,
      email: user.email,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleSubmit = async () => {
    const form_values = form.getFieldsValue();
    try {
      await updateCompany(user.id, form_values);
      message.success("Se modificaron los datos correctamente");
    } catch (error) {
      message.error(" Se produjo un error, vuelve a intentarlo");
    }
  };

  return user ? (
    <Form
      initialValues={{
        name: user.name,
        lastname: user.lastname,
        email: user.email,
        verified: user.verified,
      }}
      layout="vertical"
      form={form}
    >
      <FormItem label="Nombre" name="name">
        <Input />
      </FormItem>
      <FormItem label="Apellido" name="lastname">
        <Input />
      </FormItem>
      <FormItem label="email" name="email">
        <Input />
      </FormItem>
      <FormItem label={"Documento de Representación Legal"}>
        <DocumentUpload
          type={"legal_representation"}
          id={user.id}
          buttonText="Seleccionar Archivo "
        />
      </FormItem>
      <FormItem label={"Cédula de identidad de representante legal"}>
        <DocumentUpload
          type={"personal_id"}
          id={user.id}
          buttonText="Seleccionar Archivo "
        />
      </FormItem>
      <FormItem label={"Foto de perfil"}>
        <DocumentUpload
          type={"profile_picture"}
          id={user.id}
          setProfile={setProfile}
          buttonText="Seleccionar Archivo "
        />
      </FormItem>
      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button type="primary" htmlType="submit" onClick={handleSubmit}>
          Guardar
        </Button>
      </Form.Item>
    </Form>
  ) : (
    <div />
  );
}
