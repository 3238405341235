import React, { useEffect } from "react";
import { Form, Input, DatePicker, Button, Row, message } from "antd";
import FormItem from "antd/lib/form/FormItem";
import DocumentUpload from "../../DocumentUpload/UI/DocumentUpload";
import Document from "../../DocumentUpload/Domain/Document";
import { updateCompany } from "../../Companies/Infrastructure/Companies.service";

export default function CompaniesId(props) {
  const [form] = Form.useForm();
  const { user } = props;

  const handleSubmit = async () => {
    try {
      const extras = { ...user.companyExtras, ...form.getFieldsValue() };
      await updateCompany(user.id, { extras });
      message.success("Se añadieron los valores de identificación de compañía");
    } catch (error) {
      message.error(" Se produjo un error, vuelve a intentarlo");
    }
  };

  useEffect(() => {
    form.setFieldsValue(user.companyExtras);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      <Row justify="center">
        <Form initialValues={user.companyExtras} form={form} layout="vertical">
          <FormItem label="ID de Compañía" name={"company_id_code"}>
            <Input placeholder="ID de Compañía" style={{ width: 250 }} />
          </FormItem>
          <FormItem>
            <DocumentUpload
              type={Document.COMPANY_ID}
              id={user.id}
              buttonText="Seleccionar Archivo "
            />
          </FormItem>
          <FormItem label="Inicio de actividades SII" name={"date"}>
            <DatePicker
              placeholder="Seleccionar fecha"
              style={{ width: 250 }}
            />
          </FormItem>
          <FormItem>
            <DocumentUpload
              type={Document.LOCAL_TAX_AUTHORITY}
              id={user.id}
              buttonText="Seleccionar Archivo "
            />
          </FormItem>
          <FormItem
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit" onClick={handleSubmit}>
              Guardar
            </Button>
          </FormItem>
        </Form>
      </Row>
    </>
  );
}
