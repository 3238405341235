import Formatter from "../../Data/Domain/Formatter";
import Date from "../../Data/Domain/CustomDate";

class Transaction {
  static get FOOTPRINT_OFFSET() {
    return "footprint_offset";
  }

  static get BUY_BONDS() {
    return "buy_bonds";
  }

  static get BUY_USD() {
    return "buy_usd";
  }
  static get LOAD_USD() {
    return "load_usd";
  }

  static get PENDING_STATUS() {
    return "pending";
  }

  static get ACCEPTED_STATUS() {
    return "accepted";
  }

  static get CREDIT_CARD() {
    return "credit_card";
  }

  static get BANK_TRANSFER() {
    return "bank_transfer";
  }
  static get BUY_NFT() {
    return 'buy_nft';
  }

  static get BUY_SBT() {
    return 'buy_sbt';
  }

  constructor({
    _id,
    type,
    state,
    payment_amount,
    payment_method,
    fee,
    total_coins,
    carbon_credit_invested,
    project_invested,
    amount_usd,
    project_id,
    id_user,
    id_session,
    client_coins,
    co2_emission,
    partner_coins,
    date,
  }) {
    this.type = type;
    this.id = _id;
    this.state = state;
    this.payment_amount = payment_amount;
    this.payment_method = payment_method;
    this.fee = fee;
    this.total_coins = total_coins;
    this.carbon_credit_invested = carbon_credit_invested;
    this.project_invested = project_invested;
    this.amount_usd = amount_usd;
    this.project_id = project_id;
    this.id_user = id_user;
    this.id_session = id_session;
    this.client_coins = client_coins;
    this.co2_emission = co2_emission;
    this.partner_coins = partner_coins;
    this.date = new Date(date).formatted_date;
  }

  get forTransaction() {
    switch (this.type) {
      case Transaction.BUY_USD:
        return this.forUsd();
      case Transaction.BUY_BONDS:
        return this.forBonds();
      case Transaction.LOAD_USD:
        return this.forLoadUsd();
      default:
        return "n/a";
    }
  }

  forUsd() {
    return {
      id_user: this.id_user,
      amount_usd: this.amount_usd,
      type: this.type,
      payment_method: Transaction.BANK_TRANSFER,
    };
  }

  forBonds() {
    return {
      id_user: this.id_user,
      type: this.type,
      amount_usd: this.amount_usd,
      project_id: this.project_id,
      carbon_credit_invested: this.carbon_credit_invested,
      client_coins: this.client_coins,
      co2_emission: this.co2_emission,
      partner_coins: this.partner_coins,
      project_invested: this.project_invested,
      total_coins: this.total_coins,
      payment_method: Transaction.BANK_TRANSFER,
    };
  }

  forLoadUsd() {
    return {
      id_user: this.id_user,
      type: this.type,
      amount_usd: this.amount_usd,
      payment_method: Transaction.BANK_TRANSFER,
    };
  }

  get typeValue() {
    switch (this.type) {
      case Transaction.BUY_USD:
        return "Compra de dólares";
      case Transaction.BUY_BONDS:
        return "Compra de bonos";
      case Transaction.FOOTPRINT_OFFSET:
        return "Cálculo de huella";
      case Transaction.BUY_NFT:
        return "Compra de Crédito de Carbono (NFT)";
      case Transaction.BUY_SBT:
        return "Offset de Crédito de Carbono (SBT)";
      default:
        return "N/A";
    }
  }

  get stateValue() {
    switch (this.state) {
      case Transaction.ACCEPTED_STATUS:
        return "Finalizado";
      case Transaction.PENDING_STATUS:
        return "Pendiente";
      default:
        return "N/A";
    }
  }

  get paymentMethodValue() {
    switch (this.payment_method) {
      case Transaction.CREDIT_CARD:
        return "Tarjeta de Crédito";
      case Transaction.BANK_TRANSFER:
        return "Transferencia Bancaria";
      default:
        return "N/A";
    }
  }
  get calculatePayment() {
    return `${Formatter.round(this.payment_amount / 100)}`;
  }

  get calculateFee() {
    return this.type === Transaction.BUY_USD
      ? `${Formatter.round(this.fee)}`
      : `${Formatter.round(this.fee / 100)}`;
  }
  isBonds() {
    return this.type === Transaction.BUY_BONDS;
  }

  isUSD() {
    return this.type === Transaction.BUY_USD;
  }

  isLoadUSD() {
    return this.type === Transaction.LOAD_USD;
  }

  get canFinish() {
    const credit_card =
      this.payment_method === Transaction.CREDIT_CARD && !!this.id_session;
    const bank_transfer = this.payment_method === Transaction.BANK_TRANSFER;

    return (
      this.state === Transaction.PENDING_STATUS &&
      (credit_card || bank_transfer)
    );
  }

  get isPending() {
    return this.state === Transaction.PENDING_STATUS;
  }

  get hasSession() {
    return !!this.id_session;
  }

  get isCreditCardOperation() {
    return this.payment_method === Transaction.CREDIT_CARD;
  }

  get isBankTransferOperation() {
    return this.payment_method === Transaction.BANK_TRANSFER;
  }
}

export default Transaction;
