import Transaction from "../../Transactions/Domain/Transaction";
import Offer from "../../Offers/Domain/Offer";
import Formatter from "../../Data/Domain/Formatter";
import Balance from "./Balance";

class User {
  static get ROLES() {
    return {
      superadmin: "Superadministrador",
      admin: "Administrador",
      owner: " Propietario",
      legal: "legal",
      projects_viewer: "Visualizador de Proyectos",
      projects_seller: "Vendedor de Proyectos",
      carbon_management: "Gestión de carbono",
      carbon_credits: "Créditos de Carbono",
      partner: "Partner",
      project_owner: "Propietario del proyecto",
    };
  }
  static get DICTIONARY_ROLES_PAGES() {
    return {
      admin: "users",
      projects_viewer: "projects",
      projects_seller: "projects",
      carbon_management: "suppliers",
      carbon_credits: "offers",
      partner: "dashboardOffset",
      project_owner: "owners",
    };
  }
  static getPageBasedOnRole(role) {
    return User.DICTIONARY_ROLES_PAGES[role] || "error";
  }

  static get SUPERADMIN() {
    return "superadmin";
  }
  static get ADMIN() {
    return "admin";
  }
  static get OWNER() {
    return "owner";
  }
  static get LEGAL() {
    return "legal";
  }
  static get PROJECTS_VIEWER() {
    return "projects_viewer";
  }

  static get PROJECTS_SELLER() {
    return "projects_seller";
  }
  static get CARBON_MANAGEMENT() {
    return "carbon_management";
  }
  static get CARBON_CREDITS() {
    return "carbon_credits";
  }
  static get PARTNER() {
    return "partner";
  }
  static get PROJECT_OWNER() {
    // return "project_owner";
    return "owner";
  }

  constructor({
    id,
    _id,
    name,
    lastname,
    email,
    availableUSD,
    verified,
    enabled,
    publicKey,
    withdrew_usd,
    username,
    profile_picture,
    availableCoins,
    totalCoins,
    data = {},
    offers = [],
    transactions = [],
    balances = [],
    availableNFT,
    availableSBT
  }) {
    this.id = id || _id;
    this.name = name;
    this.lastname = lastname;
    this.email = email;
    this.availableUSD = Formatter.round(availableUSD);
    this.verified = verified;
    this.enabled = enabled;
    this.publicKey = publicKey;
    this.withdrew_usd = withdrew_usd;
    this.username = username;
    this.profile_picture = profile_picture;
    this.availableCoins = availableCoins;
    this.totalCoins = totalCoins;
    this.data = data;
    this.offers = this.formatOffers(offers);
    this.transactions = this.formatTransactions(transactions);
    this.balances = this.formatBalances(balances);
    this.availableNFT=availableNFT;
    this.availableSBT=availableSBT;
  }

  formatTransactions(transactions = []) {
    return transactions.map((transaction) => new Transaction(transaction));
  }

  formatOffers(offers = []) {
    return offers.map((offer) => new Offer(offer));
  }
  formatBalances(balances = []) {
    return balances.map((balance) => new Balance(balance));
  }

  getNativeBalance() {
    const nativeBalance = this.balances.find(
      (balance) => balance.asset_code === 'native'
    );
    return nativeBalance ? nativeBalance.balance : "N/A";
  }
  get fullName() {
    return `${this.name} ${this.lastname}`;
  }

  get userName() {
    const fullName = `${this.name} ${this.lastname}`;
    return fullName.replace(" undefined", "");
  }
  static getFirstRole(roles = []) {
    return roles[0];
  }

  get verifiedValue() {
    switch (this.verified) {
      case "starter":
        return "No iniciado";
      case "pending":
        return "Pendiente";
      case "rejected":
        return "Rechazado";
      case "succeeded":
        return "Verificado";
      case "data_loaded":
        return "Información inicial cargada";
      default:
        return "No iniciado";
    }
  }

  get enabledValue() {
    return this.enabled ? "Activado" : "Desactivado";
  }

  openOffers() {
    return this.offers.some((data) => data.status === "open");
  }
  get disabledValue() {
    return this.availableCoins || this.availableUSD >= 1 || this.openOffers()
      ? true
      : null;
  }
  getCo2() {
    return this.data.co2;
  }
}

export default User;
