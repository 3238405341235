import {
  getReductionsLogic,
  deleteReductionLogic,
  getReductionLogic,
  postReductionLogic,
} from "./Saving.logic";
import {
  saveCountReductions,
  saveListReductions,
  deleteSelectedReduction,
  saveSelectedReduction,
  savePostListReduction,
} from "../Infrastructure/Saving.store";

export async function getReductions(params) {
  const { count, resources: reductions } = await getReductionsLogic(params);
  saveListReductions(reductions);
  saveCountReductions(count);
}

export async function deleteReduction(id) {
  await deleteReductionLogic(id);
  deleteSelectedReduction(id);
}
export async function getReductionById(id) {
  const reduction = await getReductionLogic(id);
  saveSelectedReduction(reduction);
  return reduction;
}

export async function postReduction(params) {
  const reduction = await postReductionLogic(params);
  savePostListReduction(reduction);
}
