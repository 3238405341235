import Response from "../../Data/Domain/Response";
import Area from "../Domain/Area";
import DataUser from "../../DataUser/Domain/DataUser";

import {
  getAreas,
  getAreasByUser,
  putAreas,
  deleteArea,
} from "./Areas.service";

export async function getAreasRepository(params) {
  const data = await getAreas(params);
  return new Response(data, Area).getMultiple();
}
export async function getAreasByUserRepository(id) {
  const data = await getAreasByUser(id);
  return new Response(data, DataUser).getMultiple().resources;
}

export async function updateAreaRepository(id, params) {
  const data = await putAreas(id, params);
  return new Response(data, Area).onlyStatus();
}

export async function deleteAreaRepository(id) {
  const data = await deleteArea(id);
  return new Response(data).onlyStatus();
}
