import Response from "../../Data/Domain/Response";
import RepositoryProject from "../Domain/RepositoryProject";
import {
  getRepositoryProjects,
  deleteRepositoryProject,
  getRepositoryProjectByUser
} from "./RepositoryProjects.service";
import DataUser from "../../DataUser/Domain/DataUser";

export async function getRepositoryProjectsRepository(params) {
  const data = await getRepositoryProjects(params);
  return new Response(data, RepositoryProject).getMultiple();
}
export async function getRepositoryProjectByUserRepository(id) {
  const data = await getRepositoryProjectByUser(id);
  return new Response(data, DataUser).getMultiple().resources;
}
export async function deleteRepositoryProjectRepository(id) {
  const data = await deleteRepositoryProject(id);
  return new Response(data).onlyStatus();
}
