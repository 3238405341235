import React from "react";
import { Radio, Typography } from "antd";
import "antd/dist/antd.min.css";
import "./Scope.css";
import ProgressBar from "../../../ProgressBar/ProgressBar";
import ProgressCircle from "../../../ProgressCircle/ProgressCircle";

const { Text } = Typography;
const styles = {
  containerScope: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "1em ",
  },
};
const Scope = ({ id, title, subtitle, percent }) => {
  return (
    <Radio.Button value={title} key={id} className="scope-container">
      <div style={styles.containerScope}>
        <ProgressCircle percentCircle={percent} width={65} />
        <div className="text-container">
          <Text className="title-scope">{title}</Text>
          <Text className="subtitle-scope">{subtitle}</Text>
          <div className="progress-bar-container">
            <ProgressBar percent={percent} />
          </div>
        </div>
      </div>
    </Radio.Button>
  );
};

export default Scope;
