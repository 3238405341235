import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "subcategories",
  initialState: {
    list: [],
    count: 0,
    selected: null,
  },
  reducers: {
    setSubcategories: (state, { payload: list }) => {
      return {
        ...state,
        list,
      };
    },
    countSubcategories: (state, { payload: count }) => {
      return {
        ...state,
        count,
      };
    },
    selectSubcategory: (state, { payload: selected }) => {
      return {
        ...state,
        selected,
      };
    },
  },
});

export const { setSubcategories, countSubcategories, selectSubcategory } =
  slice.actions;

export default slice.reducer;

export const getSubcategoriesSelector = (store) => store.subcategories.list;
export const getSelectedSubcategorySelector = (store) =>
  store.subcategories.selected;
