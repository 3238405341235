import Response from "../../Data/Domain/Response";
import Document from "../Domain/Document";
import {
  PostDocument,
  getByCompany,
  getDocumentById,
  deleteDocument,
  getByUser,
  getByAll
} from "./DocumentUpload.service";

export async function getDocumentByIdRepository(id) {
  const document = await getDocumentById(id);
  return new Response(document, Document).getSingle();
}

export async function PostDocumentRepository(params) {
  const data = await PostDocument(params);
  return new Response(data, Document).getSingle();
}

export async function getByCompanyRepository(id) {
  const data = await getByCompany(id);
  return new Response(data, Document).getMultiple();
}

export async function deleteDocumentRepository(id) {
  const document = await deleteDocument(id);
  return new Response(document).onlyStatus();
}
export async function getByUserRepository(id, params) {
  const data = await getByUser(id, params);
  return new Response(data, Document).getMultiple();
}
export async function getAllRepository(type,params) {
  const response = await getByAll(type,params);
  return new Response(response, Document).getMultiple();


}