class Response {
  constructor(response, Type) {
    this.response = response;
    this.Type = Type;
    this.getFullResponse();
  }

  getFullResponse() {
    const { data, headers, config, request, status, statusText } =
      this.response;
    this.data = data;
    this.status = status;
    this.statusText = statusText;
    this.headers = headers;
    this.config = config;
    this.request = request;
  }

  getMultiple() {
    const { resources = [], count = 0, ...data } = this.data;
    const clean_resources = [];
    for (const resource of resources) {
      clean_resources.push(new this.Type(resource));
    }
    return { ...data, resources: clean_resources, count };
  }

  getSingle() {
    let data = this.data ? this.data : {};
    return new this.Type(data);
  }

  onlyStatus() {
    return Number(this.status) === 204;
  }
}

export default Response;
