class Token {
  static get Transaction() {
    return "transaction";
  }

  constructor({ id, type, expired_at, code }) {
    this.id = id;
    this.type = type;
    this.expired_at = expired_at;
    this.code = code;
  }

  forCreation() {
    return {
      id: this.id,
      type: this.type,
    };
  }
}

export default Token;
