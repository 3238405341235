import { Col, Layout, Row, Typography, Card } from "antd";
import SuppliersBoard from "./SuppliersBoard";
import NewSuppliers from "./NewSuppliers";

const { Content } = Layout;
const { Text } = Typography;
const styles = {
  text: {
    fontFamily: "MontserratBold",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "24px",
    color: "#041370",
  },
};

export default function Suppliers() {
  return (
    <div className="grid-emission">
      <div>
        <Card className="card-content">
          <Row>
            <Col
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 20,
              }}
              span={24}
            >
              <Text style={styles.text}>Suppliers</Text>
              <NewSuppliers />
            </Col>
          </Row>
          <SuppliersBoard />
        </Card>
      </div>
    </div>
  );
}
