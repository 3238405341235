import Response from "../../Data/Domain/Response";
import SupplierBySubcategory from "../Domain/SupplierBySubcategory";
import Supplier from "../Domain/Supplier";
import DataUser from "../../DataUser/Domain/DataUser";

import {
  getSuppliers,
  getSuppliersByUser,
  putSuppliers,
  deleteSupplier,
  getSubcategoriesBySuppliersAndUser,
} from "./Suppliers.service";

export async function getSuppliersRepository(params) {
  const data = await getSuppliers(params);
  return new Response(data, Supplier).getMultiple();
}
export async function getSuppliersByUserRepository(id) {
  const data = await getSuppliersByUser(id);
  return new Response(data, DataUser).getMultiple().resources;
}
export async function updateSupplierRepository(id, params) {
  const data = await putSuppliers(id, params);
  return new Response(data, Supplier).onlyStatus();
}
export async function deleteSuppliersRepository(id) {
  const data = await deleteSupplier(id);
  return new Response(data).onlyStatus();
}
// export async function getSubcategoriesBySuppliersAndUserRepository(params) {
//   const data = await getSubcategoriesBySuppliersAndUser(params);
//   return new Response(data, Supplier).getMultiple();
// }
export async function getSubcategoriesBySuppliersAndUserRepository(id) {
  const data = await getSubcategoriesBySuppliersAndUser(id);
  return new Response(data, SupplierBySubcategory).getMultiple().resources;
}
