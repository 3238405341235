import store from "../../Redux/store";
import {
  countTransactions,
  setTransactions,
  selectTransaction,
  addTransaction,
  totalTransactions,
  totalUsd,
} from "./Transactions.reducer";

export function saveListTransactions(records) {
  store.dispatch(setTransactions(records));
}

export function saveCountTransactions(count) {
  store.dispatch(countTransactions(count));
}

export function saveSelectedTransaction(transaction) {
  store.dispatch(selectTransaction(transaction));
}

export function savePostListTransaction(records) {
  store.dispatch(addTransaction(records));
}
export function saveTotalTransactions(total_transactions) {
  store.dispatch(totalTransactions(total_transactions));
}
export function saveTotalUsd(total_usd) {
  store.dispatch(totalUsd(total_usd));
}
