import { Table } from "antd";
import React from "react";
import "antd/dist/antd.min.css";
import "./InterfaceTable.css";

const InterfaceTable = (props) => {
  return (
    <div className="table-wrapper">
      <Table
        rowKey={(record) => props.data.indexOf(record)}
        bordered
        columns={props.columns}
        expandable={props.expandable}
        dataSource={props.data}
        pagination={props.pagination}
        onChange={props.onChange}
        onRow={props.onRow}
        loading={props.loading}
        className={props.table}
      />
    </div>
  );
};

export default InterfaceTable;
