import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Badge, PageHeader, Descriptions, Tag } from "antd";
import "antd/dist/antd.min.css";
import { getSelectedAdminSelector } from "../Infrastructure/Administrators.reducer";
import { deselectAdmin } from "../Infrastructure/Administrators.store";

export default function AdminsForm() {
  const selected = useSelector(getSelectedAdminSelector);
  const navigate = useNavigate();

  const cancel = () => {
    deselectAdmin();
    navigate("/administrator");
  };

  return selected ? (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={cancel}
        tags={<Tag color="green">{selected.name}</Tag>}
        avatar={{
          src: "https://avatars1.githubusercontent.com/u/8186664?s=460&v=4",
        }}
      >
        <Descriptions title="" layout="vertical" bordered>
          <Descriptions.Item label="Nombre" span={2}>
            {selected.name}
          </Descriptions.Item>
          <Descriptions.Item label="Email" span={3}>
            {selected.email}
          </Descriptions.Item>
          <Descriptions.Item label="Status">
            {selected.verifiedValue}
          </Descriptions.Item>
          <Descriptions.Item label="Activo">
            <Badge status="processing" text={selected.enabledValue} />
          </Descriptions.Item>
          <Descriptions.Item label="Withdrew Usd">
            {selected.withdrew_usd}
          </Descriptions.Item>
          <Descriptions.Item label="Public Key">
            {selected.publicKey}
          </Descriptions.Item>
        </Descriptions>
        ,
      </PageHeader>
    </div>
  ) : (
    <div />
  );
}
