import React, { useEffect } from "react";
import "./Switch.css";

const Switch = ({ setStream, switchLeft, switchRight, ...props }) => {
  useEffect(() => {
    const firstBtn = document.querySelector(".firstBtn");
    const secondBtn = document.querySelector(".secondBtn");
    const moveBtn = document.querySelector(".moveBtn");

    secondBtn.addEventListener("click", () => {
      moveBtn.classList.add("rightBtn");
      moveBtn.innerHTML = switchRight;
      setStream(switchRight);
    });

    firstBtn.addEventListener("click", () => {
      moveBtn.classList.remove("rightBtn");
      moveBtn.innerHTML = switchLeft;
      setStream(switchLeft);
    });
  });

  return (
    <div className="wrapper">
      <div className="modalForm">
        <div className="actionBtns">
          <button className="actionBtn firstBtn">{switchLeft}</button>
          <button className="actionBtn secondBtn"> {switchRight}</button>
          <button className="moveBtn">{switchLeft}</button>
        </div>
      </div>
    </div>
  );
};

export default Switch;
