import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Select, Input } from "antd";
import Button from "../../Layout/DesignSystem/Button/Button";
import { getAreas } from "../../Areas/Application/Areas.business";
import { getAreasSelector } from "../../Areas/Infrastructure/Areas.reducer";

const { Option } = Select;
const NewAreaManageData = (props) => {
    const areas = useSelector(getAreasSelector);
    const [selectedArea, setSelectedArea] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [emissionValue, setEmissionValue] = useState("");
    const [buttonDisabled, setButtonDisabled] = useState(true);

    useEffect(() => {
        const isButtonDisabled =
            props.userId === undefined ||
            !searchValue ||
            isNaN(parseFloat(emissionValue)) ||
            emissionValue === null;
        setButtonDisabled(isButtonDisabled);
    }, [props.userId, searchValue, emissionValue]);

    useEffect(() => {
        getAreas();
    }, []);

    const onSearchAreas = (val) => {
        getAreas({ filter: { q: val } });
    };

    const handleClick = () => {
        console.log("Área seleccionada:", selectedArea);
        console.log("Valor de emisión:", emissionValue);
    };

    const handleSelectChange = (value, option) => {
        setSelectedArea(option.children);
        setSearchValue(value);
    };

    return (
        <div className="grid-NewManageData">
            <div className="item-NewManageData">
                <Select
                    onSearch={onSearchAreas}
                    showSearch
                    placeholder="Seleccionar áreas"
                    style={{ width: "100%" }}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    value={searchValue}
                    onChange={(value, option) => handleSelectChange(value, option)}
                >
                    {areas?.map((item) => (
                        <Option value={item.id} key={item.id}>
                            {item.name}
                        </Option>
                    ))}
                </Select>
            </div>
            <div className="item-NewManageData">
                <Input
                    type="number"
                    min={0}
                    className="input-NewManageData custom-input"
                    placeholder="Emisión(es)"
                    value={emissionValue}
                    onChange={(e) => setEmissionValue(e.target.value)}
                />
            </div>
            <div className="item-NewManageData">
                <Button
                    htmlType="submit"
                    text="Agregar"
                    block={true}
                    onClick={handleClick}
                    disabled={buttonDisabled}

                />
            </div>
        </div>
    )
}

export default NewAreaManageData