import {
  getSuppliersRepository,
  getSuppliersByUserRepository,
  updateSupplierRepository,
  deleteSuppliersRepository,
  getSubcategoriesBySuppliersAndUserRepository
} from "../Infrastructure/Suppliers.repository";

export async function getSuppliersLogic(params) {
  return getSuppliersRepository(params);
}
export async function getSuppliersByUserLogic(id) {
  return getSuppliersByUserRepository(id);
}
export async function updateSupplierLogic(id, params) {
  return updateSupplierRepository(id, params);
}
export async function deleteSupplierLogic(id) {
  return deleteSuppliersRepository(id);
}
// export async function getSubcategoriesBySuppliersAndUserLogic(params) {
//   return getSubcategoriesBySuppliersAndUserRepository(params);
// }
export async function getSubcategoriesBySuppliersAndUserLogic(id) {
  return getSubcategoriesBySuppliersAndUserRepository(id);
}
