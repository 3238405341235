import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Form, Input, message } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { getAdmins } from "../Application/Administrators.business";
import { postAdmin } from "../Infrastructure/Administrator.service";
import Modal from "../../Modal/Modal";

export default function NewAdministrator() {
  const [form] = Form.useForm();
  const [values, setValues] = useState({
    email: "",
    name: "",
    password: "",
  });
  const dispatch = useDispatch();

  const onHandleChange = (input) => (e) => {
    setValues({
      ...values,
      [input]: e.target.value,
    });
  };

  async function handleSubmit() {
    const { password, name, email } = values;
    try {
      await postAdmin({
        password,
        name,
        email,
      });
      setValues({ ...values, visible: false });
      message.success("Se agregó un nuevo Admin");
    } catch (error) {
      message.error(" Se produjo un error, vuelve a intentarlo");
    }
    dispatch(getAdmins());
  }
  const styles = {
    formAdministrator: {
      fontFamily: "MontserratRegular",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
  };
  return (
    <>
      <Modal
        children={
          <Form
            responsive={"true"}
            layout="vertical"
            onFinish={handleSubmit}
            form={form}
            style={styles.formAdministrator}
          >
            <Form.Item
              label={
                <label style={{ color: "white" }}>Correo Electrónico</label>
              }
              colon={false}
              name="email"
              rules={[
                {
                  type: "email",
                  message: "Email no válido",
                },
                {
                  required: true,
                  message: "Ingresa un valor",
                },
              ]}
              hasFeedback
            >
              <Input
                value={values.email}
                name={"email"}
                onChange={onHandleChange("email")}
                placeholder="Correo Electrónico"
                maxLength={50}
                schema={{ default: "", type: "string" }}
              />
            </Form.Item>

            <Form.Item
              label={<label style={{ color: "white" }}>Contraseña</label>}
              colon={false}
              name="password"
              rules={[
                {
                  required: true,
                  message: "Ingresa un valor",
                },
              ]}
              hasFeedback
            >
              <Input.Password
                value={values.password}
                name={"password"}
                onChange={onHandleChange("password")}
                placeholder="Contraseña"
                maxLength={25}
                schema={{ default: "", type: "string" }}
              />
            </Form.Item>

            <Form.Item
              label={<label style={{ color: "white" }}>Nombre</label>}
              colon={false}
              name="name"
              rules={[
                {
                  required: "true",
                  message: "Nombre no válido",
                  pattern: new RegExp(
                    /^[a-zA-Z@~`!@#$%^&*()_=+\\\\';:"\\/?>.<,-, ]+$/i
                  ),
                },
              ]}
              hasFeedback
            >
              <Input
                value={values.name}
                name={"name"}
                onChange={onHandleChange("name")}
                placeholder="Nombre"
                maxLength={25}
                schema={{ default: "", type: "string" }}
              />
            </Form.Item>
          </Form>
        }
        icon={<PlusCircleOutlined style={{ fontSize: "32px" }} />}
        style={{
          color: "#08c",
          marginLeft: "12px",
        }}
        title="Agregar Administrador"
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        text="Agregar"
        handleSubmit={handleSubmit}
        form={form}
      />
    </>
  );
}
