import React from "react";
import { Button } from "antd";
import "antd/dist/antd.min.css";
import "./Button.css";

const ButtonElement = ({
  text,
  htmlType,
  block,
  onClick,
  loading,
  type,
  disabled,
}) => {
  return (
    <Button
      className={type ? [type] : ["button-elem"]}
      onClick={onClick}
      htmlType={htmlType}
      block={block}
      loading={loading}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};

export default ButtonElement;
