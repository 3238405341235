import Response from "../../Data/Domain/Response";
import Transaction from "../Domain/Transaction";
import {
  getTransactions,
  getTransaction,
  patchTransaction,
  postTransaction,
} from "./Transactions.service";

export async function getTransactionsRepository(params) {
  const data = await getTransactions(params);
  return new Response(data, Transaction).getMultiple();
}

export async function getTransactionRepository(id) {
  const data = await getTransaction(id);
  return new Response(data, Transaction).getSingle();
}

export async function patchTransactionRepository(id) {
  const data = await patchTransaction(id);
  return new Response(data, Transaction).onlyStatus();
}

export async function postTransactionRepository(params) {
  const data = await postTransaction(params);
  return new Response(data, Transaction).getMultiple().resources;
}
