import React from "react";
import { PlusCircleOutlined } from "@ant-design/icons";
import ModalInterface from "../../Modal/Modal";
import { Input } from "antd";
import "./Files.css";
import DocumentUpload from "../../DocumentUpload/UI/DocumentUpload";
import Document from "../../DocumentUpload/Domain/Document";

const { TextArea } = Input;

const separatorStyle = {
  marginLeft: "4px",
};

const NewFile = ({
  userId,
  handleImageUpdate,
  description,
  handleDescriptionChange,
  role,
  getByUser,
  User,
  isDescriptionEmpty,
}) => {
  return (
    <div>
      <ModalInterface
        children={
          <div className="container-document-file">
            <div className="container-document-file-item ">
              <TextArea
                rows={4}
                placeholder="Escribe una descripción"
                maxLength={20}
                value={isDescriptionEmpty ? "" : description}
                onChange={handleDescriptionChange}
              />
            </div>
            <div className="container-document-file-item ">
              <DocumentUpload
                type={Document.CARBON_MANAGEMENT_FILE}
                id={userId}
                buttonText="Seleccionar Archivo"
                disabled={isDescriptionEmpty}
                updateImage={handleImageUpdate}
                description={description}
              />
            </div>
          </div>
        }
        icon={
          role === User.CARBON_MANAGEMENT ? null : (
            <PlusCircleOutlined
              className="icon-files"
              onClick={() => getByUser(userId)}
            />
          )
        }
        style={separatorStyle}
        title="Nuevo Archivo"
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        text=""
        handleSubmit=""
        form=""
      />
    </div>
  );
};

export default NewFile;
