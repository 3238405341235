import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { getCategoriesByUserSelector } from "../../DataUser/Infrastructure/DataUser.reducer";
import { getCategoriesByUser } from "../../Categories/Application/Categories.business";
import InterfaceTable from "../../Table/InterfaceTable";
import NewCategoryManageData from "./NewCategoryManageData";

const columnsEmissionPerCategory = [
  {
    title: "Categorías",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Emisiones (tons)",
    dataIndex: "quantity",
    key: "quantity",
  },
  {
    title: "Porcentaje",
    dataIndex: "percent",
    key: "percent",
  },
];

const CategoriesByUser = ({ userId }) => {
  const categoriesByUser = useSelector(getCategoriesByUserSelector);

  useEffect(() => {
    if (userId) {
      getCategoriesByUser(userId);
    }
  }, [userId]);

  return (
    <div>
      <NewCategoryManageData userId={userId} />
      <InterfaceTable
        rowKey={(record) => record._id}
        data={categoriesByUser}
        columns={columnsEmissionPerCategory}
        table={"table-manage-data"}
      />
    </div>
  );
};

export default CategoriesByUser;
