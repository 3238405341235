class InfoGeneral {
    constructor({ title, subtitle, data, name, info, type, id, project }) {
      this.project = project;
      this.id = id;
      this.info = info;
      this.title = title || name.en;
      this.data = subtitle || data;
      this.setType(type, subtitle);
    }
  
    setType(type, subtitle) {
      if (type) this.type = type;
      if (!type && subtitle) this.type = "impact";
      if (!type && !subtitle) this.type = "sdg";
    }
  
    get TYPE_DICTIONARY() {
      return {
        "impact": "Impact",
        "sdg": "SDG",
        "key": "Key Indicator",
        "projectDescription": "Project Description"
      };
    }
  
    get typeStr() {
      return this.TYPE_DICTIONARY[this.type];
    }
  }
  
  export default InfoGeneral;

  