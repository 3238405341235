class Storage {
  constructor(key) {
    this.storage = localStorage;
    this.key = key;
  }

  setKey(key) {
    this.key = key;
    return this;
  }

  getItem() {
    return this.storage.getItem(this.key);
  }

  setItem(value) {
    this.storage.setItem(this.key, value);
  }

  removeItem() {
    this.storage.removeItem(this.key);
  }

  removeMany(keys = []) {
    for (const key of keys) {
      this.storage.removeItem(key);
    }
  }

  removeAll() {
    this.storage.clear();
  }
}

export default Storage;
