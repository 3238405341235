import store from "../../Redux/store";
import { setOwnersByProject,countOwnersByProject } from "../Infrastructure/Owners.reducer";


export function saveListOwnersByProject(records) {
  store.dispatch(setOwnersByProject(records));
}
export function saveCountOwnersByProject(count) {
  store.dispatch(countOwnersByProject(count));
}
