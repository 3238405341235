import Response from "../../Data/Domain/Response";
import Category from "../Domain/Category";
import Subcategory from "../../Subcategories/Domain/Subcategory";
import {
  getCategories,
  getSubcategoriesData,
  updateSubcategories,
  getSubcategory,
  getSubcategoriesByCategAndUser,
  getCategoriesByUser,
} from "./Categories.service";
import DataUser from "../../DataUser/Domain/DataUser";

export async function getCategoriesRepository(params) {
  const data = await getCategories(params);
  return new Response(data, Category).getMultiple();
}

export async function getSubcategoriesDataRepository(id) {
  const data = await getSubcategoriesData(id);
  return new Response(data, Subcategory).getMultiple();
}

export async function getSubcategorytRepository(id) {
  const data = await getSubcategory(id);
  return new Response(data, Subcategory).getSingle();
}

export async function updateSubcategoriesRepository(id, params) {
  const data = await updateSubcategories(id, params);
  return new Response(data, Subcategory).onlyStatus();
}

export async function getSubcategoriesByCategAndUserRepository(params) {
  const data = await getSubcategoriesByCategAndUser(params);
  return new Response(data, Subcategory).getMultiple();
}
export async function getCategoriesByUserRepository(id) {
  const data = await getCategoriesByUser(id);
  return new Response(data, DataUser).getMultiple().resources;
}
