import {
  getPermissionsRepository,
  getPermissionRepository,
  updatePermissionRepository,
  getPermissionsByOwnerRepository,
} from "../Infrastructure/Permissions.repository";

export async function getPermissionsLogic(params) {
  return getPermissionsRepository(params);
}

export async function getPermissionsByOwnerLogic(params) {
  return getPermissionsByOwnerRepository(params);
}
export async function getPermissionLogic(id) {
  return getPermissionRepository(id);
}
export async function updatePermissionLogic(id, params) {
  return updatePermissionRepository(id, params);
}
