import store from "../../Redux/store";
import { countOffers, setOffers, selectOffer } from "./Offers.reducer";

export function saveListOffers(records) {
  store.dispatch(setOffers(records));
}
export function saveCountOffers(count) {
  store.dispatch(countOffers(count));
}
export function saveSelectedOffer(offer) {
  store.dispatch(selectOffer(offer));
}
