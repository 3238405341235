import {
  getAreasLogic,
  getAreasByUserLogic,
  updateAreaLogic,
  deleteAreaLogic,
} from "./Areas.logic";
import {
  saveListAreas,
  deleteSelectedArea,
  saveListAreasByUser
} from "../Infrastructure/Areas.store";

export async function getAreas() {
  const { resources: areas } = await getAreasLogic();
  saveListAreas(areas);
}
export async function getAreasByUser(id) {
  const areas = await getAreasByUserLogic(id);
  saveListAreasByUser(areas);
}

export async function updateArea(id, params) {
  await updateAreaLogic(id, params);
}

export async function deleteArea(id) {
  await deleteAreaLogic(id);
  deleteSelectedArea(id);
}
