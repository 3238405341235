import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { getSuppliersByUser } from "../../Suppliers/Application/Suppliers.business";
import { getSuppliersByUserSelector } from "../../DataUser/Infrastructure/DataUser.reducer";
import NewSuppliersManageData from "./NewSuppliersManageData";
import InterfaceTable from "../../Table/InterfaceTable";

const columnsEmissionPerSuppliers = [
  {
    title: "Suppliers",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Emisiones (tons)",
    dataIndex: "quantity",
    key: "quantity",
  },
];
const SuppliersByUser = ({ userId }) => {
  const suppliersByUser = useSelector(getSuppliersByUserSelector);

  useEffect(() => {
    if (userId) {
      getSuppliersByUser(userId);
    }
  }, [userId]);

  return (
    <div>
      <NewSuppliersManageData userId={userId} />
      <InterfaceTable
        rowKey={(record) => record._id}
        data={suppliersByUser}
        columns={columnsEmissionPerSuppliers}
        table={"table-manage-data"}
      />
    </div>
  );
};

export default SuppliersByUser;
