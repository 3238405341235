import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import InterfaceTable from "../../Table/InterfaceTable";
import {
  getDocumentsSelector,
  getDocumentsCountSelector,
} from "../../DocumentUpload/Infrastructure/DocumentUpload.reducer";
import { deleteDocument } from "../../DocumentUpload/Application/DocumentUpload.business";
import { message, Tooltip, Modal as ModalAntd } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import Formatter from "../../Data/Domain/Formatter";
import Document from "../../DocumentUpload/Domain/Document";

const FileBoard = ({
  userId,
  getByUser,
  imageCounter,
  getAll,
  role,
  User,
  type,
}) => {
  const documentsUser = useSelector(getDocumentsSelector);
  const count = useSelector(getDocumentsCountSelector);
  const [values, setValues] = useState({
    page: 0,
    limit: 5,
  });
  const userIdString = String(userId);
  const userTypeString = String(type);

  const [loading, setLoading] = useState(false);

  const [filters, setFilters] = useState("false");

  const handleDelete = async (id, params) => {
    try {
      ModalAntd.confirm({
        title: "¿Está seguro de que desea eliminar un documento?",
        okText: "Si",
        okType: "danger",
        onOk: async () => {
          try {
            await deleteDocument(id);
            if (role === User.CARBON_MANAGEMENT) {
              getAll(type, params);
            } else if (
              role === User.PARTNER ||
              role !== User.CARBON_MANAGEMENT
            ) {
              getByUser(id, params);
            }
            message.success("Se eliminó el documento");
          } catch (error) {
            message.error("Se produjo un error, vuelva a intentarlo");
          }
        },
      });
    } catch (error) {
      message.error("Se produjo un error, vuelva a intentarlo");
    }
  };

  const columnsUser = [
    {
      title: " URL",
      dataIndex: "url",
      key: "url",
      render: (text, record) => (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a href={text} target="_blank">
          {text}
        </a>
      ),
    },
    {
      title: "Descripción",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Fecha de carga",
      dataIndex: "created_at",
      key: "created_at",
      render: (date, record) => new Document(record).formatDate(date),
    },
    {
      title: "Usuario",
      dataIndex: "usuario",
      key: "usuario",
    },
    {
      title: "Acciones",
      dataIndex: "",
      key: "",
      render: (_, record) => {
        return (
          <>
            <div className="delete-ipfs">
              <div>
                <Tooltip title="Eliminar la carpeta">
                  <DeleteOutlined
                    onClick={() => {
                      handleDelete(record.id || record._id);
                    }}
                    style={{ color: "red" }}
                  />
                </Tooltip>
              </div>
            </div>
          </>
        );
      },
    },
  ];
  const fetchData = async (params = {}) => {
    try {
      console.log(JSON.stringify(params) + "params");

      let filters = {};
      if (role === User.CARBON_MANAGEMENT) {
        filters = { type: type };
      }
      const combinedParams = { ...params, filter: filters };
      if (role === User.CARBON_MANAGEMENT) {
        await getAll(userTypeString, combinedParams);
      } else if (role === User.PARTNER || role !== User.CARBON_MANAGEMENT) {
        console.log("userId:", userIdString, "params", params);
        getByUser(userIdString, params);
      }

      if (Object.entries(params).length > 0) message.success("Filtrado");
      setLoading(false);
    } catch (error) {
      message.error("error");
    }
  };
  useEffect(() => {
    if (role === User.CARBON_MANAGEMENT) {
      getAll(type);
    } else if (role === User.PARTNER || role !== User.CARBON_MANAGEMENT) {
      getByUser(userId);
    }
    fetchData({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, imageCounter, type]);

  const handleRequest = (newPagination = {}, filters = {}, sorter = {}) => {
    const { current: page = 1, pageSize: limit = 5 } = newPagination;
    const { field: sortField, order: sortOrder } = sorter;
    const clean = Formatter.cleanParamsFromTableToAPI({
      ...filters,
      limit,
      sortField,
      sortOrder,
      page: page - 1,
      dates: [],
    });
    setValues({
      ...values,
      limit,
      page: page - 1,
    });
    fetchData(clean);
  };

  return (
    <InterfaceTable
      rowKey="id"
      onChange={handleRequest}
      pagination={{
        pageSize: values.limit,
        current: values.page + 1,
        onChange: (current) => {
          if (role === User.CARBON_MANAGEMENT) {
            getAll({
              page: current - 1,
              limit: values.limit,
            });
          } else if (role === User.PARTNER || role !== User.CARBON_MANAGEMENT) {
            getByUser(userIdString, {
              page: current - 1,
              limit: values.limit,
            });
          }
        },
        total: count,
      }}
      data={documentsUser}
      columns={columnsUser}
    />
  );
};

export default FileBoard;
