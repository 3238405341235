import API from "../../Data/Domain/API";
import URL from "../../Data/Domain/URL";

export async function getOffers(params) {
  return await new API(URL.setParams(URL.buildUrl("offers"), params)).get();
}

export async function getOffer(id) {
  return await new API(URL.setId(URL.buildUrl("offers"), id)).get();
}
