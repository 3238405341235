import React from "react";
import SectionUnderConstruction from "../SectionUnderConstruction/SectionUnderConstruction";
const styles = {
  offset: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "calc(100vh - 21vh)",
  },
};

const Offset = () => {
  return (
    <div
      // style={{
      //   display: "flex",
      //   alignItems: "center",
      //   justifyContent: "center",
      //   minHeight: "calc(100vh - 21vh)",
      // }}
      style={styles.offset}
    >
      <SectionUnderConstruction />
    </div>
  );
};

export default Offset;
