import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import { getOffsetsSelector } from "../../DashboardOffset/Infrastructure/DashboardOffset.reducer";
import { Radio } from "antd";
import Modal from "../../Modal/Modal";
import { SearchOutlined } from "@ant-design/icons";

function DonutChart() {
  const emissions = useSelector(getOffsetsSelector);
  const [seriesOriginal, setSeriesOriginal] = useState();
  const [series, setSeries] = useState();
  const [percents, setPercents] = useState();
  const [labels, setLabels] = useState();
  const [options, setOptions] = useState();
  const [colors] = useState(["#0319a3", "#8998f5", "#00dd9e"]);
  const [visible, setVisible] = useState(false);
  const minValueVisible = 46;

  useEffect(() => {
    let labelData = [];
    let seriesData = [];
    let seriesDataOriginal = [];
    let percentData = [];
    const emissionsData = emissions.scopes;
    if (emissionsData !== undefined) {
      for (let obj in emissionsData) {
        if (obj !== "total") {
          let porcentaje = emissionsData[`${obj}`].percent;
          let quantity = emissionsData[`${obj}`].quantity;
          labelData.push(obj);
          seriesDataOriginal.push(quantity);

          if (quantity < minValueVisible) {
            quantity = minValueVisible;
          }

          seriesData.push(quantity);
          percentData.push(porcentaje);
        }
      }

      setLabels(labelData);
      setSeries(seriesData);
      setSeriesOriginal(seriesDataOriginal);
      setPercents(percentData);
    }
  }, [emissions.scopes]);

  useEffect(() => {
    if (labels !== undefined) {
      setOptions(_options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labels]);

  const _options = {
    title: {
      text: "",
      align: "left",
      margin: 0,
      offsetX: 0,
      offsetY: -20,
      floating: true,
      style: {
        fontSize: "21px",
        fontWeight: "bold",
        fontFamily: "MontserratBold",
        color: "#041370",
      },
    },
    colors: colors,
    tooltip: { enabled: false },
    //scope en los radios
    dataLabels: {
      enabled: true,
      align: "center",
      offsetX: 0,
      offsetY: 0,
      formatter: (value, opts) => {
        let quantity = seriesOriginal[opts.seriesIndex];
        return `${labels[opts.seriesIndex]}: ${quantity.toFixed(2)} / ${
          percents[opts.seriesIndex]
        }%`;
      },
      textAnchor: "middle",
      inverseOrder: false,
      distributed: true,
      tooltipHoverFormatter: ["es", "ff", "99"],
      style: {
        fontSize: "12px",
        fontFamily: "MontserratBold",
        fontWeight: "bold",
        colors: ["#041370"],
      },
      background: {
        enabled: true,
        foreColor: "#fff",
        padding: 12,
        borderRadius: 10,
        borderWidth: 0,
        borderColor: undefined,
        opacity: 0.85,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: "#3b3939",
          opacity: 0.45,
        },
      },
    },
    legend: {
      show: true,
      showForSingleSeries: false,
      showForNullSeries: true,
      showForZeroSeries: true,
      position: "bottom",
      horizontalAlign: "left",
      floating: true,
      fontSize: "14px",
      fontFamily: "MontserratBold",
      fontWeight: 400,
      formatter: (value, opts) => {
        return `${labels[opts.seriesIndex]}`;
      },
      inverseOrder: false,
      width: undefined,
      height: undefined,
      tooltipHoverFormatter: [],
      customLegendItems: [],
      offsetX: 50,
      offsetY: 50,
      labels: {
        colors: undefined,
        useSeriesColors: false,
      },
    },
  };

  return (
    <>
      <Radio.Button
        style={{
          borderRadius: "20px",
          height: "400px",
          width: "100%",
          margin: "0px",
          paddingTop: "25px",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
        }}
        className="scope-container"
      >
        <div className="text-container">
          <span style={{
            fontSize: "21px",
            fontWeight: "bold",
            fontFamily: "MontserratBold",
            color: "#041370",
          }} onClick={ () => setVisible(true)}>
            <SearchOutlined /> Scopes (ton CO2/%)
          </span>
          {emissions.scopes && options && (
            <Chart options={options} series={series} type="donut" width="380" />
          )}
        </div>
      </Radio.Button>
      {emissions.scopes && options && (
      <Modal
        width="540px"
        children={
          <>
          <h3>Scope1: {series[0]} /  {percents[0]}%</h3>
          <h3>Scope2: {series[1]} /  {percents[1]}%</h3>
          <h3>Scope3: {series[2]} /  {percents[2]}%</h3>
          </>
        }
        // icon={
        //   // <Tooltip title="Asociar un área">
        //   //   <ApiOutlined/>
        //   // </Tooltip>
        // }
        title="Scopes (ton CO2/%)"
        // form={}
        onClick={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        visible={visible}
        cancelButtonProps={{ style: { display: "none" } }}
        okText="Cerrar"
      />)}
    </>
  );
}
export default DonutChart;
