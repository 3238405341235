import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PageHeader, Row, Col, Typography, message, Modal } from "antd";
import "antd/dist/antd.min.css";
import "./CategorySection.css";
import OffssetData from "../../../OffssetData/OffsetData";
import { getSubcategoriesByCategAndUser } from "../../../Categories/Application/Categories.business";
import { getSubcategoriesByCategSelector } from "../../../Categories/Infrastructure/Categories.reducer";
import { postReduction } from "../../../Saving/Application/Saving.business";
import Storage from "../../../Data/Domain/Storage";

const { Text } = Typography;

const CategorySection = (props) => {
  const { id, title, subtitle } = props;
  const subcategories = useSelector(getSubcategoriesByCategSelector);
  const [activePanelKey, setActivePanelKey] = useState(null);

  const handleMitigations = (quantity, percent, parent) => {
    Modal.confirm({
      title: "Está seguro que desea mitigar la fila?",
      okText: "Si",
      okType: "danger",
      onOk: async () => {
        try {
          await postReduction({
            quantity,
            percent,
            parent,
            type: "reduction",
            id_user: new Storage("id").getItem(),
          });
          message.success("Se añadió a reducciones");
        } catch (error) {
          message.error("Se produjo un error, vuelva a intentarlo");
        }
      },
    });
  };
  const handlePanelChange = (key) => {
    if (activePanelKey === key) {
      setActivePanelKey(null);
    } else {
      setActivePanelKey(key);
    }
  };

  const fetchData = async (params = {}) => {
    try {
      await getSubcategoriesByCategAndUser(params);
    } catch (error) {
      message.error("error");
    }
  };

  useEffect(() => {
    fetchData({ id_category: id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageHeader className="section-content">
        <Row className="title-section">
          <Col xs={24} sm={24} md={12} lg={12}>
            <Text className="title-style">{title}</Text>
          </Col>
          {subtitle ? (
            <Col xs={24} sm={24} md={12} lg={12}>
              <Text className="subtitle-style">{subtitle}</Text>
            </Col>
          ) : (
            <div />
          )}
        </Row>
        <Row className="item-container" gutter={5}>
          {subcategories.map(
            ({ name, quantity, parent, percent, id: id_subcategory }) => {
              return (
                <Col xs={24} sm={24} md={24} lg={24} key={id_subcategory}>
                  <OffssetData
                    id={id_subcategory}
                    quantity={quantity}
                    name={name}
                    percent={percent}
                    icons={true}
                    mitigations={true}
                    expandible={true}
                    activePanelKey={activePanelKey}
                    handlePanelChange={handlePanelChange}
                    handleMitigations={() =>
                      handleMitigations(quantity, percent, parent)
                    }
                  />
                </Col>
              );
            }
          )}
        </Row>
      </PageHeader>
    </>
  );
};

export default CategorySection;
