import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PageHeader, Tag } from "antd";
import { getSelectedCompanySelector } from "../Infrastructure/Companies.reducer";
import { deselectCompany } from "../Infrastructure/Companies.store";
import CompaniesTab from "../../CompaniesTab/UI/companiesTab";

export default function CompaniesForm() {
  const selected = useSelector(getSelectedCompanySelector);
  const navigate = useNavigate();
  const [profile, setProfile] = useState("");

  const cancel = () => {
    deselectCompany();
    navigate("/companies");
  };

  return selected ? (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={cancel}
        tags={<Tag color="green">{selected.name}</Tag>}
        avatar={{
          src: profile ? profile : selected.profile_picture,
        }}
      >
        <CompaniesTab setProfile={setProfile} />
      </PageHeader>
    </div>
  ) : (
    <div />
  );
}
