import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Form, Tooltip, message, Modal as ModalAntd } from "antd";
import { EditOutlined, ApiOutlined, DeleteOutlined } from "@ant-design/icons";
import AreasEdit from "./AreasEdit";
import Modal from "../../Modal/Modal";

import InterfaceTable from "../../Table/InterfaceTable";
import { getAreas, deleteArea } from "../Application/Areas.business";
import { getAreasSelector } from "../Infrastructure/Areas.reducer";
import AreasAssociation from "./AreasAssociation";

const AreasBoard = () => {
  const areas = useSelector(getAreasSelector);
  const [form] = Form.useForm();

  useEffect(() => {
    getAreas();
  }, []);

  const handleDelete = async (id) => {
    try {
      console.log(id);
      ModalAntd.confirm({
        title: "Está seguro de que desea eliminar un área?",
        okText: "Si",
        okType: "danger",
        onOk: () => {
          deleteArea(id);
          message.success("Se eliminó el área");
        },
      });
    } catch (error) {
      message.error("Se produjo un error, vuelva a intentarlo");
    }
  };

  const flex = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const columns = [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Acciones",
      dataIndex: "",
      key: "",
      render: (_, record) => {
        return (
          <>
            <div style={flex}>

              <div>
                <Modal
                  children={<AreasEdit record={record} form={form} />}
                  icon={
                    <Tooltip title="Editar un área">
                      <EditOutlined />
                    </Tooltip>
                  }
                  style={{ color: "red" }}
                  title="Editar un área"
                  resetFields={"nameArea"}
                  form={form}
                  cancelButtonProps={{ style: { display: "none" } }}
                  okText="Cerrar"
                />
              </div>
              <div>
                <Modal
                  width="540px"
                  children={<AreasAssociation record={record} form={form} />}
                  icon={
                    <Tooltip title="Asociar un área">
                      <ApiOutlined/>
                    </Tooltip>
                  }
                  style={{ color: "red" }}
                  title="Asociar un área"
                  form={form}
                  cancelButtonProps={{ style: { display: "none" } }}
                  okText="Cerrar"
                />
            </div>
              <div>
                <Tooltip title="Eliminar un área">
                  <DeleteOutlined
                    onClick={() => {
                      // console.log({ record });
                      handleDelete(record.id);
                    }}
                    style={{ color: "red" }}
                  />
                </Tooltip>
              </div>
            </div>
          </>
        );
      },
    },
  ];
  return <InterfaceTable rowKey="id" data={areas} columns={columns} />;
};

export default AreasBoard;
