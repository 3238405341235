import store from "../../Redux/store";
import {
  setScriptOffset,
  countScriptOffset
} from "./ScriptOffsets.reducer";

export function saveListScriptOffset(records) {
  store.dispatch(setScriptOffset(records));
}
export function saveCountScriptOffset(count) {
  store.dispatch(countScriptOffset(count));
}

