import React from "react";
import { Progress } from "antd";
import "./ProgressCircle.css";

const ProgressCircle = (props) => {
  return (
    <>
      <Progress
        className="my-progress-circle"
        type="circle"
        percent={props.percentCircle}
        strokeColor="#00DD9E"
        width={props.width}
        strokeWidth={8}
      />
    </>
  );
};

export default ProgressCircle;
