import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Layout, Breadcrumb, Col, Row } from "antd";
import { getUser, getUsers } from "../Application/Users.business";
import Searcher from "../UI/Searcher";
import UsersForm from "../UI/Users.form";
import UsersBlockchain from "../UI/UsersBlockchain";
const { Content } = Layout;

export default function Users() {
  const { id } = useParams();
  useEffect(() => {
    getUsers();
    if (id) {
      getUser(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Content>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Searcher userId={id} />
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Col xs={24} xl={12} span={12}>
            <div
              style={{ margin: "16px ", background: "#ffffff" }}
              className="site-layout-content"
            >
              <UsersForm userId={id} getUser={getUser} />
            </div>
          </Col>
          <Col xs={24} xl={12} span={12}>
            <div
              style={{ margin: "16px ", background: "#ffffff" }}
              className="site-layout-content"
            >
              <UsersBlockchain
                userId={id}
                getUser={getUser}
                getUsers={getUsers}
              />
            </div>
          </Col>
        </Row>
      </Content>
    </>
  );
}
