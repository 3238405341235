import { Button } from "antd";
import { EyeFilled } from "@ant-design/icons";

const Viewer = (props) => {
  return props.link ? (
    <Button
      type="text"
      href={props.link}
      target={"_blank"}
      icon={<EyeFilled className="teamSocialIcon" />}
    >
      {props.title}
    </Button>
  ) : (
    <></>
  );
};

export default Viewer;
