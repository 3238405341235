import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Layout, Breadcrumb, Col, Row } from "antd";
import { Title } from "../../Utils/UI/Text";
import NewAdministrator from "./NewAdministrator";
import { getAdmin, getAdmins } from "../Application/Administrators.business";
import Searcher from "../UI/Searcher";
import UsersForm from "./Administrator.form";

const { Content } = Layout;

export default function Administrator() {
  const { id } = useParams();

  useEffect(() => {
    getAdmins();
    if (id) {
      getAdmin(id);
    }
  }, [id]);

  return (
    <>
      <Content>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Row>
              <Col
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 20,
                }}
                span={24}
              >
                <Title>Administradores</Title>
                <NewAdministrator />
              </Col>
            </Row>
            <Searcher userId={id} />
            <Col xs={24} xl={12} span={12}>
              <div
                style={{ margin: "16px ", background: "#ffffff" }}
                className="site-layout-content"
              >
                <UsersForm userId={id} />
              </div>
            </Col>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Content>
    </>
  );
}
