import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "stats",
  initialState: {
    data: {},
  },
  reducers: {
    setStats: (state, { payload: data }) => {
      return {
        ...state,
        data,
      };
    },
  },
});
export const { setStats } = slice.actions;

export default slice.reducer;

export const getStatsSelector = (store) => store.stats.data;
