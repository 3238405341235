import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "bankAccounts",
  initialState: {
    list: [],
    url: null,
    selected: null,
    count: 0,
  },
  reducers: {
    setUrl: (state, { payload: url }) => {
      return { ...state, url };
    },
    setBankAccounts: (state, { payload: list }) => {
      return {
        ...state,
        list,
      };
    },
    countBankAccounts: (state, { payload: count }) => {
      return {
        ...state,
        count,
      };
    },
    selectBankAccount: (state, { payload: selected }) => {
      return {
        ...state,
        selected,
      };
    },
    cancelSelectedBankAccount: (state) => {
      return {
        ...state,
        selected: null,
      };
    },
    deleteBankAccount: (state, { payload: id }) => {
      return {
        ...state,
        list: [...state.list.filter((elem) => elem.id !== id)],
      };
    },
    addBankAccounts: (state, { payload: list }) => {
      return {
        ...state,
        list: [...state.list, list],
      };
    },
  },
});

export const {
  setBankAccounts,
  countBankAccounts,
  selectBankAccount,
  cancelSelectedBankAccount,
  deleteBankAccount,
  addBankAccounts,
  setUrl,
} = slice.actions;

export default slice.reducer;

export const getUrl = (store) => store.bankAccounts.url;
export const getBankAccountsSelector = (store) => store.bankAccounts.list;
export const getSelectedBankAccountSelector = (store) =>
  store.bankAccounts.selected;
export const getBankAccountsCountSelector = (store) => store.bankAccounts.count;
export const deleteBankAccountSelector = (store) => store.bankAccounts.id;
export const postBankAccountsSelector = (store) => store.bankAccounts.list;
