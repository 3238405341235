import {
  getCategoriesRepository,
  getSubcategoriesDataRepository,
  updateSubcategoriesRepository,
  getSubcategorytRepository,
  getSubcategoriesByCategAndUserRepository,
  getCategoriesByUserRepository,
} from "../Infrastructure/Categories.repository";

export async function getCategoriesLogic(params) {
  return getCategoriesRepository(params);
}
export async function getCategoryLogic(id) {
  return getSubcategoriesDataRepository(id);
}
export async function updateSubcategoriesLogic(id, params) {
  return updateSubcategoriesRepository(id, params);
}
export async function getSubcategoryLogic(id) {
  return getSubcategorytRepository(id);
}
export async function getSubcategoriesByCategAndUserLogic(params) {
  return getSubcategoriesByCategAndUserRepository(params);
}
export async function getCategoriesByUserLogic(id) {
  return getCategoriesByUserRepository(id);
}
