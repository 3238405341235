import {
  getTransactionsLogic,
  getTransactionLogic,
  patchTransactionLogic,
  postTransactionLogic,
} from "./Transactions.logic";
import {
  saveCountTransactions,
  saveListTransactions,
  saveSelectedTransaction,
  savePostListTransaction,
  saveTotalTransactions,
  saveTotalUsd,
} from "../Infrastructure/Transactions.store";

export async function getTransactions(params) {
  const {
    total_usd,
    total_transactions,
    count,
    resources: transactions,
  } = await getTransactionsLogic(params);
  saveListTransactions(transactions);
  saveCountTransactions(count);
  saveTotalTransactions(total_transactions);
  saveTotalUsd(total_usd);
}

export async function getTransaction(id) {
  const transaction = await getTransactionLogic(id);
  saveSelectedTransaction(transaction);
}

export async function patchTransaction(id) {
  return await patchTransactionLogic(id);
}

export async function postTransaction(params) {
  const transaction = await postTransactionLogic(params);
  savePostListTransaction(transaction);
}
