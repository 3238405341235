import {
  getProjectsLogic,
  getProjectLogic,
  getProjectIpfsLogic,
  updateBondsLogic,
  patchProjectLogic,
  updateProjectLogic,
  updateCoordinatesLogic,
  removeContactLogic,
  deleteFileIpfsLogic,
  getProjectImagesLogic,
  deleteFileImageLogic,
  getProjectTransactionsLogic
} from "./Projects.logic";
import {
  saveCountProjects,
  saveListProjects,
  saveSelectedProject,
  saveIpfs,
  saveImages,
  saveTransactions
} from "../Infrastructure/Projects.store";

export async function getProjects(params) {
  const { count, resources: projects } = await getProjectsLogic(params);
  saveListProjects(projects);
  saveCountProjects(count);
}

export async function getProject(id) {
  const project = await getProjectLogic(id);
  saveSelectedProject(project);
}

export async function getProjectIpfs(id) {
  const ipfs = await getProjectIpfsLogic(id);
  saveIpfs(ipfs);
}

export async function updateBonds(id, params) {
  await updateBondsLogic(id, params);
  const project = await getProjectLogic(id);
  saveSelectedProject(project);
}
export async function updateProject(id, params) {
  await updateProjectLogic(id, params);
  const project = await getProjectLogic(id);
  saveSelectedProject(project);
}

export async function updateCoordinates(id, params) {
  await updateCoordinatesLogic(id, params);
  const project = await getProjectLogic(id);
  saveSelectedProject(project);
}

export async function patchProject(id, params) {
  await patchProjectLogic(id, params);
  const project = await getProjectLogic(id);
  saveSelectedProject(project);
}

// export async function removeContact(id) {
//   await removeContactLogic(id);
//   deleteSelectedremoveContact(id);
// }
export async function removeContact(id, params) {
  await removeContactLogic(id, params);
  const project = await getProjectLogic(id);
  saveSelectedProject(project);
}
export async function deleteFileIpfs(id, params) {
  await deleteFileIpfsLogic(id, params);
  const project = await getProjectIpfsLogic(id);
  saveIpfs(project);
}
export async function getProjectImages(id) {
  const images = await getProjectImagesLogic(id);
  saveImages(images);
}
export async function deleteFileImage(id, params) {
  await   deleteFileImageLogic(id, params);
  const image = await getProjectImagesLogic(id);
  saveImages(image);
}

export async function getProjectTransactions(id, state, type) {
  const transactions = await getProjectTransactionsLogic(id, state, type);
  saveTransactions(transactions);
}