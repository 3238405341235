import React from "react";
import { Radio } from "antd";
import Scope from "../DesignSystem/Scope/Scope";
import "./Scopes.css";

const Scopes = ({ data, onChange, active }) => {
  return (
    <Radio.Group value={active} onChange={onChange} buttonStyle="solid">
      {data.map(({ id, title, subtitle, percent }) => {
        return (
          <div key={id}>
            <Scope id={id} title={title} subtitle={subtitle} percent={percent} />
          </div>
        );
      })}
    </Radio.Group>
  );
};

export default Scopes;
