import API from "../../Data/Domain/API";
import URL from "../../Data/Domain/URL";

export async function getBankAccounts(params) {
  return await new API(
    URL.setParams(URL.buildUrl("bankAccounts"), params)
  ).get();
}

export async function getByCompany(id) {
  return await new API(URL.buildUrl(`companies/${id}/bankAccounts`)).get();
}

export async function deleteBankAccount(id) {
  return await new API(URL.setId(URL.buildUrl("bankAccounts"), id)).delete();
}

export async function postBankAccount({
  owner,
  bank_name,
  sort_code,
  account_number,
  swiftbic,
  iba,
  user,
  document,
}) {
  return await new API(URL.buildUrl("bankAccounts")).post({
    owner,
    bank_name,
    sort_code,
    account_number,
    swiftbic,
    iba,
    user,
    document,
  });
}
export async function getBankAccount(id) {
  return await new API(URL.setId(URL.buildUrl("bankAccounts"), id)).get();
}
