import React, { useState, useEffect } from "react";
import { Skeleton, message } from "antd";
import "./ProjectLocation.css";
import Button from "../../Layout/DesignSystem/Button/Button";
import { useJsApiLoader, GoogleMap, Marker } from "@react-google-maps/api";
import {
  updateCoordinates,
} from "../Application/Projects.business";


export default function ProjectLocation({ record = {} }) {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });

  const [loading, setLoading] = useState(false);
  const [position, setPosition] = useState({});


  useEffect(() => {
    if (record) {
      if (position.lat && position.lng) {
        let lat = position.lat;
        let lng = position.lng;
        setPosition({ lat: lat, lng: lng });
      } else if (record.latitude && record.longitude) {
        let lat = record.latitude;
        let lng = record.longitude;
        setPosition({ lat: lat, lng: lng });
      } else {
        setPosition({ lat: 0, lng: 180 });
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  if (!isLoaded) {
    return <Skeleton />;
  }

  function onMarkerDragEnd(event) {
    let newLat = event.latLng.lat();
    let newLng = event.latLng.lng();
    setPosition({ lat: newLat, lng: newLng });
  }

  const handleUpdate = () => {
    setLoading(true);
    const data = {
      latitude: position.lat,
      longitude: position.lng,
    };

    updateCoordinates(record.id, data)
      .then(() => {
        message.success("Coordenadas actualizadas con éxito");
        setLoading(false);
      })
      .catch(() => {
        message.error("Error al actualizar las coordenadas");
        setLoading(false);
      });
  };

  return (
    <>
      <div className="boxMaps">
        <GoogleMap
          center={position}
          zoom={4}
          mapContainerStyle={{ width: "100%", height: "100%" }}
          options={{
            zoomControl: true,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: true,
          }}
        >
          <Marker
            position={position}
            draggable={true}
            onDragEnd={onMarkerDragEnd}
          />
        </GoogleMap>
        <div>
          {loading ? (
            <Button loading={loading} text={"Cargando"} block={true} />
          ) : (
            <Button onClick={handleUpdate} text={"Actualizar"} block={true} />
          )}
        </div>
      </div>
    </>
  );
}
