import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PageHeader, Tag, Descriptions, Tabs } from "antd";
import {
  getSelectedUserSelector,
  getUsersCountSelector,
} from "../Infrastructure/Users.reducer";
import { deselectUser } from "../Infrastructure/Users.store";
import InterfaceTable from "../../Table/InterfaceTable";

const { TabPane } = Tabs;

const TransactionsColumns = [
  {
    title: "Tipo",
    dataIndex: "typeValue",
    key: "typeValue",
  },
  {
    title: "Estado",
    dataIndex: "stateValue",
    key: "stateValue",
  },
  {
    title: "Monto (USD)",
    dataIndex: "calculatePayment",
    key: "calculatePayment",
  },
];

const OffersColumns = [
  {
    title: "Tipo",
    dataIndex: "typeValue",
    key: "typeValue",
  },
  {
    title: "Estado",
    dataIndex: "statusValue",
    key: "statusValue",
  },
  {
    title: "Monto (EGRN)",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "Precio (USD)",
    dataIndex: "price",
    key: "price",
  },
  {
    title: "Comisión (USD)",
    dataIndex: "fee",
    key: "fee",
  },
  {
    title: "Total (USD)",
    dataIndex: "total",
    key: "total",
  },
];

const tagText = {
  fontFamily: "MontserratMedium",
  fontWeight: 500,
};

export default function UsersForm({ id, getUser, getUsers }) {
  const selected = useSelector(getSelectedUserSelector);
  const navigate = useNavigate();
  const count = useSelector(getUsersCountSelector);
  const limit = 5;

  const cancel = () => {
    deselectUser();
    navigate("/users");
  };
  const bold = {
    fontWeight: 600,
    margin: 0,
  };
  useEffect(() => {
    if (id) {
      getUser(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return selected ? (
    <div>
      <PageHeader
        onBack={cancel}
        title={
          <Tag color="blue" style={tagText}>
            Blockchain
          </Tag>
        }
      >
        <Descriptions layout="vertical" bordered>
          <Descriptions.Item label="EGRN disponibles">
            {selected.availableCoins}
          </Descriptions.Item>
          <Descriptions.Item label="USD disponibles ">
            {selected.availableUSD}
          </Descriptions.Item>
          <Descriptions.Item label="EGRN totales">
            {selected.totalCoins}
          </Descriptions.Item>
          {/* <Descriptions.Item label="CO2 compensado">
            {selected.getCo2 ? selected.getCo2() : "N/A"}
          </Descriptions.Item> */}
          <Descriptions.Item label="Créditos de carbono comprados (NFT) ">
            {selected.availableNFT}
          </Descriptions.Item>
          <Descriptions.Item label="Créditos de carbono compensados (SBT)">
            {selected.availableSBT}
          </Descriptions.Item>
          <Descriptions.Item label=" LUMENS disponibles">
            {selected.getNativeBalance ? selected.getNativeBalance() : "N/A"}
          </Descriptions.Item>
        </Descriptions>

        <Tabs defaultActiveKey="1" centered style={tagText}>
          <TabPane tab="Transacciones" key="2">
            <InterfaceTable
              rowKey={(record) => record.id}
              pageSize={limit}
              total={count}
              onChange={(current) => {
                getUsers({ page: current - 1, limit });
              }}
              data={selected.transactions}
              columns={TransactionsColumns}
              expandable={{
                expandedRowRender: (record) =>
                  record.typeValue === "Compra de bonos" ? (
                    <div>
                      <Descriptions style={bold} layout="vertical" bordered>
                        <Descriptions.Item label="Proyecto Invertido">
                          {record.project_invested}
                        </Descriptions.Item>
                        <Descriptions.Item label="Método de Pago">
                          {record.paymentMethodValue}
                        </Descriptions.Item>
                        <Descriptions.Item label="EGRN Coins">
                          {record.total_coins}
                        </Descriptions.Item>
                      </Descriptions>
                    </div>
                  ) : record.typeValue === "Compra de dólares" ? (
                    <Descriptions style={bold} layout="vertical" bordered>
                      <Descriptions.Item label="Método de Pago">
                        {record.paymentMethodValue}
                      </Descriptions.Item>
                      <Descriptions.Item label="Monto de Dólares">
                        {record.amount_usd}
                      </Descriptions.Item>
                    </Descriptions>
                  ) : record.typeValue === "Cálculo de huella" ? (
                    ""
                  ) : (
                    ""
                  ),
              }}
            />
          </TabPane>
          <TabPane tab="Ofertas" key="1">
            <InterfaceTable
              rowKey={(record) => record.id}
              pageSize={limit}
              total={count}
              onChange={(current) => {
                getUsers({ page: current - 1, limit });
              }}
              data={selected.offers}
              columns={OffersColumns}
              expandable={{
                expandedRowRender: (record) => (
                  <Descriptions style={bold} layout="vertical" bordered>
                    <Descriptions.Item label="Fecha de creación:">
                      {record.date}
                    </Descriptions.Item>
                  </Descriptions>
                ),
              }}
            />
          </TabPane>
        </Tabs>
      </PageHeader>
    </div>
  ) : (
    <div />
  );
}
