import store from "../../Redux/store";
import { deleteContact, countProjects, setProjects, selectProject, setIpfs,setImages,setTransactions } from "./Projects.reducer";

export function saveListProjects(records) {
  store.dispatch(setProjects(records));
}

export function saveCountProjects(count) {
  store.dispatch(countProjects(count));
}

export function saveSelectedProject(project) {
  store.dispatch(selectProject(project));
}
export function deleteSelectedremoveContact(id) {
  store.dispatch(deleteContact(id));
}
export function saveIpfs(records) {
  store.dispatch(setIpfs(records));
}
export function saveImages(records) {
  store.dispatch(setImages(records));
}
export function saveTransactions(records) {
  store.dispatch(setTransactions(records));
}
