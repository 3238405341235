import React from "react";
import { grisObscuro, main, normal } from "./Colors";

const styles = {
  title: {
    fontFamily: "MontserratBold",
    fontWeight: "700",
    fontSize: "32px",
    color: main,
  },
  subtitle: {
    fontWeight: "800",
    fontSize: "24px",
    color: grisObscuro,
  },
  textbody: {
    fontWeight: "600",
    fontSize: "12px",
    color: normal,
  },
  tag: {
    padding: "0.1em 0.5em",
    color: main,
    fontWeight: "800",
    borderRadius: "20px",
    boxShadow: "0px 3px 5px 0px rgb(181,181,181, 0.5)",
  },
};

export function Title({ children }) {
  return <span style={styles.title}>{children}</span>;
}
