import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Col, Row, Input, message } from "antd";
import { Title } from "../../Utils/UI/Text";
import Statistics from "../../Statistics/Statistics";
import InterfaceTable from "../../Table/InterfaceTable";
import {
  getUsersSelector,
  getUsersCountSelector,
  getcountWithoutTransactionsSelector,
} from "../../Users/Infrastructure/Users.reducer";
import { getUsers } from "../../Users/Application/Users.business";
import "./CRM.css";
import Formatter from "../../Data/Domain/Formatter";
import ExportButtonElement from "../../Layout/DesignSystem/ExportButton/Button";


const { Search } = Input;

const CRM = () => {
  const crm = useSelector(getUsersSelector);
  const countUsers = useSelector(getUsersCountSelector);
  const countWithoutTransactions = useSelector(
    getcountWithoutTransactionsSelector
  );
  const dates = useRef([]);
  const [values, setValues] = useState({
    page: 0,
    limit: 10,
  });
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState("false");

  useEffect(() => {
    fetchData({});
  }, []);

  const onChange = (changed_values) => {
    const value = changed_values.target
      ? changed_values.target.value
      : changed_values;
    fetchData({ filter: { q: value } });
  };

  const fetchData = async (params) => {
    try {
      await getUsers(params);
      const filtros = params.filter ? JSON.stringify(params.filter) : [""];
      setFilters(filtros);
      message.success("Filtrado");
      setLoading(false);
    } catch (error) {
      message.error("error");
    }
  };

  const handleRequest = (newPagination = {}, filters = {}, sorter = {}) => {
    const { current: page = 1, pageSize: limit = 10 } = newPagination;
    const { field: sortField, order: sortOrder } = sorter;
    const clean = Formatter.cleanParamsFromTableToAPI({
      ...filters,
      limit,
      sortField,
      sortOrder,
      page: page - 1,
      dates: dates.current,
    });
    setValues({
      ...values,
      limit,
      page: page - 1,
    });
    fetchData(clean);
  };

  const statistics = [
    {
      value: countUsers,
      title: "Número total de usuarios registrados",
    },
    {
      value: countWithoutTransactions,
      title: "Número total de usuarios sin transacciones",
    },
  ];

  const CrmColumns = [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Apellido",
      dataIndex: "lastname",
      key: "lastname",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
  ];

  const title = {
    marginBottom: 20,
  };

  return (
    <>
      <Row style={title}>
        <Col
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 20,
          }}
          span={24}
        >
          <Col span={12}>
            <Title>CRM</Title>
          </Col>
          <Col span={12} style={{ width: 112, display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
            <ExportButtonElement
              type={"users"}
              text={"Exportar"}
              filter={filters}
            />
          </Col>
        </Col>
      </Row>


      <Statistics statistics={statistics} />
      <div className="search">
        <Search
          placeholder="Buscar"
          onSearch={onChange}
          onChange={onChange}
          className="search-item"
        />
      </div>
      <InterfaceTable
        rowKey="id"
        data={crm}
        columns={CrmColumns}
        pagination={{
          current: values.page + 1,
          pageSize: values.limit,
          total: countUsers,
        }}
        loading={loading}
      // onChange={handleRequest}
      />
    </>
  );
};

export default CRM;
