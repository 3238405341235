import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { getAreasByUser } from "../../Areas/Application/Areas.business";
import { getAreasByUserSelector } from "../../DataUser/Infrastructure/DataUser.reducer";
import NewAreaManageData from "./NewAreaManageData";
import InterfaceTable from "../../Table/InterfaceTable";

const columnsEmissionPerAreas = [
  {
    title: "Áreas",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Emisiones (tons)",
    dataIndex: "quantity",
    key: "quantity",
  },
];

const AreasByUser = ({ userId }) => {
  const areasByUser = useSelector(getAreasByUserSelector);

  useEffect(() => {
    if (userId) {
      getAreasByUser(userId);
    }
  }, [userId]);

  return (
    <div>
      <NewAreaManageData userId={userId} />
      <InterfaceTable
        rowKey={(record) => record._id}
        data={areasByUser}
        columns={columnsEmissionPerAreas}
        table={"table-manage-data"}
      />
    </div>
  );
};

export default AreasByUser;
