import {
  getScriptOffsetByUserRepository,
  updateScriptOffsetRepository
} from "../Infrastructure/ScriptOffsets.repository";

export async function getScriptOffsetByUserLogic(id, params) {
  try {
    const result = await getScriptOffsetByUserRepository(id, params);
    return result;
  } catch (error) {
    console.error("Logic Error:", error);
    throw error;
  }
}

export async function updateScriptOffsetLogic(id, params) {
  try {
    const result = await updateScriptOffsetRepository(id, params);
    return result;
    
  } catch (error) {
    console.error("Logic Error:", error);
    throw error;
  }
}
