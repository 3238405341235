import React, { useState, useEffect } from "react";
import { Form, Select, Row, Button, message } from "antd";
import { MailOutlined } from "@ant-design/icons";
import InputToken from "../../Token/UI/Code";
import { postToken } from "../../Token/Infrastructure/Token.service";
import Token from "../../Token/Domain/Token";
import { updateCompany } from "../../Companies/Infrastructure/Companies.service";

const { Option } = Select;

export default function CompaniesStatus(props) {
  const { user } = props;
  const [form] = Form.useForm();
  const [disabledForm, setDisabledForm] = useState(true);
  const [disabledButton, setDisabledButton] = useState(true);
  const [token, setToken] = useState(false);
  const [values, setValues] = useState({});
  const [, forceUpdate] = useState({});

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {}, [disabledForm]);

  const handleChange = (value) => {
    setValues({
      ...values,
      type: value,
    });
    setDisabledButton(false);
  };

  const onReset = () => {
    form.resetFields();
  };

  const handleSubmit = async () => {
    try {
      await postToken(
        new Token({
          id: user.id,
          type: "company",
        }).forCreation()
      );
      message.success("Toquen enviado");
      setToken(true);
    } catch (error) {
      message.error("Se produjo un error, vuelve a intentarlo");
      setDisabledForm(true);
      form.resetFields();
    }
  };

  const handleSave = async () => {
    try {
      await updateCompany(user.id, { verified: values.type });
      message.success("Se modificó el estado correctamente");
      setToken(false);
      setDisabledForm(true);
      setDisabledButton(true);
    } catch (error) {
      message.error(" Se produjo un error, vuelve a intentarlo");
    }
  };

  return (
    <>
      <Row justify="center">
        <Form
          layout="horizontal"
          form={form}
          onReset={onReset}
          initialValues={{
            status: user.verified,
          }}
        >
          <Form.Item name="status" label="Estado">
            <Select
              style={{
                width: 300,
              }}
              onChange={handleChange}
            >
              <Option value="pending">Pendiente</Option>
              <Option value="succeeded">Verificado</Option>
              <Option value="rejected">Rechazado</Option>
            </Select>
          </Form.Item>
          {token && (
            <Form.Item>
              <InputToken
                placeholder="Ingrese tu código"
                text="Validar"
                prefix={<MailOutlined className="site-form-item-icon" />}
                type="primary"
                disabled={true}
                setDisabledForm={setDisabledForm}
              />
            </Form.Item>
          )}
          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
            shouldUpdate
          >
            {!token ? (
              () => (
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={handleSubmit}
                  disabled={
                    !form.isFieldsTouched(true) ||
                    !!form
                      .getFieldsError()
                      .filter(({ errors }) => errors.length).length ||
                    disabledButton
                  }
                >
                  Solicitar código
                </Button>
              )
            ) : (
              <Button
                type="primary"
                htmlType="submit"
                disabled={disabledForm}
                onClick={handleSave}
              >
                Guardar
              </Button>
            )}
          </Form.Item>
        </Form>
      </Row>
    </>
  );
}
