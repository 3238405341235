import store from "../../Redux/store";
import { setAreas, deleteArea } from "./Areas.reducer";
import { setAreasByUser } from "../../DataUser/Infrastructure/DataUser.reducer";

export function saveListAreas(records) {
  store.dispatch(setAreas(records));
}

export function deleteSelectedArea(id) {
  store.dispatch(deleteArea(id));
}
export function saveListAreasByUser(records) {
  store.dispatch(setAreasByUser(records));
}
