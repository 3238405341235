import store from "../../Redux/store";
import {
  setSuppliers,
  setSuppliersBySubcategory,
  selectSupplier,
  deleteSupplier,
} from "./Suppliers.reducer";
import { setSuppliersByUser } from "../../DataUser/Infrastructure/DataUser.reducer";

export function saveListSuppliers(records) {
  store.dispatch(setSuppliers(records));
}
export function saveSelectedSuppliers(supplier) {
  store.dispatch(selectSupplier(supplier));
}
export function deleteSelectedSupplierst(id) {
  store.dispatch(deleteSupplier(id));
}
export function saveListSuppliersByUser(records) {
  store.dispatch(setSuppliersByUser(records));
}
export function saveListSuppliersBySubcategory(records) {
  store.dispatch(setSuppliersBySubcategory(records));
}
