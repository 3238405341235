import React from "react";
import { Row, Col } from "antd";
import { Title } from "../../Utils/UI/Text";
import SearcherCategories from "./SearcherCategories";
import TagsSubcategories from "./TagsSubcategories";
import SearcherSubcategories from "./SearcherSubcategories";
import CategoriesDetail from "./CategoriesDetail";

const Categories = () => {
  const title = {
    marginBottom: 20,
  };
  return (
    <>
      <Row style={title}>
        <Col
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 20,
          }}
          span={24}
        >
          <Title>Categorías</Title>
          <CategoriesDetail />
        </Col>
      </Row>

      <Row gutter={[8, 8]}>
        <Col span={12}>
          <SearcherCategories />
        </Col>
        <Col span={12}>
          <SearcherSubcategories />
        </Col>
      </Row>
      <TagsSubcategories />
    </>
  );
};
export default Categories;
