import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import { getOffsetsSelector } from "../../DashboardOffset/Infrastructure/DashboardOffset.reducer";
import { Radio } from "antd";

function BarChart() {
  const emissions = useSelector(getOffsetsSelector);
  const [years, setYears] = useState();
  const [series, setSeries] = useState();
  const [options, setOptions] = useState();
  const [colors] = useState(["#0319a3"]);

  useEffect(() => {
    let yearsData = [];
    let totalData = [];
    const emissionsData = emissions.emission_per_year;
    for (let obj in emissionsData) {
      const emission = emissionsData[obj]
      yearsData.push(emission.year);
      totalData.push(emission.emission);
    }
    setYears(yearsData);
    setSeries([
      {
        name: "Emission",
        data: totalData,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emissions.data]);

  useEffect(() => {
    if (years !== undefined) {
      setOptions(_options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [years]);

  const _options = {
    title: {
      text: "Emission per year",
      align: "left",
      margin: 80,
      offsetX: 0,
      offsetY: -30,
      floating: true,
      style: {
        fontSize: "21px",
        fontWeight: "bold",
        fontFamily: "MontserratBold",
        color: "#041370",
      },
    },
    colors: colors,
    tooltip: { enabled: false },
    series: [
      {
        // data: [349, 780]
      },
    ],
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        tools: {
          download: false,
          selection: true,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        horizontal: false,
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return Number(val).toLocaleString();
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#0319a3"],
      },
    },
    xaxis: {
      categories: years,
    },
  };

  return (
    <>
      <Radio.Button
        style={{
          borderRadius: "20px",
          height: "400px",
          width: "100%",
          margin: "0px",
        }}
        className="scope-container"
      >
        <div className="text-container">
          <div className="donut">
            {emissions.data && options && (
              <Chart
                options={options}
                series={series}
                type="bar"
                height={300}
                width={500}
              />
            )}
          </div>
        </div>
      </Radio.Button>
    </>
  );
}
export default BarChart;
