import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "companies",
  initialState: {
    list: [],
    selected: null,
    count: 0,
  },
  reducers: {
    setCompanies: (state, { payload: list }) => {
      return {
        ...state,
        list,
      };
    },
    countCompanies: (state, { payload: count }) => {
      return {
        ...state,
        count,
      };
    },
    selectCompany: (state, { payload: selected }) => {
      return {
        ...state,
        selected,
      };
    },
    cancelSelectedCompany: (state) => {
      return {
        ...state,
        selected: null,
      };
    },
  },
});

export const {
  setCompanies,
  countCompanies,
  selectCompany,
  cancelSelectedCompany,
  setCollaborators,
} = slice.actions;

export default slice.reducer;

export const getCompaniesSelector = (store) => store.companies.list;
export const getSelectedCompanySelector = (store) => store.companies.selected;
export const getCompaniesCountSelector = (store) => store.companies.count;
