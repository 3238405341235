import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "categoriesEmission",
  initialState: {
    categories_emission: [],
  },
  reducers: {
    setEmissionCategory: (state, { payload: categories_emission }) => {
      return {
        ...state,
        categories_emission,
      };
    },
  },
});

export const { setEmissionCategory } = slice.actions;
export default slice.reducer;
export const getEmissionCategorySelector = (store) =>
  store.categoriesEmission.categories_emission;
