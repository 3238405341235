import API from "../../Data/Domain/API";
import URL from "../../Data/Domain/URL";
import Storage from "../../Data/Domain/Storage";

// export async function getManageData(id) {
//   // Todo: Cambiar users/:id/offsets
//   return await new API(URL.setId(URL.buildUrl("offsets"), id)).get();
// }

// export async function patchManageData(id, data) {
//   return await new API(URL.buildUrl(`users/${id}/offsets`)).patch(data);
// }

export async function deleteMonth(id, id_month) {
  console.log(`users/${id}/offsets/${id_month}/months`);
  return await new API(
    URL.buildUrl(`users/${id}/offsets/${id_month}/months`)
  ).delete();
}
