import {
  getOwnersByProjectLogic,
  } from "./Owners.logic";
  import {
    saveListOwnersByProject,
    saveCountOwnersByProject
  } from "../Infrastructure/Owners.store";

export async function getOwnersByProject(id) {
  const { resources: project,count } = await getOwnersByProjectLogic(id);
  saveListOwnersByProject(project);
  saveCountOwnersByProject(count);

}
