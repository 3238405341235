import {
  getReductionsRepository,
  deleteReductionRepository,
  getReductionRepository,
  postReductionRepository,
} from "../Infrastructure/Saving.repository";

export async function getReductionsLogic(params) {
  return getReductionsRepository(params);
}

export async function deleteReductionLogic(id) {
  return deleteReductionRepository(id);
}
export async function getReductionLogic(id) {
  return getReductionRepository(id);
}

export async function postReductionLogic(params) {
  return postReductionRepository(params);
}
