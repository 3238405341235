import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Formatter from "../../Data/Domain/Formatter";
import { Card, Col, Row, Typography, message, Button, Tooltip } from "antd";
import InterfaceTable from "../../Table/InterfaceTable";
import { getOwnersByProject } from "../Application/Owners.bussines";
import {
  getsetOwnersByProjectSelector,
  getOwnersByProjectCountSelector,
} from "../Infrastructure/Owners.reducer";
import NewOwners from "./NewOwners";
import "./Owners.css";
import Storage from "../../Data/Domain/Storage";
import { useNavigate } from "react-router-dom";
import ProjectUploadImage from "../../Projects/UI/ProjectUploadImage";
import Modal from "../../Modal/Modal";
import { FileImageOutlined, GlobalOutlined } from "@ant-design/icons";
import ProjectsTranslations from "../../Projects/UI/ProjectsTranslations";

const { Text } = Typography;

const Owners = ({ setPage }) => {
  const ownersByProject = useSelector(getsetOwnersByProjectSelector);
  // console.log(JSON.stringify(ownersByProject));
  const ownersByCount = useSelector(getOwnersByProjectCountSelector);
  // console.log(JSON.stringify(ownersByCount));
  const [values, setValues] = useState({
    page: 0,
    limit: 5,
  });
  const navigate = useNavigate();

  const columns = [
    {
      title: "Nombre",
      dataIndex: "project_name",
      key: "project_name",
    },
    {
      title: "Acciones",
      dataIndex: "",
      key: "",
      render: (_, record) => {
        const handleClick = ({ setPage, type, record }) => {
          switch (type) {
            case "ipfs":
              navigate(`/projects/${record.id}/ipfs`);
              setPage("ipfs");
              break;
            case "generals":
              navigate(`/projects/${record.id}/generals`);
              setPage("generals");
              break;
            case "images":
              navigate(`/projects/${record.id}/images`);
              setPage("images");
              break;
            case "transactionsProjects":
              navigate(`/projects/${record.id}/transactions`);
              setPage("transactionsProjects");
              break;
            default:
              break;
          }
        };

        return (
          <div className="delete-ipfs">
            <div className="button-ipfs">
              <Button
                type="primary"
                danger
                ghost
                onClick={() => handleClick({ setPage, type: "ipfs", record })}
              >
                IPFS
              </Button>
            </div>
            <div>
              <Modal
                children={<ProjectUploadImage record={record} />}
                icon={
                  <Tooltip title="Imagen del Proyecto">
                    <FileImageOutlined />
                  </Tooltip>
                }
                style={{ color: "red" }}
                title="Imagen del Proyecto"
              />
            </div>
            <div>
              {
                <Modal
                  icon={
                    <Tooltip title="Traducciones">
                      <GlobalOutlined />
                    </Tooltip>
                  }
                  style={{ color: "red" }}
                  title="Traducciones"
                  width={1000}
                  cancelButtonProps={{ style: { display: "none" } }}
                  okButtonProps={{ style: { display: "none" } }}
                  children={<ProjectsTranslations record={record} />}
                />
              }
            </div>
            <div className="button-info">
              <Button
                type="primary"
                danger
                ghost
                onClick={() =>
                  handleClick({ setPage, type: "generals", record })
                }
              >
                GENERAL
              </Button>
            </div>
            <div className="button-info">
              <Button
                type="primary"
                danger
                ghost
                onClick={() => handleClick({ setPage, type: "images", record })}
              >
                IMÁGENES
              </Button>
            </div>
            <div className="button-info">
              <Button
                type="primary"
                danger
                ghost
                onClick={() =>
                  handleClick({ setPage, type: "transactionsProjects", record })
                }
              >
                TRANSACCIONES
              </Button>
            </div>
          </div>
        );
      },
    },
  ];
  const [loading, setLoading] = useState(false);

  const [filters, setFilters] = useState("false");

  const fetchData = async (params = {}) => {
    try {
      const id = params.id || new Storage("id").getItem();
      // console.log("id:", id);

      await getOwnersByProject(id);
      const filtros = params.filter ? JSON.stringify(params.filter) : [""];
      setFilters(filtros);
      if (Object.entries(params).length > 0) message.success("Filtrado");
      setLoading(false);
      message.success("Filtrado");
    } catch (error) {
      message.error("error");
    }
  };

  useEffect(() => {
    getOwnersByProject();
    fetchData({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRequest = (newPagination = {}, filters = {}, sorter = {}) => {
    const { current: page = 1, pageSize: limit = 5 } = newPagination;
    const { field: sortField, order: sortOrder } = sorter;
    const clean = Formatter.cleanParamsFromTableToAPI({
      ...filters,
      limit,
      sortField,
      sortOrder,
      page: page - 1,
      dates: [],
    });
    setValues({
      ...values,
      limit,
      page: page - 1,
    });
    fetchData(clean);
  };

  return (
    <div className="grid-owners">
      <div>
        <Card className="card-owners">
          <Row className="owners-title">
            <Col className="my-projects" span={24}>
              <Text className="text-my-projects">Mis Proyectos</Text>
              {/* <NewOwners /> */}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InterfaceTable
                // rowKey="id"
                data={ownersByProject}
                columns={columns}
                onChange={handleRequest}
                pagination={{
                  pageSize: values.limit,
                  total: ownersByCount,
                  onChange: (current) => {
                    //getOwnersByProject({ page: current - 1, values.limit });
                  },
                }}
              />
            </Col>
          </Row>
        </Card>
      </div>
    </div>
  );
};

export default Owners;
