import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { message, Tooltip, Form, Modal as ModalAntd } from "antd";
import Modal from "../../Modal/Modal";
import InterfaceTable from "../../Table/InterfaceTable";
import { EditOutlined, ApiOutlined, DeleteOutlined } from "@ant-design/icons";
import SuppliersDetails from "./SuppliersDetail";
import SuppliersAssociation from "./SuppliersAssociation";
import {
  getSuppliersSelector,
} from "../Infrastructure/Suppliers.reducer";
import { getSuppliers, deleteSupplier } from "../Application/Suppliers.business";
import Storage from "../../Data/Domain/Storage";

const SuppliersBoard = () => {
  const suppliers = useSelector(getSuppliersSelector);
  const idSupplier = new Storage("id").getItem();
  const [form] = Form.useForm();
  const flex = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  useEffect(() => {
    getSuppliers(idSupplier)

  }, []);

  const handleDelete = async (id) => {
    try {
      console.log(id);
      ModalAntd.confirm({
        title: "Está seguro de que desea eliminar un supplier?",
        okText: "Si",
        okType: "danger",
        onOk: () => {
           deleteSupplier(id);
          message.success("Se eliminó el supplier");
        },
      });
    } catch (error) {
      message.error("Se produjo un error, vuelva a intentarlo");
    }
  };



  const columns = [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Acciones",
      dataIndex: "",
      key: "",

      render: (_, selected) => {
        return (
          <>
          <div style={flex}>
          <div>
              <Modal
                children={<SuppliersDetails record={selected} form={form} />}
                icon={
                  <Tooltip title="Editar un supplier">
                    <EditOutlined />
                  </Tooltip>
                }
                style={{ color: "red" }}
                title="Editar un supplier"
                resetFields={"nameSupplier"}
                form={form}
                cancelButtonProps={{ style: { display: "none" } }}
                okText="Cerrar"
              />
          </div>
          <div>
              <Modal
                width="540px"
                children={<SuppliersAssociation record={selected} form={form} />}
                icon={
                  <Tooltip title="Asociar un supplier">
                    <ApiOutlined/>
                  </Tooltip>
                }
                style={{ color: "red" }}
                title="Asociar un supplier"
                form={form}
                cancelButtonProps={{ style: { display: "none" } }}
                okText="Cerrar"
              />
          </div>
          <div>
              <Tooltip title="Eliminar un supplier">
                <DeleteOutlined
                  onClick={() => {
                    handleDelete(selected.id);
                  }}
                  style={{ color: "red" }}
                />
              </Tooltip>
              </div>

            </div>
           </>
        );
      },
    },
  ];
  return <InterfaceTable rowKey="id" data={suppliers} columns={columns} />;
};

export default SuppliersBoard;
