import React from "react";

const ErrorPage = () => {
  return (
    <div>
      <h1>
        No tienes los permisos suficientes para acceder a esta sección del Panel
      </h1>
    </div>
  );
};

export default ErrorPage;
