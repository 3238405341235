import Response from "../../Data/Domain/Response";
import Subcategory from "../../Subcategories/Domain/Subcategory";
import { getSubcategories, getSubcategoryById } from "./Subcategories.service";

export async function getSubcategoriesRepository(params) {
  const data = await getSubcategories(params);
  return new Response(data, Subcategory).getMultiple();
}

export async function getSubcategoryByIdRepository(id) {
  const data = await getSubcategoryById(id);
  return new Response(data, Subcategory).getSingle();
}
