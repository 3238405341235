import API from "../../Data/Domain/API";
import URL from "../../Data/Domain/URL";
import Storage from "../../Data/Domain/Storage";

export async function getSuppliers(params) {
  return await new API(URL.setParams(URL.buildUrl("suppliers"), params)).get();
}
export async function postSuppliers({ name }) {
  return await new API(URL.buildUrl("suppliers")).post({ name });
}
export async function getSuppliersByUser(id) {
  const internal_id = id || new Storage("id").getItem();
  return await new API(URL.buildUrl(`users/${internal_id}/suppliers`)).get();
}

export async function putSuppliers(id, data) {
  const url = URL.setId(URL.buildUrl("suppliers"), id);
  return await new API(url).put(data);
}
export async function deleteSupplier(id) {
  return await new API(URL.setId(URL.buildUrl("suppliers"), id)).delete();
}
// export async function getSubcategoriesBySuppliersAndUser({ id_subcategory }) {
//   const id = new Storage("id").getItem();
//   return await new API(
//     URL.buildUrl(` users/${id}/subcategories/${id_subcategory}/suppliers`)
//   ).get();
// }
export async function getSubcategoriesBySuppliersAndUser(id) {
  const id_user = new Storage("id").getItem();
  return await new API(
    URL.buildUrl(`users/${id_user}/subcategories/${id}/suppliers`)
  ).get();
}
