import {
  getAdminRepository,
  getAdminsRepository,
} from "../Infrastructure/Administrators.repository";

export async function getAdminsLogic(params) {
  return getAdminsRepository(params);
}

export async function getAdminLogic(id) {
  return getAdminRepository(id);
}
