import React from "react";
import { Select, message } from "antd";
import { updateStatusDocument } from "../DocumentUpload/Infrastructure/DocumentUpload.service";

const { Option } = Select;

const DocumentStatus = (props) => {
  const handleChange = async (value) => {
    try {
      alert(value);
      await updateStatusDocument(props.id, { status: value });
      message.success("Se actualizó el estado correctamente");
    } catch (error) {
      message.error(" Se produjo un error, vuelve a intentarlo");
    }
  };

  return (
    <>
      <Select
        defaultValue={props.default}
        style={{
          width: 120,
        }}
        onChange={handleChange}
      >
        <Option value={"pending"}>{"Pendiente"}</Option>
        <Option value={"verified"}>{"Verificado"}</Option>
        <Option value={"rejected"}>{"Rechazado"}</Option>
      </Select>
    </>
  );
};

export default DocumentStatus;
