import React, { useState, useEffect } from "react";
import { Card, Col, Row, Typography } from "antd";
import NewFile from "./NewFile";
import FileBoard from "./FileBoard";
import Storage from "../../Data/Domain/Storage";
import {
  getByUser,
  getAll,
} from "../../DocumentUpload/Application/DocumentUpload.business";
import User from "../../Users/Domain/User";

const { Text } = Typography;

const Files = () => {
  const role = new Storage("rol").getItem();
  const user_id = new Storage("id").getItem();
  const [imageCounter, setImageCounter] = useState(0);
  const [description, setDescription] = useState(true);
  const isDescriptionEmpty = !description || description.length === 0;

  const type = "carbon_management_file";
  const handleImageUpdate = () => {
    setImageCounter(imageCounter + 1);
    setDescription("");
  };
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };
  useEffect(() => {
    setDescription(isDescriptionEmpty);
  }, []);
  return (
    <div className="grid-file">
      <div>
        <Card className="card-file">
          <Row>
            <Col className="content-files" span={24}>
              <Text className="text-files">Archivos</Text>
              <NewFile
                userId={user_id}
                handleImageUpdate={handleImageUpdate}
                description={description}
                handleDescriptionChange={handleDescriptionChange}
                getByUser={getByUser}
                User={User}
                role={role}
                isDescriptionEmpty={isDescriptionEmpty}
              />
            </Col>
          </Row>
          <FileBoard
            userId={user_id}
            imageCounter={imageCounter}
            getByUser={getByUser}
            role={role}
            User={User}
            getAll={getAll}
            type={type}
          />
        </Card>
      </div>
    </div>
  );
};

export default Files;
