import {
  saveListAdmins,
  saveSelectedAdmin,
} from "../Infrastructure/Administrators.store";
import { getAdminLogic, getAdminsLogic } from "./Administrators.logic";

export async function getAdmins(params) {
  const admins = await getAdminsLogic(params);
  saveListAdmins(admins);
}

export async function getAdmin(id) {
  const admin = await getAdminLogic(id);
  saveSelectedAdmin(admin);
}
