import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Title } from "../../Utils/UI/Text";
import { Row, Form, Input, Button, message } from "antd";
import { getStatsSelector } from "../Infrastructure/Dashboard.reducer";
import { getStats, updateStats } from "../Application/Dashboard.bussines";

export default function DashboardBoard() {
  const [form] = Form.useForm();
  const stats = useSelector(getStatsSelector);
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    updateStats(values)
      .then(() => {
        setLoading(false);
        message.success("Datos actualizados con éxito");
      })
      .catch(() => {
        setLoading(false);
        message.error("Error al actualizar datos");
      });
  };

  useEffect(() => {
    getStats();
  }, []);

  useEffect(() => {
    form.setFieldsValue(stats);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stats]);
  const title = {
    marginBottom: 20,
  };
  return (
    <main>
      <Row style={title}>
        <Title>DashBoard</Title>
      </Row>

      <Form
        labelAlign="left"
        form={form}
        onFinish={onFinish}
        name="basic"
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 12 }}
      >
        <Form.Item label="Toneladas Compensadas" name="co2_offset">
          <Input />
        </Form.Item>
        <Form.Item label="Numero de paises" name="countries">
          <Input />
        </Form.Item>
        <Form.Item label="Precio Actual" name="egrn_price">
          <Input disabled={true} />
        </Form.Item>
        <Form.Item label="Lumens disponibles" name="lumens">
          <Input disabled={true} />
        </Form.Item>
        <Form.Item
          label="Comision en Compra de Moneda (%)"
          name="commission_buy_bonds"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Comision en Compra de Dólares (%)"
          name="commission_buy_usd"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Comision en Ofertas de Venta (%)"
          name="commission_sell_offer"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Comision en Ofertas de Compra (%)"
          name="commission_buy_offer"
        >
          <Input />
        </Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Guardar
        </Button>
      </Form>
    </main>
  );
}
