import React from "react";
import TagsSubcategoriesLogic from "./TagsSubcategoriesLogic";
import { PlusOutlined, FileTwoTone } from "@ant-design/icons";
import {
  Input,
  Tag,
  Tooltip,
  Button,
  Space,
  Typography,
  Layout,
  Row,
  Col,
} from "antd";
import DocumentUpload from "../../DocumentUpload/UI/DocumentUpload";
const { Text } = Typography;
const { Content } = Layout;

const TagsSubcategories = () => {
  const {
    tags,
    editInputIndex,
    editInputRef,
    tagInputStyle,
    editInputValue,
    setEditInputIndex,
    setEditInputValue,
    inputVisible,
    inputRef,
    inputValue,
    tagPlusStyle,
    handleInputChange,
    handleInputConfirm,
    showInput,
    handleEditInputChange,
    handleEditInputConfirm,
    handleClose,
    value,
    setName,
    setData,
    selectedSubcategory,
    setFactor,
    factorValue,
    setUnit,
    setId,
    unitValue,
    idValue,
  } = TagsSubcategoriesLogic();

  return selectedSubcategory ? (
    <>
      <Content
        className="site-layout-content"
        style={{
          background: "#ffffff",
          marginTop: 12,
          padding: 17,
        }}
      >
        <Row gutter={[15, 15]}>
          <Col xs={24} sm={24} md={6} lg={6} xl={4} span={4}>
            <Space direction="horizontal">
              <Text strong>Nombre </Text>
              <Input placeholder="" value={value} onChange={setName} />
            </Space>
          </Col>

          <Col xs={24} sm={24} md={6} lg={6} xl={4} span={4}>
            <Space direction="horizontal">
              <Text strong>Factor </Text>
              <Input placeholder="" value={factorValue} onChange={setFactor} />
            </Space>
          </Col>

          <Col xs={24} sm={24} md={6} lg={6} xl={4} span={4}>
            <Space direction="horizontal">
              <Text strong>Unit </Text>
              <Input placeholder="" value={unitValue} onChange={setUnit} />
            </Space>
          </Col>

          <Col xs={24} sm={24} md={6} lg={6} xl={4} span={4}>
            <Space direction="horizontal">
              <Text strong>ID </Text>
              <Input placeholder="" value={idValue} onChange={setId} />
            </Space>
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={4} span={4}>
            <Space direction="horizontal">
              <DocumentUpload
                type={"subcategory_info"}
                id={selectedSubcategory.id}
                buttonText="Seleccionar Archivo "
              />
              {selectedSubcategory.subcategory_info && (
                <a
                  href={selectedSubcategory.subcategory_info}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FileTwoTone style={{ fontSize: "150%" }} />
                </a>
              )}
            </Space>
          </Col>

          <Col xs={24} sm={24} md={6} lg={6} xl={4} span={4}>
            <Space direction="vertical">
              <Button
                style={{
                  width: 80,
                }}
                onClick={setData}
                type="primary"
              >
                Enviar
              </Button>
            </Space>
          </Col>
        </Row>

        <Content
          className="site-layout-content"
          style={{
            border: "solid 1px #d9d9d9",
            borderRadius: "15px",
            marginTop: 12,
            padding: 17,
          }}
        >
          {tags.map((tag, index) => {
            if (editInputIndex === index) {
              return (
                <Input
                  ref={editInputRef}
                  key={tag}
                  size="small"
                  style={tagInputStyle}
                  value={editInputValue}
                  onChange={handleEditInputChange}
                  onBlur={handleEditInputConfirm}
                  onPressEnter={handleEditInputConfirm}
                />
              );
            }
            const isLongTag = tag.length > 20;
            const tagElem = (
              <Tag
                key={tag}
                closable={index !== 0}
                style={{
                  userSelect: "none",
                }}
                onClose={() => handleClose(tag)}
              >
                <span
                  onDoubleClick={(e) => {
                    if (index !== 0) {
                      setEditInputIndex(index);
                      setEditInputValue(tag);
                      e.preventDefault();
                    }
                  }}
                >
                  {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                </span>
              </Tag>
            );
            return isLongTag ? (
              <Tooltip title={tag} key={tag}>
                {tagElem}
              </Tooltip>
            ) : (
              tagElem
            );
          })}
          {inputVisible ? (
            <Input
              ref={inputRef}
              type="text"
              size="small"
              style={tagInputStyle}
              value={inputValue}
              onChange={handleInputChange}
              onBlur={handleInputConfirm}
              onPressEnter={handleInputConfirm}
            />
          ) : (
            <Tag style={tagPlusStyle} onClick={showInput}>
              <PlusOutlined /> Nueva etiqueta
            </Tag>
          )}
        </Content>
      </Content>
    </>
  ) : (
    <></>
  );
};

export default TagsSubcategories;
