import Params from "./Params";

class URL {
  static get URL_SERVER() {
    return process.env.REACT_APP_URL_SERVER;
  }

  static get STELLAR_URL() {
    return process.env.REACT_APP_HORIZON_STELLAR_URL;
  }

  static get EGREEN_NEW_PAGE() {
    return process.env.REACT_APP_DOMAIN_EGREEN_NEW_PAGE;
  }

  static buildUrl(path) {
    return `${URL.URL_SERVER}/${path}`;
  }

  static setParams(url, params = {}) {
    return Params.buildUrlBasedOnFilters(url, params);
  }

  static setQuery(url, { key, value }) {
    return url.includes("?")
      ? `${url}&${key}=${value}`
      : `${url}?${key}=${value}`;
  }

  static setId(url, id) {
    return `${url}/${id}`;
  }
}

export default URL;
