import store from "../../Redux/store";
import {
  setDocuments,
  countDocuments,
  selectDocument,
  deleteDocument,
} from "./DocumentUpload.reducer";

export function saveListDocuments(records) {
  store.dispatch(setDocuments(records));
}
export function saveCountDocuments(count) {
  store.dispatch(countDocuments(count));
}

export function saveSelectedDocument(document) {
  store.dispatch(selectDocument(document));
}

export function deleteSelectedDocument(document) {
  store.dispatch(deleteDocument(document));
}
