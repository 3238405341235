import store from "../../Redux/store";
import {
  setRepositoryProjects,
  countRepositoryProjects,
  deleteRepositoryProject
} from "./RepositoryProjects.reducer";
import { setRepositoryProjectsByUser } from "../../DataUser/Infrastructure/DataUser.reducer";

export function saveListRepositoryProjects(records) {
  store.dispatch(setRepositoryProjects(records));
}
export function saveCountRepositoryProjects(count) {
  store.dispatch(countRepositoryProjects(count));
}
export function deleteSelectedRepositoryProject(id) {
  store.dispatch(deleteRepositoryProject(id));
}
export function saveListRepositoryProjectsByUser(records) {
  store.dispatch(setRepositoryProjectsByUser(records));
}

