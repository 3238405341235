import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, message, Typography, Row, Col } from "antd";
import { UserOutlined, WarningOutlined } from "@ant-design/icons";
import "antd/dist/antd.min.css";
import "./Login.css";
import {
  login,
  role,
  resetPassword,
  finalizeResetPassword,
} from "../Application/Auth.logic";
import CustomError from "../../Utils/Domain/CustomError";
import Button from "../../Layout/DesignSystem/Button/Button";
import Storage from "../../Data/Domain/Storage";
import User from "../../Users/Domain/User";

const { Title, Link } = Typography;

const Login = ({ logout }) => {
  const [error, setError] = useState("");
  const [currentForm, setCurrentForm] = useState("login");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (logout) localStorage.clear();
  }, [logout]);

  async function handleSubmit(values) {
    const { client_id, password } = values;
    setEmail(client_id);
    try {
      await login({ client_id, password });
      const role_object = await role();
      const first_role = User.getFirstRole(role_object.role);
      new Storage("rol").setItem(first_role);
      message.success("Bienvenido");
      navigate("/");
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    } catch (error) {
      const messageError =
        error instanceof CustomError ? error.message : "General Error";
      setError(messageError);
    }
  }

  const handleForgotPasswordSubmit = async ({ client_id }) => {
    try {
      await resetPassword({ email: client_id });
      message.success(
        "Solicitud de restablecimiento de contraseña enviada con éxito."
      );
      setCurrentForm("newPassword");
    } catch (error) {
      setError(error instanceof CustomError ? error.message : "General Error");
    }
  };

  const handleNewPasswordSubmit = async ({ client_id, code, password }) => {
    try {
      await finalizeResetPassword({
        email: client_id,
        code,
        new_password: password,
      });
      message.success("Contraseña restablecida correctamente.");
      setCurrentForm("login");
    } catch (error) {
      setError(error instanceof CustomError ? error.message : "General Error");
    }
  };

  const rulesEmail = [
    {
      validator: async (_, value) => {
        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i;
        if (!emailRegex.test(value) && value.length < 6) {
          throw new Error("Ingrese un usuario o email válido");
        }
      },
    },
  ];

  const formItems = {
    login: [
      {
        label: "user/email",
        name: "client_id",
        rules: rulesEmail,
      },
      {
        label: "password",
        name: "password",
        rules: [{ required: true, message: "Por favor ingrese su password" }],
      },
    ],
    forgotPassword: [
      {
        label: "user/email",
        name: "client_id",
        initialValue: email,
        rules: rulesEmail,
      },
    ],
    newPassword: [
      {
        label: "Email",
        name: "client_id",
        initialValue: email,
        rules: rulesEmail,
      },
      {
        label: "Código",
        name: "code",
        rules: [
          { required: true, message: "Por favor ingrese el código recibido" },
        ],
      },
      {
        label: "Contraseña",
        name: "password",
        rules: [
          { required: true, message: "Por favor ingrese su nueva contraseña" },
        ],
      },
      {
        label: "Confirmar Contraseña",
        name: "password_confirm",
        rules: [
          {
            required: true,
            message: "Por favor confirme su nueva contraseña",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("Las contraseñas no coinciden"));
            },
          }),
        ],
      },
    ],
  };

  const handleFinish = (values) => {
    if (currentForm === "login") handleSubmit(values);
    if (currentForm === "forgotPassword") handleForgotPasswordSubmit(values);
    if (currentForm === "newPassword") handleNewPasswordSubmit(values);
  };

  const renderForm = () => (
    <Form
      name={currentForm}
      className={"form"}
      onFinish={handleFinish}
      initialValues={{ client_id: email }}
      autoComplete="off"
    >
      {formItems[currentForm].map((item) => (
        <Form.Item
          key={item.name}
          label={item.label}
          name={item.name}
          rules={item.rules}
          initialValue={item.initialValue}
        >
          {item.name === "password" || item.name === "password_confirm" ? (
            <Input.Password />
          ) : (
            <Input />
          )}
        </Form.Item>
      ))}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: currentForm === "login" ? "1fr" : "1fr 1fr",
          gap: "10px",
        }}
      >
        {currentForm === "login" ? (
          <Button htmlType="submit" text="Login" />
        ) : (
          <Button
            htmlType="submit"
            text={
              currentForm === "forgotPassword"
                ? "Enviar"
                : "Restablecer Contraseña"
            }
          />
        )}

        {currentForm !== "login" && (
          <Button text="Regresar" onClick={() => setCurrentForm("login")} />
        )}
      </div>
    </Form>
  );

  return (
    <div className={"main_login"}>
      <Row>
        <Col className={"form_login"}>
          <div className={"root_login"}>
            <UserOutlined
              style={{ fontSize: 80, color: "#08c", display: "block" }}
            />
            {renderForm()}
            {error && (
              <Title
                level={5}
                type="danger"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "1px",
                }}
              >
                <WarningOutlined /> {error}
              </Title>
            )}
            {currentForm === "login" && (
              <div className={"restore_password"}>
                <Link
                  onClick={() => setCurrentForm("forgotPassword")}
                  style={{ display: "block" }}
                >
                  Olvidé mi contraseña
                </Link>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
