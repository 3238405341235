import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "fees",
  initialState: {
    list: [],
  },
  reducers: {
    setCommissions: (state, { payload: list }) => {
      return {
        ...state,
        list,
      };
    },
  },
});
export const { setCommissions } = slice.actions;

export default slice.reducer;

export const getCommissionsSelector = (store) => store.fees.list;
