class Admin {
  constructor({
    id,
    _id,
    name,
    email,
    verified,
    enabled,
    publicKey,
    withdrew_usd,
  }) {
    this.id = id || _id;
    this.name = name;
    this.username = name;
    this.email = email;
    this.verified = verified;
    this.enabled = enabled;
    this.publicKey = publicKey;
    this.withdrew_usd = withdrew_usd;
  }

  get verifiedValue() {
    switch (this.verified) {
      case "starter":
        return "No iniciado";
      case "pending":
        return "Pendiente";
      case "rejected":
        return "Rechazado";
      case "succeeded":
        return "Verificado";
      case "data_loaded":
        return "Información inicial cargada";
      default:
        return "No iniciado";
    }
  }

  get enabledValue() {
    return this.enabled ? "Activado" : "Desactivado";
  }

  get usernameForSearcher() {
    return `${this.email} (${this.name})`;
  }

  get userName() {
    return this.name;
  }
}

export default Admin;
