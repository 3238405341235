import API from "../../Data/Domain/API";
import URL from "../../Data/Domain/URL";
import Storage from "../../Data/Domain/Storage";

export async function postCollaborators({
  name,
  lastname,
  email,
  password,
  company,
}) {
  return await new API(URL.buildUrl("collaborators")).post({
    name,
    lastname,
    email,
    password,
    company,
  });
}

export async function getCollaborators(params) {
  return await new API(
    URL.setParams(URL.buildUrl("collaborators"), params)
  ).get();
}

export async function getByCompany() {
  const id = new Storage("id").getItem();
  return await new API(URL.buildUrl(`companies/${id}/collaborators`)).get();
}

export async function deleteCollaborator(id) {
  return await new API(URL.setId(URL.buildUrl("collaborators"), id)).delete();
}

export async function revokeCollaborator(id, {revoked}) {
  let url = URL.setId(URL.buildUrl("collaborators"), id);
  url = URL.setQuery(url, {key: "revoked", value: revoked});
  return await new API(url).delete();
}
