class Permission {
  constructor({
    _id,
    user,
    project,
    permissions = [],
    email,
    username,
    project_name,
  }) {
    this.id = _id;
    this.user = user;
    this.project = project;
    this.email = email;
    this.username = username;
    this.project_name = project_name;
    this.permissions = permissions;
  }
  get namePermission() {
    return `${this.username} / ${this.email}`;
  }
}
export default Permission;
