import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { message } from "antd";
import { getSelectedSubcategorySelector } from "../Infrastructure/Categories.reducer";
import { updateSubcategory } from "../Application/Categories.business";

const TagsSubcategoriesLogic = () => {
  const selectedSubcategory = useSelector(getSelectedSubcategorySelector);
  const [tags, setTags] = useState([]);
  const [value, setValue] = useState("");
  const [factorValue, setFactorValue] = useState("");
  const [unitValue, setUnitValue] = useState("");
  const [idValue, setIdValue] = useState("");

  // Tags antd
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState("");
  const inputRef = useRef(null);
  const editInputRef = useRef(null);

  useEffect(() => {
    setValue(selectedSubcategory ? selectedSubcategory.name : "");
    setFactorValue(selectedSubcategory ? selectedSubcategory.factor : "");
    setUnitValue(selectedSubcategory ? selectedSubcategory.unit : "");
    setTags(selectedSubcategory ? selectedSubcategory.aliases : []);
    setIdValue(selectedSubcategory ? selectedSubcategory.internal_id : "");
  }, [selectedSubcategory]);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [inputValue]);

  const handleClose = (removedTag) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      setTags([...tags, inputValue]);
    }
    setInputVisible(false);
    setInputValue("");
  };

  const handleEditInputChange = (e) => {
    setEditInputValue(e.target.value);
  };

  const handleEditInputConfirm = () => {
    const newTags = [...tags];
    newTags[editInputIndex] = editInputValue;
    setTags(newTags);
    setEditInputIndex(-1);
    setInputValue("");
  };

  const tagInputStyle = {
    width: 78,
    marginRight: 8,
    verticalAlign: "top",
  };

  const tagPlusStyle = {
    background: "fffff",
    borderStyle: "dashed",
  };

  const setName = (e) => {
    const name = e.target.value;
    setValue(name);
  };

  const setFactor = (e) => {
    const factor = e.target.value;
    setFactorValue(factor);
  };

  const setUnit = (e) => {
    const unit = e.target.value;
    setUnitValue(unit);
  };

  const setId = (e) => {
    const id = e.target.value;
    setIdValue(id);
  };

  const setData = () => {
    const data = {
      name: value,
      aliases: tags,
      factor: factorValue,
      unit: unitValue,
      internal_id: idValue,
    };

    updateSubcategory(selectedSubcategory.id, data)
      .then(() => {
        message.success("La subcategoría se actualizó con éxito");
      })
      .catch(() => {
        message.error("Error al actualizar la subcategoría");
      });
  };
  return {
    selectedSubcategory,
    tags,
    editInputIndex,
    editInputRef,
    tagInputStyle,
    editInputValue,
    handleEditInputChange,
    handleEditInputConfirm,
    handleClose,
    setEditInputIndex,
    setEditInputValue,
    inputVisible,
    inputRef,
    inputValue,
    handleInputChange,
    handleInputConfirm,
    showInput,
    tagPlusStyle,
    value,
    setName,
    setData,
    factorValue,
    setFactor,
    unitValue,
    setUnit,
    setId,
    idValue,
  };
};
export default TagsSubcategoriesLogic;
