import Response from "../../Data/Domain/Response";
import { deleteMonth } from "./ManageData.service";
// import Offset from "../../DashboardOffset/Domain/DashboardOffset";
// import {
//     getManageData,
//     patchManageData,
// } from "./ManageData.service";

// export async function getManageDataRepository(id) {
//     const data = await getManageData(id);
//     return new Response(data, Offset).getSingle();
// }

// export async function patchManageDataRepository(id, params) {
//     const data = await patchManageData(id, params);
//     return new Response(data, Offset).onlyStatus();
// }

export async function deleteMonthRepository(id, id_month) {
  const data = await deleteMonth(id, id_month);
  return new Response(data).onlyStatus();
}
