import React, { useState } from "react";
import { Button, Select, Input, message } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import "./Owners.css";
import Modal from "../../Modal/Modal";
import GeneralInformationMaker from "../../Projects/Domain/GeneralInformationMaker";

const NewGeneralInfo = ({ recordId }) => {
  const [values, setValues] = useState({
    title: "",
    data: "",
    type: "",
    id: recordId,
  });

  const handleInputChange = (input) => (e) => {
    setValues({
      ...values,
      [input]: e.target.value,
    });
  };

  const handleSelectorChange = (value) => {
    setValues({
      ...values,
      type: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const informationMaker = new GeneralInformationMaker(values);
      const service = await informationMaker.getService();
      await service(informationMaker.setup());
      message.success("Se agregó una nueva información");
      setValues({
        title: "",
        data: "",
        type: "",
      });
    } catch (error) {
      message.error("Se produjo un error, vuelve a intentarlo");
    }
  };

  return (
    <div>
      <Modal
        children={
          <form onSubmit={handleSubmit} className="horizontal-form-generals">
            <div className="form-group-generals">
              {/* <label htmlFor="titulo" className="form-label-generals">
                Título:
              </label> */}
              <Input
                placeholder="Título"
                id="titulo"
                value={values.title}
                onChange={handleInputChange("title")}
              />
            </div>
            <div className="form-group-generals">
              {/* <label htmlFor="datos" className="form-label-generals">
                Datos:
              </label> */}
              <Input
                placeholder="Datos"
                id="datos"
                value={values.data}
                onChange={handleInputChange("data")}
              />
            </div>
            <div className="form-select-generals">
              {/* <label htmlFor="tipo" className="form-label-generals">
                Tipo:
              </label> */}
              <Select
                showSearch
                placeholder="Tipo"
                optionFilterProp="children"
                onChange={handleSelectorChange}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={[
                  { value: "impact", label: "Impact" },
                  { value: "sdg", label: "SDG" },
                  { value: "key", label: "Key Indicator" },
                  { value: "projectDescription", label: "Project Description" },
                ]}
              />
            </div>
            <div className="form-button-generals">
              <Button
                type="primary"
                htmlType="submit"
                className="button-generals"
              >
                Agregar
              </Button>
            </div>
          </form>
        }
        icon={<PlusCircleOutlined className="icon-style" />}
        className="icon-style"
        title="Agregar nueva información general al proyecto"
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        width="auto"
      />
    </div>
  );
};

export default NewGeneralInfo;
