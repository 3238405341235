import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "projects",
  initialState: {
    list: [],
    count: 0,
    selected: null,
    ipfs: [],
    images: [],
    count_images: 0,
    transactions: [],


  },
  reducers: {
    setProjects: (state, { payload: list }) => {
      return {
        ...state,
        list,
      };
    },
    countProjects: (state, { payload: count }) => {
      return {
        ...state,
        count,
      };
    },
    selectProject: (state, { payload: selected }) => {
      return {
        ...state,
        selected,
      };
    },
    deleteContact: (state, { payload: id }) => {
      return {
        ...state,
        list: [...state.list.filter((elem) => elem.id !== id)],
      };
    },
    setIpfs: (state, { payload: ipfs }) => {
      return {
        ...state,
        ipfs,
      };
    },
    setImages: (state, { payload: images }) => {
      return {
        ...state,
        images,
      };
    },
    setTransactions: (state, { payload: transactions }) => {
      return {
        ...state,
        transactions,
      };
    },
    countImages: (
      state,
      { payload: count_images }
    ) => {
      return {
        ...state,
        count_images,
      };
    },
  },
});

export const { deleteContact, setProjects, countProjects, selectProject, setIpfs,setImages,countImages,setTransactions} = slice.actions;

export default slice.reducer;

export const getProjectsSelector = (store) => store.projects.list;
export const getProjectsCountSelector = (store) => store.projects.count;
export const getSelectedProjectSelector = (store) => store.projects.selected;
export const getIpfsSelector = (store) => store.projects.ipfs;
export const getImagesSelector = (store) => store.projects.images;
export const getTransactionsSelector = (store) => store.projects.transactions;
export const getcountImagesSelector = (store) =>
  store.projects.count_images;
