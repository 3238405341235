import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import InterfaceTable from "../../Table/InterfaceTable";
import { getRepositoryProjectByUser } from "../../RepositoryProjects/Application/RepositoryProjects.business";
import { getRepositoryProjectsByUserSelector } from "../../DataUser/Infrastructure/DataUser.reducer";
import NewRepositoryProjects from "./NewRepositoryProject";

const columnsEmissionPerRepositoryProjects = [
  {
    title: "Proyectos",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Emisiones (tons)",
    dataIndex: "quantity",
    key: "quantity",
  },
];

const RepositoryProjectsByUser = ({ userId }) => {
  const repositoryProjectByUser = useSelector(
    getRepositoryProjectsByUserSelector
  );

  useEffect(() => {
    if (userId) {
      getRepositoryProjectByUser(userId);
    }
  }, [userId]);

  return (
    <div>
      <NewRepositoryProjects userId={userId} />

      <InterfaceTable
        rowKey={(record) => record._id}
        data={repositoryProjectByUser}
        columns={columnsEmissionPerRepositoryProjects}
        table={"table-manage-data"}
      />
    </div>
  );
};

export default RepositoryProjectsByUser;
