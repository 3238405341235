import { useState } from "react";
import { useDispatch } from "react-redux";
import { Form, Input, Row, message } from "antd";
import Button from "../../Layout/DesignSystem/Button/Button";
import { updateSupplier } from "../Application/Suppliers.business";
import { getSuppliers  } from "../Application/Suppliers.business";
import TextArea from "antd/lib/input/TextArea";

export default function SuppliersDetail({form, record}) {
  const [loading, setLoading] = useState(false);
  const [nameSupplier, setNameSupplier] = useState(record.name);
  console.log(nameSupplier)
  /* const [form] = Form.useForm(); */

  const dispatch = useDispatch();
  
  const onHandleChange = (input) => (e) => {
    setNameSupplier(e.target.value);
  };

  const handleUpdate = () => {
    setLoading(true);
    const data = {
      name: nameSupplier,
    };

    updateSupplier(record.id, data)
      .then(() => {
        message.success("Proveedor actualizado con éxito");
        setLoading(false);
        form.resetFields([nameSupplier]);
      })
      .catch(() => {
        message.error("Error al actualizar el Proveedor");
        setLoading(false);
        form.resetFields([nameSupplier]);
      });
    dispatch(getSuppliers());
  };
  const onReset = () => {
    form.resetFields();
  };
  return (
    <Row type="flex" justify="center" align="middle">
      <Form
        name="basic"
        labelCol={{
          span: 10,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          nameSupplier: nameSupplier,
          remember: false,
        }}
        autoComplete="off"
        form={form}
        onReset={onReset}
      >
        {" "}
        {record ? (
          <>
          <Form.Item label={"Nombre"}>
            <Form.Item>
              <TextArea
                name={"nameSupplier"}
                value={nameSupplier}
                rows={2}
                maxLength={12}
                onChange={onHandleChange(nameSupplier)}
                style={{ width: "100%" }}
              />
            </Form.Item>

            <Form.Item>
              {loading ? (
                <Button loading={loading} text={"Cargando"} />
              ) : (
                <Button onClick={handleUpdate} text={"Actualizar"} />
              )}
            </Form.Item>
          </Form.Item>
        </>
        ) : (
          ""
        )}
      </Form>
    </Row>
  );
}
