import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Form, Input, Row, message, Col } from "antd";
import { updateProject, getProjects } from "../Application/Projects.business";
import Button from "../../Layout/DesignSystem/Button/Button";

const { TextArea } = Input;

export default function ProjectEdit({ form, record }) {
  const [values, setValues] = useState(record);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    getProjects();
  }, [values]);


  const onHandleChange = () => (e) => {
    setValues((oldValues) => ({
      ...oldValues,
      [e.target.name]: e.target.value,
    }));

  };

  const handleUpdate = async () => {
    setLoading(true);
    const data = {
      project_name: values.project_name,
      is_private: values.is_private,
      country: values.country,
      co2Goal: values.co2Goal,
      ton_price: values.ton_price,
      //valores privados
      add_on: values.add_on,
      technology: values.technology,
      vintage: values.vintage,
      url: values.url,
      url_file: values.url_file,
      //valores publicos
      area: values.area,
      biomass: values.biomass,
      co2_annual_estimated: values.co2_annual_estimated,
      contract_start_date: values.contract_start_date,
      contract_end_date: values.contract_end_date,
      description: values.description,
      green_project_type: values.green_project_type,
      region: values.region,
      volume: values.volume,
      ton_co2_issued: values.ton_co2_issued,
      province: values.province,
    };

    updateProject(record.id, data)
      .then(() => {
        message.success("proyecto actualizado con éxito");
        setLoading(false);
        form.resetFields([]);
      })
      .catch((error) => {
        message.error("Error al actualizar proyecto");
        setLoading(false);
        form.resetFields([]);
      });
    dispatch(getProjects());
  };

  return (
    <Row type="flex" justify="center" align="middle">
      <Form
        name="basic"
        labelCol={{
          span: 10,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
        autoComplete="off"
        form={form}
      >
        {" "}
        {record ? (
          <>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item label="Nombre">
                  <TextArea
                    name={"project_name"}
                    value={values?.project_name}
                    onChange={onHandleChange()}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Tipo">
                  <Input
                    name={"is_private"}
                    value={values?.is_private}
                    onChange={onHandleChange()}
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item label="Ubicacion">
                  <Input
                    name={"country"}
                    value={values?.country}
                    onChange={onHandleChange()}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="CO2">
                  <Input
                    name={"co2Goal"}
                    value={values?.co2Goal}
                    onChange={onHandleChange()}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Precio">
                  <Input
                    name={"ton_price"}
                    value={values?.ton_price}
                    onChange={onHandleChange()}
                  />
                </Form.Item>
              </Col>
            </Row>
            {values.is_private && (
              <>
                <Row gutter={8}>
                  <Col span={12}>
                    <Form.Item label="Add On">
                      <Input
                        name={"add_on"}
                        value={values?.add_on}
                        onChange={onHandleChange()}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Tecnologia">
                      <Input
                        name={"technology"}
                        value={values?.technology}
                        onChange={onHandleChange()} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col span={12}>
                    <Form.Item label="Vintage">
                      <Input
                        name={"vintage"}
                        value={values?.vintage}
                        onChange={onHandleChange()}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="URL">
                      <Input
                        name={"URL"}
                        value={values?.url}
                        onChange={onHandleChange()}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Form.Item label="URL">
                    <Input
                      name={"URL"}
                      value={values?.url}
                      onChange={onHandleChange()}
                    />
                  </Form.Item>
                </Row>
              </>
            )}
            {!values.is_private && (
              <>
                <Row gutter={8}>
                  <Col span={12}>
                    <Form.Item label="Area">
                      <Input
                        name={"area"}
                        value={values?.area}
                        onChange={onHandleChange()}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Biomasa">
                      <Input
                        name={"biomass"}
                        value={values?.biomass}
                        onChange={onHandleChange()}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col span={12}>
                    <Form.Item label="CO2 estimado anual">
                      <Input
                        name={"co2_annual_estimated"}
                        value={values?.co2_annual_estimated}
                        onChange={onHandleChange()}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Fecha incial del contrato">
                      <Input
                        name={"contract_start_date"}
                        value={values?.contract_start_date}
                        onChange={onHandleChange()}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col span={12}>
                    <Form.Item label="Fecha de terminacion del contrato">
                      <Input
                        name={"contract_end_date"}
                        value={values?.contract_end_date}
                        onChange={onHandleChange()}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="descripción">
                      <Input
                        name={"description"}
                        value={values?.description}
                        onChange={onHandleChange()}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col span={12}>
                    <Form.Item label="tipo de proyecto">
                      <Input
                        name={"green_project_type"}
                        value={values?.green_project_type}
                        onChange={onHandleChange()}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Region">
                      <Input
                        name={"region"}
                        value={values?.region}
                        onChange={onHandleChange()}
                      />
                    </Form.Item>

                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col span={12}>
                    <Form.Item label="Volumen">
                      <Input
                        name={"volume"}
                        value={values?.volume}
                        onChange={onHandleChange()}
                      />
                    </Form.Item>

                  </Col>
                  <Col span={12}>
                    <Form.Item label="CO2 emitido">
                      <Input
                        name={"ton_co2_issued"}
                        value={values?.ton_co2_issued}
                        onChange={onHandleChange()}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col span={12}>
                    <Form.Item label="Provincia">
                      <Input
                        name={"province"}
                        value={values?.province}
                        onChange={onHandleChange()}
                      />
                    </Form.Item>

                  </Col>
                </Row>
              </>

            )}
            <Row >
              <Col span={12}>
                <Form.Item>
                  {loading ? (
                    <Button loading={loading} text={"Cargando"} />
                  ) : (
                    <Button onClick={handleUpdate} text={"Actualizar"} />
                  )}
                </Form.Item>
              </Col>
            </Row>

          </>
        ) : (
          ""
        )}
      </Form>
    </Row>
  );
}