import { useEffect } from "react";
import { useSelector } from "react-redux";
import InterfaceTable from "../../Table/InterfaceTable";
import { getRepositoryProjectsSelector } from "../Infrastructure/RepositoryProjects.reducer";
import {
  getRepositoryProjects,
  deleteRepositoryProject,
} from "../Application/RepositoryProjects.business";
import Modal from "../../Modal/Modal";
import { Form, Tooltip, message, Modal as ModalAntd } from "antd";
import { EditOutlined, ApiOutlined, DeleteOutlined } from "@ant-design/icons";
import RepositoryProjectDetail from "./RepositoryProjectDetail";
import RepositoryProjectAssociation from "./RepositoryProjectAssociation";

const RepositoryProjectBoard = () => {
  const repositoryProjects = useSelector(getRepositoryProjectsSelector);

  useEffect(() => {
    getRepositoryProjects();
  }, []);

  const handleDelete = async (id) => {
    try {
      console.log(id);
      ModalAntd.confirm({
        title: "Está seguro de que desea eliminar un proyecto?",
        okText: "Si",
        okType: "danger",
        onOk: () => {
          deleteRepositoryProject(id);
          message.success("Se eliminó el proyecto");
        },
      });
    } catch (error) {
      message.error("Se produjo un error, vuelva a intentarlo");
    }
  };

  const [form] = Form.useForm();
  const flex = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const columns = [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Acciones",
      dataIndex: "",
      key: "",

      render: (record) => {
        return (
          <div style={flex}>
            <div>
              <Modal
                children={
                  <RepositoryProjectDetail record={record} form={form} />
                }
                icon={
                  <Tooltip title="Editar Proyecto">
                    <EditOutlined />
                  </Tooltip>
                }
                style={{ color: "red" }}
                title="Editar un Proyecto"
                resetFields={""}
                form={form}
                cancelButtonProps={{ style: { display: "none" } }}
                okText="Cerrar"
              />
            </div>
            <div>
                <Modal
                  width="540px"
                  children={<RepositoryProjectAssociation record={record} form={form} />}
                  icon={
                    <Tooltip title="Asociar un proyecto">
                      <ApiOutlined/>
                    </Tooltip>
                  }
                  style={{ color: "red" }}
                  title="Asociar un proyecto"
                  form={form}
                  cancelButtonProps={{ style: { display: "none" } }}
                  okText="Cerrar"
                />
            </div>
            <div>
              <Tooltip title="Eliminar un Proyecto">
                <DeleteOutlined
                  onClick={() => {
                    // console.log({ record });
                    handleDelete(record.id);
                  }}
                  style={{ color: "red" }}
                />
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <InterfaceTable rowKey="id" data={repositoryProjects} columns={columns} />
  );
};

export default RepositoryProjectBoard;
