import React, { useEffect, useState } from "react";
import { Input, Button, Form, message } from "antd";
import { validateToken } from "../Infrastructure/Token.service";

const InputToken = (props) => {
  const [code, setCode] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(props.disabled);

  const changeInputCode = (e) => {
    setCode(e.target.value);
  };

  useEffect(() => {
    setButtonDisabled(code.length <= 0);
  }, [code]);

  const submit = async (e) => {
    try {
      e.preventDefault();
      await validateToken({ code });
      props.setDisabledForm(false);
      message.success("Validado");
    } catch (error) {
      message.error("No validado");
    }
  };
  return (
    <React.Fragment>
      <Form.Item>
        <Input
          placeholder={props.placeholder}
          prefix={props.prefix}
          onChange={changeInputCode}
        />
      </Form.Item>
      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button
          type={props.type}
          htmlType={props.htmlType}
          disabled={buttonDisabled}
          onClick={submit}
        >
          {props.text}
        </Button>
      </Form.Item>
    </React.Fragment>
  );
};

export default InputToken;
