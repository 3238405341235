import {
  getOffersRepository,
  getOfferRepository,
} from "../Infrastructure/Offers.repository";

export async function getOffersLogic(params) {
  return getOffersRepository(params);
}
export async function getOfferLogic(id) {
  return getOfferRepository(id);
}
