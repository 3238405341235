import React, { useState, useEffect } from "react";
import { DatePicker, Input, message } from "antd";
import moment from "moment";
import Button from "../../Layout/DesignSystem/Button/Button";
import { patchManageData, getOffsets } from "../../DashboardOffset/Application/DashboardOffset.business";

const NewMonthsManageData = (props) => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [emissionMonthValue, setEmissionMonthValue] = useState(null);
    const [buttonDisabled, setButtonDisabled] = useState(true);

    useEffect(() => {
        setButtonDisabled(
            props.userId === undefined ||
            !selectedDate ||
            isNaN(parseFloat(emissionMonthValue)) ||
            emissionMonthValue === null
        );
    }, [props.userId, selectedDate, emissionMonthValue]);

    const onChange = (date) => {
        if (date) {
            date = date.clone().startOf('month');
        }
        setSelectedDate(date);
        console.log(date, "fecha del select date")
    };

    const handleUpdate = async () => {
        let data;
        const tons = parseFloat(emissionMonthValue);
        console.log(tons)
        try {
            data = [
                {
                    op: "add",
                    path: "/emission_per_month",
                    value: {
                        date: selectedDate ? selectedDate.format("YYYY-MM-DD") : null,
                        tons,
                    },
                },
            ];

            console.log({ userId: props.userId });
            await patchManageData(props.userId, data);
            getOffsets(props.userId);
            message.success("Se actualizó con éxito");
        } catch (error) {
            message.error("Se produjo un error, vuelve a intentarlo");
            console.log(props.userId, data);
        }
    };

    return (
        <div className="grid-NewManageData">
            <div className="item-NewManageData">
                <DatePicker
                    className="picker-NewManageDate custom-date-picker"
                    placeholder="Seleccionar mes"
                    onChange={onChange}
                    picker="month"
                    
                    disabledDate={(current) => {
                        return current && current > moment().endOf("month");
                    }}
                />
            </div>
            <div className="item-NewManageData">
                <Input
                    type="number"
                    min={0}
                    className="input-NewManageData custom-input"
                    placeholder="Emisión(es)"
                    onChange={(e) => {
                        setEmissionMonthValue(e.target.value);
                    }}

                />
            </div>
            <div>
                <Button
                    htmlType="submit"
                    text="Agregar"
                    onClick={handleUpdate}
                    block={true}
                    disabled={buttonDisabled}
                />
            </div>
        </div>
    );
};

export default NewMonthsManageData;
