import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Select, Form } from "antd";
import "./ScriptOffsets.css";
import { getUsersSelector } from "../../Users/Infrastructure/Users.reducer";
import { getUsers } from "../../Users/Application/Users.business";

const { Option } = Select;

const SearcherScriptOffsets = (props) => {
  const { setId } = props;
  const records = useSelector(getUsersSelector);
  const [form] = Form.useForm();
  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    if (selectedId) {
      setId(selectedId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId]);

  const onReset = () => {
    form.resetFields();
  };

  function onSearch(val) {
    getUsers({ filter: { q: val } });
  }
  const handleSelectChange = (value) => {
    setSelectedId(value);
  };
  return (
    <Form layout="inline" form={form} onReset={onReset}>
      <div className="grid-searcher-script">
        <div className="item-searcher-script">
          <Select
            className="select-searcher-script custom-select-searcher"
            placeholder="Selecciona un usuario"
            clearIcon
            showSearch
            onSearch={onSearch}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={handleSelectChange}
          >
            {records?.map((record) => (
              <Option value={record.id} key={record.id}>
                {`${record.email} (${record.name} ${record.lastname})`}
              </Option>
            ))}
          </Select>
        </div>
      </div>
    </Form>
  );
};

export default SearcherScriptOffsets;
