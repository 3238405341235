import React, { useEffect } from "react";
import { Form, Select, Input, Button, Row, message } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getProjectsSelector } from "../../Projects/Infrastructure/Projects.reducer";
import {
  getProjects,
  getProject,
} from "../../Projects/Application/Projects.business";
import {
  postTransaction,
  checkPaySession,
} from "../../Transactions/Infrastructure/Transactions.service";
import { postToken } from "../../Token/Infrastructure/Token.service";
import Transaction from "../../Transactions/Domain/Transaction";
import Token from "../../Token/Domain/Token";
import InputToken from "../../Token/UI/Code";
import { MailOutlined } from "@ant-design/icons";

const { Option } = Select;

export default function Operations(props) {
  const { id } = useParams;
  const { user } = props;
  const [selectDisabled, setSelectDisabled] = useState(true);
  const records = useSelector(getProjectsSelector);
  const [form] = Form.useForm();
  const [token, setToken] = useState(false);
  const [disabledForm, setDisabledForm] = useState(true);
  const [values, setValues] = useState({
    id_user: user.id,
    type: "",
    amount_usd: "",
    payment_method: "bank_transfer",
  });
  const [transaction, setTransaction] = useState({});
  const [idTransaction, setIdTransaction] = useState("");

  const [, forceUpdate] = useState({});

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    getProjects();
    if (id) {
      getProject(id);
    }
  }, [id]);

  useEffect(() => {}, [disabledForm]);

  const handleChange = (value) => {
    value === "buy_bonds" ? setSelectDisabled(false) : setSelectDisabled(true);
    setValues({
      ...values,
      type: value,
    });
  };

  const onReset = () => {
    form.resetFields();
  };

  const onSearch = (val) => {
    getProjects({ filter: { q: val } });
  };

  const onHandleChange = (input) => (e) => {
    const amount = e.target.value;
    const transaction = new Transaction({
      ...values,
      amount_usd: amount,
      carbon_credit_invested: amount,
      client_coins: amount,
      co2_emission: amount,
      partner_coins: amount,
      total_coins: amount,
      project_id: records?.map((record) => record.id).toString(),
      project_invested: records
        ?.map((record) => record.project_name)
        .toString(),
    });
    setTransaction(transaction);
  };

  const handleSubmit = async () => {
    try {
      const response = await postTransaction(transaction.forTransaction);
      setIdTransaction(response.data.id);
      await postToken(
        new Token({
          id: response.data.id,
          type: "transaction",
        }).forCreation()
      );
      message.success(
        "Transacción creada exitosamente y toquen enviado al correo del administrador"
      );
      setToken(true);
    } catch (error) {
      message.error("Se produjo un error, vuelve a intentarlo");
    }
  };

  useEffect(() => {}, [idTransaction]);

  const handleSave = async () => {
    try {
      await checkPaySession({
        lan: "esp",
        idPretransaction: idTransaction,
      });
      setToken(false);
      setDisabledForm(true);
      form.resetFields();
      message.success("Sesión de pago exitosa");
    } catch (error) {
      message.error("Se produjo un error, vuelve a intentarlo");
    }
  };

  return (
    <>
      <Row justify="center">
        <Form
          layout="horizontal"
          form={form}
          onReset={onReset}
          className="operations-form"
          initialValues={{
            type: "Seleccione",
            id: "Seleccione un proyecto",
          }}
        >
          <Form.Item
            name="type"
            rules={[
              {
                required: true,
                message: "Seleccionar una opción.",
              },
            ]}
          >
            <Select
              style={{
                width: 300,
              }}
              onChange={handleChange}
            >
              <Option value="load_usd">Cargar USD</Option>
              <Option value="buy_usd">Comprar USD</Option>
              <Option value="buy_bonds">Comprar a Proyecto</Option>
            </Select>
          </Form.Item>
          <Form.Item name={"id"}>
            <Select
              clearIcon
              showSearch
              onSearch={onSearch}
              style={{
                width: 300,
              }}
              placeholder="Selecciona un proyecto"
              disabled={selectDisabled}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {records?.map((record) => (
                <Option value={record.id} key={record.id}>
                  {record.projectNameForSearcher}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Ingresar Monto:"
            name="amount_usd"
            rules={[
              {
                required: true,
                message: "Ingrese un monto.",
              },
            ]}
          >
            <Input
              name={"amount_usd"}
              onChange={onHandleChange("amount_usd")}
            />
          </Form.Item>
          {token && (
            <Form.Item>
              <InputToken
                placeholder="Ingrese tu código"
                text="Validar"
                prefix={<MailOutlined className="site-form-item-icon" />}
                type="primary"
                disabled={true}
                setDisabledForm={setDisabledForm}
              />
            </Form.Item>
          )}
          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
            shouldUpdate
          >
            {!token ? (
              () => (
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={handleSubmit}
                  disabled={
                    values.type === "buy_bonds"
                      ? !form.isFieldsTouched(true) ||
                        !!form
                          .getFieldsError()
                          .filter(({ errors }) => errors.length).length
                      : !form.isFieldTouched("amount_usd") ||
                        !form.isFieldTouched("type") ||
                        !!form
                          .getFieldsError()
                          .filter(({ errors }) => errors.length).length
                  }
                >
                  Solicitar código
                </Button>
              )
            ) : (
              <Button
                type="primary"
                htmlType="submit"
                disabled={disabledForm}
                onClick={handleSave}
              >
                Guardar
              </Button>
            )}
          </Form.Item>
        </Form>
      </Row>
    </>
  );
}
