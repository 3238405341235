import {
  getStatsRepository,
  updateStatsRepository,
} from "../Infrastructure/Dashboard.repository";

export async function getStatsLogic(params) {
  return getStatsRepository(params);
}
export async function updateStatsLogic(params) {
  return updateStatsRepository(params);
}
