class Project {
    constructor({
        _id,
        enabled,
        ipfs= [],
        info= {},
        is_private,
        project_name,
        country,
        region,
        province,
        latitude,
        longitude,
        contract_start_date,
        contract_end_date,
        green_project_type,
        description,
        area,
        biomass,
        ton_co2_issued,
        ton_price,
        images= [],
        co2Goal,
        real_name,
        comercial_name,
        contacts= [],
        hash,
        publicKey,
        secretKey,
        standard_id,
        add_on,
        technology,
        vintage,
        url,
        url_file,
        co2_annual_estimated,
        main_image,
        volume,
        data = {},
        dmrv= [],
        impacts= [],
        sdgs= []
    }) {
        this.id = _id;
        this.enabled = enabled;
        this.ipfs = ipfs;
        this.info = info;
        this.is_private = is_private;
        this.project_name = project_name;
        this.country = country;
        this.region = region;
        this.province = province;
        this.latitude = latitude;
        this.longitude = longitude;
        this.contract_start_date = contract_start_date;
        this.contract_end_date = contract_end_date;
        this.green_project_type = green_project_type;
        this.description = description;
        this.area = area;
        this.biomass = biomass;
        this.ton_co2_issued = ton_co2_issued;
        this.ton_price = ton_price;
        this.images = images;
        this.co2Goal = co2Goal;
        this.real_name = real_name;
        this.comercial_name = comercial_name;
        this.contacts = contacts;
        this.hash = hash;
        this.publicKey = publicKey;
        this.secretKey = secretKey;
        this.standard_id = standard_id;
        this.add_on = add_on;
        this.technology = technology;
        this.vintage = vintage;
        this.url = url;
        this.url_file = url_file;
        this.co2_annual_estimated = co2_annual_estimated;
        this.main_image = main_image;
        this.volume = volume;
        this.data = data;
        this.dmrv = dmrv;
        this.impacts = impacts;
        this.sdgs = sdgs;
    }
}
export default Project;
