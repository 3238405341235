import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "permissions",
  initialState: {
    list: [],
    count: 1,
  },
  reducers: {
    setPermissions: (state, { payload: list }) => {
      return {
        ...state,
        list,
      };
    },
    countPermissions: (state, { payload: count }) => {
      return {
        ...state,
        count,
      };
    },
    selectPermission: (state, { payload: selected }) => {
      return {
        ...state,
        selected,
      };
    },
  },
});

export const { setPermissions, countPermissions, selectPermission } =
  slice.actions;
export default slice.reducer;

export const getPermissionsSelector = (store) => store.permissions.list;
export const getPermissionsCountSelector = (store) => store.permissions.count;
export const getSelectedPermissionSelector = (store) =>
  store.permissions.selected;
