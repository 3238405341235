import React, { useState, useEffect } from "react";
import { Col, Row, Select, Space, message } from "antd";
import { patchProject } from "../Application/Projects.business";
import HtmlEditor from "../../HtmlEditor/HtmlEditor";
import Button from "../../Layout/DesignSystem/Button/Button";
import { getProjects } from "../Application/Projects.business";

const style = {
  padding: "8px 0",
};

const textFont = {
  fontFamily: "MontserratRegular",
};

const { Option } = Select;

const ProjectsTranslations = ({ record }) => {
  const [value, setValue] = useState("");
  const [key, setKey] = useState(null);
  const [language, setLanguage] = useState(null);

  const onChangeLanguage = (languageValue) => {
    setLanguage(languageValue);
    setValue(record && key ? record.info[languageValue][key] : "");
  };

  const onChangeKey = (keyValue) => {
    setKey(keyValue);
    setValue(record && language ? record.info[language][keyValue] : "");
  };

  useEffect(() => {
    setValue(value);
  }, [value]);

  const handleUpdate = async () => {
    try {
      const cleanValue = value.replace(/<[^>]+>/g, "");

      await patchProject(record.id, [
        {
          op: "replace",
          path: `/info/${language}/${key}`,
          value: cleanValue,
        },
      ]);
      getProjects();
      message.success("Se actualizó con éxito");
    } catch (error) {
      message.error(" Se produjo un error, vuelve a intentarlo");
    }
  };

  const keys = [
    { key: "name", value: "Nombre" },
    { key: "description", value: "Descripción" },
    { key: "country", value: "País" },
    { key: "objective", value: "Objetivo" },
    { key: "location", value: "Ubicación" },
    { key: "first_title_sensors", value: "Primer título de sensores" },
    {
      key: "first_description_sensors",
      value: "Primera descripción de sensores",
    },
    { key: "second_title_sensors", value: "Segundo título de sensores" },
    {
      key: "second_description_sensors",
      value: "Segunda descripción de sensores",
    },
    { key: "third_title_sensors", value: "Tercer título de sensores" },
    {
      key: "third_description_sensors",
      value: "Tercer descripción de sensores",
    },
    {
      key: "commitment_to_guidelines",
      value: "Compromiso con las directrices",
    },
  ];
  return (
    <>
      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 32,
        }}
      >
        <Col className="gutter-row" span={12}>
          <div style={style}>
            <Select
              defaultValue="key"
              style={{ width: "100%" }}
              allowClear
              onChange={onChangeKey}
            >
              {keys.map((key) => (
                <Option key={key.key} style={textFont}>
                  {key.value}
                </Option>
              ))}
            </Select>
          </div>
        </Col>
        <Col className="gutter-row" span={12}>
          <div style={style}>
            <Select
              defaultValue="language"
              style={{ width: "100%" }}
              allowClear
              onChange={onChangeLanguage}
            >
              <Option key={"es"} style={textFont}>
                {"Español"}
              </Option>
              <Option key={"en"} style={textFont}>
                {"Inglés"}
              </Option>
              <Option key={"fr"} style={textFont}>
                {"Francés"}
              </Option>
            </Select>
          </div>
        </Col>
      </Row>
      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 32,
        }}
      >
        <Col className="gutter-row" span={24}>
          <Space
            direction="vertical"
            style={{
              width: "100%",
            }}
          >
            <HtmlEditor value={value} onChange={setValue} />
            <Button
              htmlType="submit"
              onClick={handleUpdate}
              text={"Guardar"}
              block={true}
            />
          </Space>
        </Col>
      </Row>
      <Row />
    </>
  );
};

export default ProjectsTranslations;
