import API from "../../Data/Domain/API";
import URL from "../../Data/Domain/URL";
import Storage from "../../Data/Domain/Storage";


export async function getOwnersByProject(id) {
  const internal_id = id || new Storage("id").getItem();
  return await new API(URL.buildUrl(`owners/${internal_id}/projects`)).get();

}

