import {
  getTechnologyRepository,
  deleteTechnologyRepository,
  deleteAllTechnologyRepository
} from "../Infrastucture/FetchData.repository";

export async function getTechnologyLogic(params) {
  return getTechnologyRepository(params);
}

export async function deleteTechnologyLogic(params) {
  return deleteTechnologyRepository(params);
}

export async function deleteAllTechnologyLogic(params) {
  return deleteAllTechnologyRepository(params);
}
