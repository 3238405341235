import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Col, Layout, Row } from "antd";
import { getProjects } from "../Application/Projects.business";
import { getProjectsSelector } from "../Infrastructure/Projects.reducer";
import ProjectsBoard from "./ProjectsBoard";

const { Content } = Layout;

export default function Projects() {
  const projects = useSelector(getProjectsSelector);
  useEffect(() => {
    getProjects();
  }, []);

  return (
    <>
      <Content
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Row
          justify="space-between"
          style={{
            width: "100%",
          }}
        >
          <Col span={24}>
            <Row>
              <Col span={24}>
                <ProjectsBoard data={projects} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
    </>
  );
}
