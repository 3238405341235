import React from "react";
import { Col, Row, Result, Space, Typography } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import "./SectionUnderConstruction.css";

const { Text } = Typography;
const styles = {
  title: {
    fontFamily: "MontserratRegular",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "28px",
    lineHeight: "26px",
    textAlign: "center",
    color: "#FFFFFF",
  },
  description: {
    fontFamily: "MontserratRegular",
    fontStyle: "normal",
    fontWeight: 290,
    fontSize: "20px",
    lineHeight: "26px",
    textAlign: "center",
    color: "#FFFFFF",
  },
  result: {
    paddingTop: 36,
  },
};

const SectionUnderConstruction = () => {
  return (
    <div class={"root"}>
      <Row gutter={10} className="section-container">
        <Col>
          <Result
            style={styles.result}
            icon={
              <ExclamationCircleOutlined
                style={{
                  color: "#FFFFFF",
                  fontSize: "34px",
                  paddingTop: 0,
                  marginTop: 0,
                }}
              />
            }
            extra={
              <Space direction="vertical">
                <Text style={styles.title}>Importante</Text>

                <Space class="space" direction="vertical">
                  <Text style={styles.description} type="secondary">
                    En esta versión no se encuentra activa esta área. Si te
                    interesa esta información contáctate con nosotros.
                  </Text>
                </Space>
              </Space>
            }
          />
        </Col>
      </Row>
    </div>
  );
};

export default SectionUnderConstruction;
