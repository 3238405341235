class Params {
  constructor({ limit = 10, page = 1, filter = {}, field_sort, sort }) {
    this.limit = limit;
    this.page = page;
    this.filter = filter;
    this.field_sort = field_sort;
    this.sort = sort;
  }

  static buildUrlBasedOnFilters(url, params) {
    const {
      page = 0,
      limit = 10,
      filter = {},
      field_sort = "date",
      sort = "descending",
    } = params;
    return `${url}?page=${page}&limit=${limit}&filter=${JSON.stringify(
      filter
    )}&field_sort=${field_sort}&sort=${sort}`;
  }
}
export default Params;
