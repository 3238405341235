import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Formatter from "../../Data/Domain/Formatter";
import { Card, Col, Row, Typography, message } from "antd";
import InterfaceTable from "../../Table/InterfaceTable";
import "./Owners.css";
import { getProjectTransactions } from "../../Projects/Application/Projects.business";
import { getTransactionsSelector } from "../../Projects/Infrastructure/Projects.reducer";

const { Text } = Typography;

const TransactionsProjects = ({ recordId }) => {
  const transactionsFile = useSelector(getTransactionsSelector);
  console.log(JSON.stringify(transactionsFile));
  const [values, setValues] = useState({
    page: 0,
    limit: 5,
  });
  const [loading, setLoading] = useState(false);

  const [filters, setFilters] = useState("false");

  const columns = [
    {
      title: "Monto",
      dataIndex: "payment_amount",
      key: "payment_amount",
    },
    {
      title: "Fee",
      dataIndex: "fee",
      key: "fee",
    },
    {
      title: "Tipo (NFT / SBT)",
      dataIndex: "typeDescription",
      key: "typeDescription",
    },
    {
      title: "Fecha",
      dataIndex: "date",
      key: "date",
    },
  ];
  const fetchData = async (params = {}) => {
    try {
      await getProjectTransactions(recordId);
      const filtros = params.filter ? JSON.stringify(params.filter) : [""];
      setFilters(filtros);
      if (Object.entries(params).length > 0) message.success("Filtrado");
      setLoading(false);
      message.success("Filtrado");
    } catch (error) {
      message.error("error");
    }
  };

  useEffect(() => {
    getProjectTransactions(recordId);
    fetchData({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRequest = (newPagination = {}, filters = {}, sorter = {}) => {
    const { current: page = 1, pageSize: limit = 5 } = newPagination;
    const { field: sortField, order: sortOrder } = sorter;
    const clean = Formatter.cleanParamsFromTableToAPI({
      ...filters,
      limit,
      sortField,
      sortOrder,
      page: page - 1,
      dates: [],
    });
    setValues({
      ...values,
      limit,
      page: page - 1,
    });
    fetchData(clean);
  };

  return (
    <div className="grid-owners">
      <div>
        <Card className="card-owners">
          <Row className="owners-title">
            <Col className="my-projects" span={24}>
              <Text className="text-my-projects">Transacciones </Text>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InterfaceTable
                data={transactionsFile.resources}
                columns={columns}
                onChange={handleRequest}
                pagination={{
                  pageSize: values.limit,
                  onChange: (current) => {},
                }}
              />
            </Col>
          </Row>
        </Card>
      </div>
    </div>
  );
};

export default TransactionsProjects;
