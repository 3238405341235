import {
  getProjectsRepository,
  getProjectRepository,
  getProjectIpfsRepository,
  updateBondsRepository,
  updateProjectRepository,
  patchProjectRepository,
  updateCoordinatesRepository,
  removeContactRepository,
  deleteFileIpfsRepository,
  getProjectImagesRepository,
  deleteFileImageRepository,
  getProjectTransactionsRepository
} from "../Infrastructure/Projects.repository";

export async function getProjectsLogic(params) {
  return getProjectsRepository(params);
}

export async function getProjectLogic(id) {
  return getProjectRepository(id);
}

export async function updateBondsLogic(id, params) {
  return updateBondsRepository(id, params);
}
export async function patchProjectLogic(id, params) {
  return patchProjectRepository(id, params);
}

export async function updateProjectLogic(id, params) {
  return updateProjectRepository(id, params);
}

export async function updateCoordinatesLogic(id, params) {
  return updateCoordinatesRepository(id, params);
}
// export async function removeContactLogic(id) {
//   return removeContactRepository(id);
// }
export async function removeContactLogic(id, params) {
  return removeContactRepository(id, params);
}

export async function getProjectIpfsLogic(id) {
  return getProjectIpfsRepository(id);
}
export async function deleteFileIpfsLogic(id, params) {
  return deleteFileIpfsRepository(id, params);
}
export async function getProjectImagesLogic(id) {
  return getProjectImagesRepository(id);
}
export async function deleteFileImageLogic(id, params) {
  return   deleteFileImageRepository (id, params);
}
export async function getProjectTransactionsLogic(id, state, type) {
  return getProjectTransactionsRepository(id, state, type);
}