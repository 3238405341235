import {useEffect, useState} from "react";
import {auth, role} from "./Auth.logic";
import CustomError from "../../Utils/Domain/CustomError";

const AuthBusiness = ({allowedRoles}) => {
  const [isUserAuth, setIsUserAuth] = useState(false);
  const [isValidRole, setIsValidRole] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const isAuth = async () => {
    let isAuth = await auth();
    return setIsUserAuth(!(isAuth instanceof CustomError));
  };

  const checkValidRole = async () => {
    const role_object = await role();
    const {role: role_array} = role_object;

    return setIsValidRole(
      !(role_object instanceof CustomError) &&
        allowedRoles.some((allowedRole) => role_array.includes(allowedRole))
    );
  };

  useEffect(() => {
    async function fetch() {
      await isAuth();
      await checkValidRole();
      setIsLoading(false);
    }
    fetch()
      .then()
      .catch(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isUserAuth,
    isValidRole,
    isLoading,
  };
};

export default AuthBusiness;
