import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Select } from "antd";
import { getCategoriesSelector } from "../Infrastructure/Categories.reducer";
import {
  getCategories,
  getSubcategoriesData,
} from "../Application/Categories.business";

const { Option } = Select;

const SearcherCategories = () => {
  const categories = useSelector(getCategoriesSelector);

  useEffect(() => {
    getCategories();
  }, []);

  const onSearch = (val) => {
    getCategories({ filter: { q: val } });
  };

  const onChange = async (id) => {
    getSubcategoriesData(id);
  };

  return (
    <>
      <Select
        onSearch={onSearch}
        showSearch
        placeholder="Categorías"
        onChange={onChange}
        style={{ width: "100%" }}
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {categories?.map((category) => (
          <Option value={category.id} key={category.id}>
            {`${category.name} | ${category.scope}`}
          </Option>
        ))}
      </Select>
    </>
  );
};

export default SearcherCategories;
