import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Form, Input, Row, message } from "antd";
import { updateArea } from "../Application/Areas.business";
import Button from "../../Layout/DesignSystem/Button/Button";
import { getAreas } from "../Application/Areas.business";

const { TextArea } = Input;

export default function AreasEdit({ form, record }) {
  const [loading, setLoading] = useState(false);
  const [nameArea, setNameArea] = useState(record.name);

  const dispatch = useDispatch();

  const onHandleChange = (input) => (e) => {
    setNameArea(e.target.value);
  };

  const handleUpdate = () => {
    setLoading(true);
    const data = {
      name: nameArea,
    };

    updateArea(record.id, data)
      .then(() => {
        message.success("Area actualizada con éxito");
        setLoading(false);
        form.resetFields([nameArea]);
      })
      .catch(() => {
        message.error("Error al actualizar el area");
        setLoading(false);
        form.resetFields([nameArea]);
      }).finally(() => {
        dispatch(getAreas())
      }
      );

  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <Row type="flex" justify="center" align="middle">
      <Form
        name="basic"
        labelCol={{
          span: 10,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          nameArea: nameArea,
          remember: false,
        }}
        autoComplete="off"
        form={form}
        onReset={onReset}
      >
        {" "}
        {record ? (
          <>
            <Form.Item label={"Nombre"}>
              <Form.Item>
                <TextArea
                  name={"nameArea"}
                  value={nameArea}
                  rows={2}
                  maxLength={12}
                  onChange={onHandleChange(nameArea)}
                  style={{ width: "100%" }}
                />
              </Form.Item>

              <Form.Item>
                {loading ? (
                  <Button loading={loading} text={"Cargando"} />
                ) : (
                  <Button onClick={handleUpdate} text={"Actualizar"} />
                )}
              </Form.Item>
            </Form.Item>
          </>
        ) : (
          ""
        )}
      </Form>
    </Row>
  );
}
