class Category {
  constructor({ id, _id, name, scope, downstream, upstream, quantity }) {
    this.id = id || _id;
    this.quantity = quantity;
    this.name = name;
    this.scope = scope;
    this.upstream = upstream;
    this.downstream = downstream;
  }
}

export default Category;
