class Stats {
  constructor({
    egrn_price,
    total_projects,
    co2_offset,
    commission_buy_bonds,
    commission_buy_usd,
    commission_sell_offer,
    commission_buy_offer,
    lumens,
  }) {
    this.egrn_price = egrn_price;
    this.total_projects = total_projects;
    this.co2_offset = co2_offset;
    this.commission_buy_bonds = commission_buy_bonds;
    this.commission_buy_usd = commission_buy_usd;
    this.commission_sell_offer = commission_sell_offer;
    this.commission_buy_offer = commission_buy_offer;
    this.lumens = lumens;
  }
}
export default Stats;
