import React from "react";
import { Col, Row, Typography, Card, Modal, message } from "antd";
import OffsetData from "../../OffssetData/OffsetData";
import { postReduction } from "../../Saving/Application/Saving.business";
import Storage from "../../../src/Data/Domain/Storage";

const styles = {
  container: {
    marginBottom: 20,
    marginTop: 30,
  },
  ton: {
    fontFamily: "MontserratBold",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "24px",
    color: "#041370",
  },
};

const { Text } = Typography;

const Emissions = ({ title, data = {} }) => {
  const handleMitigations = (quantity, percent, parent) => {
    Modal.confirm({
      title: "Está seguro que desea mitigar la fila?",
      okText: "Si",
      okType: "danger",
      onOk: async () => {
        try {
          await postReduction({
            quantity,
            percent,
            parent,
            type: "reduction",
            id_user: new Storage("id").getItem(),
          });
          message.success("Se añadió a reducciones");
        } catch (error) {
          message.error("Se produjo un error, vuelva a intentarlo");
        }
      },
    });
  };

  return (
    <div className="grid-business">
      <div className="grid-item">
        <Card className="card-content">
          <Row style={styles.container}>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Text style={styles.ton}>{title}</Text>
            </Col>
          </Row>

          <Row gutter={5}>
            {data.map(
              (
                {
                  quantity,
                  name,
                  percent,
                  nameSecond,
                  arrowUp,
                  arrowDown,
                  expandible,
                  subcategory,
                  parent,
                },
                i
              ) => (
                <Col key={i} xs={24} sm={24} md={24} lg={24}>
                  <OffsetData
                    quantity={quantity}
                    name={name}
                    percent={percent}
                    nameSecond={nameSecond}
                    arrowUp={arrowUp}
                    arrowDown={arrowDown}
                    expandible={expandible}
                    icons={true}
                    mitigations={true}
                    handleMitigations={() =>
                      handleMitigations(quantity, percent, parent)
                    }
                  />
                </Col>
              )
            )}
          </Row>
        </Card>
      </div>
    </div>
  );
};

export default Emissions;
