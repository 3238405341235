import Formatter from "../../Data/Domain/Formatter";

class SupplierBySubcategory {
  constructor({ _id, name,total,percent }) {
    this.id = _id;
    this.name = name;
    this.total = Formatter.round(total);
    this.percent = percent;
  }
}

export default SupplierBySubcategory;
