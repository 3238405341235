import { useSelector } from "react-redux";
import { Layout, Tabs } from "antd";
import "./companiesTab.css";
import Operations from "../../Operations/UI/Operations";
import CompaniesContactForm from "../../CompaniesContactForm/UI/companiesContactForm";
import CompaniesInformation from "../../CompaniesInformation/UI/CompaniesInformation";
import CompaniesBankDetails from "../../CompaniesBankDetails/UI/companiesBankDetails";
import { getSelectedCompanySelector } from "../../Companies/Infrastructure/Companies.reducer";
import CompaniesId from "../../CompaniesId/UI/CompaniesId";
import CompaniesDocuments from "../../CompaniesDocuments/UI/CompaniesDocuments";
import CompaniesStatus from "../../CompaniesStatus/UI/companiesStatus";
import CompaniesOffset from "../../CompaniesOffset/UI/CompaniesOffset";

const { TabPane } = Tabs;

export default function CompaniesTab(props) {
  const { setProfile } = props;
  const selected = useSelector(getSelectedCompanySelector);

  return (
    <>
      <Layout>
        <Tabs centered>
          <TabPane
            tab={<span className={"tab-span"}>Información de contacto</span>}
            key="1"
          >
            <CompaniesContactForm user={selected} setProfile={setProfile} />
          </TabPane>
          <TabPane
            tab={<span className={"tab-span"}>Información Institución</span>}
            key="2"
          >
            <CompaniesInformation user={selected} />
          </TabPane>
          <TabPane
            tab={<span className={"tab-span"}>Cuentas Bancarias</span>}
            key="3"
          >
            <CompaniesBankDetails user={selected} />
          </TabPane>
          <TabPane
            tab={<span className={"tab-span"}>Transacciones</span>}
            key="4"
          >
            <Operations user={selected} />
          </TabPane>
          <TabPane
            tab={<span className={"tab-span"}>Identificación</span>}
            key="5"
          >
            <CompaniesId user={selected} />
          </TabPane>
          <TabPane tab={<span className={"tab-span"}>Documentos</span>} key="6">
            <CompaniesDocuments user={selected} />
          </TabPane>
          <TabPane tab={<span className={"tab-span"}>Estado</span>} key="7">
            <CompaniesStatus user={selected} />
          </TabPane>
          <TabPane
            tab={<span className={"tab-span"}>Clasificadora</span>}
            key="8"
          >
            <CompaniesOffset user={selected} />
          </TabPane>
        </Tabs>
      </Layout>
    </>
  );
}
