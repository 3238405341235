import {
  getCompanyRepository,
  getCompaniesRepository,
} from "../Infrastructure/Companies.repository";

export async function getCompaniesLogic(params) {
  return getCompaniesRepository(params);
}

export async function getCompanyLogic(id) {
  return getCompanyRepository(id);
}
