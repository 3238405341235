import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Formatter from "../../Data/Domain/Formatter";
import Storage from "../../Data/Domain/Storage";
import { Card, Col, Row, Typography, message, Checkbox } from "antd";
import InterfaceTable from "../../Table/InterfaceTable";
import "./Permissions.css";
import {
  getPermissionsCountSelector,
  getPermissionsSelector,
} from "../Infrastructure/Permissions.reducer";
import {
  getPermissionsByOwner,
  updatePermission,
  getPermission,
} from "../Application/Permissions.bussines";
import { useDispatch } from "react-redux";
import { setPermissions } from "../Infrastructure/Permissions.reducer";

const { Text } = Typography;

const Permissions = () => {
  const permissions = useSelector(getPermissionsSelector);
  const count = useSelector(getPermissionsCountSelector);
  const [values, setValues] = useState({
    page: 0,
    limit: 25,
  });
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState("false");
  const dispatch = useDispatch();
  const [ownerId, setOwnerId] = useState(new Storage("id").getItem());

  const fetchData = async (params = {}) => {
    try {
      await getPermissionsByOwner();
      const filtros = params.filter ? JSON.stringify(params.filter) : [""];
      setFilters(filtros);
      if (Object.entries(params).length > 0) message.success("Filtrado");
      setLoading(false);
      message.success("Filtrado");
    } catch (error) {
      message.error("error");
    }
  };

  useEffect(() => {
    fetchData({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRequest = (newPagination = {}, filters = {}, sorter = {}) => {
    const { current: page = 1, pageSize: limit = 5 } = newPagination;
    const { field: sortField, order: sortOrder } = sorter;
    const clean = Formatter.cleanParamsFromTableToAPI({
      ...filters,
      limit,
      sortField,
      sortOrder,
      page: page - 1,
      dates: [],
    });
    setValues({
      ...values,
      limit,
      page: page - 1,
    });
    fetchData(clean);
  };
  const handleUpdate = async (userId, permissionKey, access) => {
    try {
      setLoading(true);
      const updatedPermissions = permissions.map((user) => {
        if (user.id === userId) {
          return {
            ...user,
            permissions: user.permissions.map((permission) =>
              permission.key === permissionKey
                ? { ...permission, access: !permission.access }
                : permission
            ),
          };
        }
        return user;
      });
      dispatch(setPermissions(updatedPermissions));

      await updatePermission(userId, {
        key: permissionKey,
        access: !access,
      });
      message.success("Check actualizado");
    } catch (error) {
      message.error("Error al actualizar el check");
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Usuario",
      dataIndex: "namePermission",
      key: "namePermission",
    },
    {
      title: "Proyecto",
      dataIndex: "project_name",
      key: "project_name",
    },
    {
      title: "Accesos",
      dataIndex: "permissions",
      key: "permissions",
      render: (_, record) => {
        return record.permissions.map((element, index) => (
          <div className="containerAccess" key={index}>
            <div className="flexAccess">
              <div className="columnLeft">{element.key}</div>
              <div className="columnRight">
                <Checkbox
                  key={element.key}
                  checked={element.access}
                  onChange={() =>
                    handleUpdate(record.id, element.key, element.access)
                  }
                />
              </div>
            </div>
          </div>
        ));
      },
    },
  ];
  return (
    <div className="grid-permissions">
      <div>
        <Card className="card-permissions">
          <Row className="permissions-title">
            <Col className="my-permissions" span={24}>
              <Text className="text-my-permissions">Permisos</Text>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InterfaceTable
                rowKey="id"
                data={permissions}
                columns={columns}
                onChange={handleRequest}
                pagination={{
                  pageSize: values.limit,
                  total: count,
                  onChange: (current) => {},
                }}
              />
            </Col>
          </Row>
        </Card>
      </div>
    </div>
  );
};

export default Permissions;
