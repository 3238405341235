import React from "react";
import InterfaceTable from "../../Table/InterfaceTable";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { Popconfirm, Button, message } from "antd";
import Viewer from "../../Viewer/Viewer";
import { getByCompany } from "../../DocumentUpload/Application/DocumentUpload.business";
import {
  getDocumentsSelector,
  getDocumentsCountSelector,
} from "../../DocumentUpload/Infrastructure/DocumentUpload.reducer";
import { deleteDocument } from "../../DocumentUpload/Application/DocumentUpload.business";
import DocumentStatus from "../../DocumentStatus/DocumentStatus";

const columns = [
  {
    title: "Archivo",
    dataIndex: "url",
    key: "url",
    responsive: ["xxl", "xl", "lg", "md", "sm", "xs"],
    render: (_, record) => <Viewer link={record.url} title={"ABRIR"} />,
  },
  {
    title: "Tipo",
    dataIndex: "documentType",
    key: "documentType",
    responsive: ["xxl", "xl", "lg", "md", "sm", "xs"],
  },
  {
    title: "Estado",
    dataIndex: "documentStatus",
    key: "documentStatus",
    responsive: ["xxl", "xl", "lg", "md", "sm", "xs"],
  },
  {
    title: "Action",
    dataIndex: "",
    key: "x",
    responsive: ["xxl", "xl", "lg", "md", "sm", "xs"],
    render: (_, record) => (
      <>
        <Popconfirm
          title="Deseas eliminar?"
          onConfirm={() => handleDelete(record.key)}
        >
          {!record.was_approved && <Button type="link">{"Eliminar"}</Button>}
        </Popconfirm>
        <DocumentStatus id={record.id} default={record.status} />
      </>
    ),
  },
];

const handleDelete = async (key) => {
  try {
    await deleteDocument(key);
    message.success("Se eliminó el documento");
  } catch (error) {
    message.error("Se produjo un error, vuelva a intentarlo");
  }
};

const CompaniesDocuments = (props) => {
  const { user } = props;
  const documents = useSelector(getDocumentsSelector);
  const count = useSelector(getDocumentsCountSelector);
  const limit = 5;

  useEffect(() => {
    getByCompany(user.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <InterfaceTable
      pagination={{
        pageSize: limit,
        total: count,
        onChange: (current) => {
          getByCompany({ page: current - 1, limit });
        },
      }}
      data={documents}
      columns={columns}
    />
  );
};

export default CompaniesDocuments;
