import Response from "../../Data/Domain/Response";
import Offer from "../Domain/Offer";
import { getOffers, getOffer } from "./Offers.service";

export async function getOffersRepository(params) {
  const data = await getOffers(params);
  return new Response(data, Offer).getMultiple();
}

export async function getOfferRepository(id) {
  const data = await getOffer(id);
  return new Response(data, Offer).getSingle();
}
