class CustomError {
  constructor(error_data) {
    this.error_data = error_data;
  }

  get message() {
    switch (true) {
      case this.error_data.errors && this.error_data.errors.length > 0:
        return this.error_data.errors;
      case this.error_data.message && this.error_data.message.length > 0:
        return this.error_data.message;
      default:
        return "General Error";
    }
  }
}

export default CustomError;
