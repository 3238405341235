import { saveStats } from "../Infrastructure/Dashboard.store";
import { getStatsLogic, updateStatsLogic } from "./Dashboard.logic";

export async function getStats(params) {
  const stats = await getStatsLogic(params);
  saveStats(stats);
}

export async function updateStats(params) {
  const stats = await updateStatsLogic(params);
  saveStats(stats);
}
