import {
  saveListPermissions,
  saveCountPermissions,
  saveSelectedPermission,
} from "../Infrastructure/Permissions.store";
import {
  getPermissionsLogic,
  getPermissionLogic,
  updatePermissionLogic,
  getPermissionsByOwnerLogic,
} from "./Permissions.logic";

export async function getPermissions(params) {
  const { count, resources: permissions } = await getPermissionsLogic(params);
  saveListPermissions(permissions);
  saveCountPermissions(count);
}

export async function getPermissionsByOwner(params) {
  const { count, resources: permissions } = await getPermissionsByOwnerLogic(
    params
  );
  saveListPermissions(permissions);
  saveCountPermissions(count);
}
export async function getPermission(id) {
  const permission = await getPermissionLogic(id);
  saveSelectedPermission(permission);
}
export async function updatePermission(id, params) {
  await updatePermissionLogic(id, params);
  // const permission = await getPermissionLogic(id);
  // saveSelectedPermission(permission);
}
// export async function getPermission(id) {
//   const { resources: permissions,count } = await getPermissionLogic(id);
//   saveListPermissions(permissions);
//   saveCountPermissions(count);

// }
