import CustomDate from "../../Data/Domain/CustomDate";
import Formatter from "../../Data/Domain/Formatter";

class Offset {
  constructor({
    data = null,
    categories_emission = [],
    scopes = null,
    emission_per_year = [],
    emission_per_month = [],
  }) {
    this.categories_emission = categories_emission;
    this.scopes = this.get_scopes(scopes);
    this.data = this.get_data(data);
    this.emission_per_year = emission_per_year;
    this.emission_per_month = emission_per_month;
  }

  get_data(data) {
    let year = new CustomDate(new Date()).getYear;
    let initial_data = {};
    initial_data[`${year}`] = {
      total_co2: 0,
      emission_projected: 0,
      emission_avg: 0,
      emission_saving: 0,
    };

    return data ? data : initial_data;
  }

  static formatScopes(scope) {
    if (scope.includes("scope")) {
      return "Scope " + scope.split("scope")[1];
    }
    return scope;
  }

  get_scopes(scopes) {
    const scopes_data = {
      scope1: {
        quantity: 0,
        percent: 0,
      },
      scope2: {
        quantity: 0,
        percent: 0,
      },
      scope3: {
        quantity: 0,
        percent: 0,
      },
      total: 0,
    };
    return scopes ? scopes : scopes_data;
  }

  scopes_for_table() {
    const scopesArray = Object.keys(this.scopes).map((scopeKey) => {
      if (scopeKey === "total") {
        return {
          id: scopeKey,
          scope: scopeKey,
          emissions: Formatter.formatNumber(this.scopes[scopeKey]),
        };
      }
      return {
        id: scopeKey,
        scope: Offset.formatScopes(scopeKey),
        emissions: Formatter.formatNumber(this.scopes[scopeKey].quantity),
        percent: Formatter.formatNumber(this.scopes[scopeKey].percent)
      };
    });
    return scopesArray;
  }

  formatterYearForTable() {
    const formattedEmissionPerYearData = this.emission_per_year.map((item) => ({
      ...item,
      emission: Formatter.formatNumber(item.emission),
    }));
    return formattedEmissionPerYearData;
  }

  formattedEmissionForTable() {
    const formattedEmissionPerDateData = this.emission_per_month.map(
      (item) => ({
        ...item,
        tons: Formatter.formatNumber(item.tons),
        date: new CustomDate(item.date).getMonthName(),
      })
    );
    return formattedEmissionPerDateData;
  }
}

export default Offset;
