import Response from "../../Data/Domain/Response";
import Company from "../Domain/Company";
import { getCompanies, getCompany, updateCompany } from "./Companies.service";

export async function getCompaniesRepository(params) {
  const data = await getCompanies(params);
  return new Response(data, Company).getMultiple().resources;
}

export async function getCompanyRepository(id) {
  const data = await getCompany(id);
  return new Response(data, Company).getSingle();
}

export async function updateCompanyRepository(id, params) {
  const data = await updateCompany(id, params);
  return new Response(data, Company).getSingle().resources;
}
