class Key {
    constructor({  id,data, type,  project }) {
      this.id = id;
      this.data= data;
      this.type = type;
      this.project = project;
    }
  
  }
  
  export default Key;

  