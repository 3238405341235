import { useState } from "react";
import { Form, Input, Row, InputNumber, message } from "antd";
import { updateBonds } from "../Application/Projects.business";
import Button from "../../Layout/DesignSystem/Button/Button";

const { TextArea } = Input;

export default function ProjectDetail({ form, record, update_bonds }) {
  const [bondsAmount, setBondsAmount] = useState(0);
  const [loading, setLoading] = useState(false);

  const onHandleChange = () => (value) => {
    setBondsAmount(value);
  };

  const handleUpdate = () => {
    if (record.availableBonds + bondsAmount < 0) {
      message.error(
        "La cantidad de bonos disponibles es menor a la cantidad de bonos actualizados"
      );
      form.resetFields([update_bonds]);
    } else {
      setLoading(true);
      const data = {
        carbon_credit: bondsAmount,
      };
      updateBonds(record.id, data)
        .then(() => {
          message.success("Bonos actualizados con éxito");
          setLoading(false);
          form.resetFields([update_bonds]);
        })
        .catch(() => {
          message.error("Error al actualizar bonos");
          setLoading(false);
          form.resetFields([update_bonds]);
        });
    }
  };

  return (
    <Row type="flex" justify="center" align="middle">
      <Form
        name="basic"
        labelCol={{
          span: 10,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
          update_bonds: 0,
        }}
        autoComplete="off"
        form={form}
      >
        {" "}
        {record ? (
          <>
            <Form.Item label="Nombre">
              <TextArea
                value={record.project_name}
                rows={2}
                disabled
                maxLength={6}
              />
            </Form.Item>
            <Form.Item label="Pais">
              <Input value={record.country} disabled />
            </Form.Item>
            <Form.Item label="Región">
              <Input value={record.region} disabled />
            </Form.Item>
            <Form.Item label="Provincia">
              <Input value={record.province} disabled />
            </Form.Item>
            <Form.Item label="Descripción">
              <TextArea
                value={record.description}
                rows={4}
                disabled
                maxLength={6}
              />
            </Form.Item>
            <Form.Item label="Precio">
              <Input value={record.tonPrice} disabled />
            </Form.Item>
            <Form.Item label="Status">
              <Input value={record.statusValue} disabled />
            </Form.Item>
            <Form.Item label="Bonos disponibles">
              <Input value={record.availableBonds} disabled />
            </Form.Item>
            <Form.Item label="Actualizar bonos">
              <Form.Item
                extra="(Solo ingresar números enteros)"
                name={update_bonds}
              >
                <InputNumber
                  name={update_bonds}
                  style={{ width: "100%" }}
                  step={1}
                  precision={0}
                  onChange={onHandleChange(update_bonds)}
                />
              </Form.Item>
              {loading ? (
                <Button loading={loading} text={"Cargando"} />
              ) : (
                <Button onClick={handleUpdate} text={"Actualizar"} />
              )}
            </Form.Item>
          </>
        ) : (
          ""
        )}
      </Form>
    </Row>
  );
}
