import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { Col, Row, message } from "antd";
import "antd/dist/antd.min.css";
import Formatter from "../../Data/Domain/Formatter";
import { Title } from "../../Utils/UI/Text";
import {
  getSelectedOfferSelector,
  getOffersSelector,
  getOffersCountSelector,
} from "../Infrastructure/Offers.reducer";
import { getOffers, getOffer } from "../Application/Offers.bussines";
import OfferDetail from "./OffersDetail";
import InterfaceTable from "../../Table/InterfaceTable";
import RangePicker from "../../Layout/DesignSystem/Picker/Picker";
import ExportButtonElement from "../../Layout/DesignSystem/ExportButton/Button";

const columns = [
  {
    title: "Tipo",
    dataIndex: "typeValue",
    key: "typeValue",
    filters: [
      {
        text: "COMPRA",
        value: "BUY",
      },
      {
        text: "VENTA",
        value: "SELL",
      },
    ],
  },
  {
    title: "Estado",
    dataIndex: "statusValue",
    key: "statusValue",
    filters: [
      {
        text: "PENDIENTE",
        value: "pending",
      },
      {
        text: "ABIERTO",
        value: "open",
      },
      {
        text: "HECHO",
        value: "done",
      },
      {
        text: "CERRADO",
        value: "close",
      },
    ],
  },
  {
    title: "Fecha",
    dataIndex: "date",
    key: "date",
    sorter: true,
  },
  {
    title: "Monto",
    dataIndex: "calculateAmount",
    key: "calculateAmount",
  },
  {
    title: "Precio",
    dataIndex: "calculatePrice",
    key: "calculatePrice",
  },
  {
    title: "Comisión",
    dataIndex: "calculateFee",
    key: "calculateFee",
  },
  {
    title: "Total",
    dataIndex: "calculateTotal",
    key: "calculateTotal",
  },
];

export default function OffersBoard() {
  const record = useSelector(getSelectedOfferSelector);
  const offers = useSelector(getOffersSelector);
  const count_offers = useSelector(getOffersCountSelector);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const dates = useRef([]);
  const [values, setValues] = useState({
    page: 0,
    limit: 10,
  });
  const [filters, setFilters] = useState("false");

  const onChange = (changed_values) => {
    dates.current = !!changed_values
      ? changed_values.map((value) => {
        return moment(value).format("YYYY-MM-DD");
      })
      : [];
    handleRequest({}, {}, {});
  };

  const fetchData = async (params = {}) => {
    try {
      await getOffers(params);
      const filtros = params.filter ? JSON.stringify(params.filter) : [""];
      setFilters(filtros);
      if (Object.entries(params).length > 0) message.success("Filtrado");
      setLoading(false);
    } catch (error) {
      message.error("error");
    }
  };

  useEffect(() => {
    fetchData({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRequest = (newPagination = {}, filters = {}, sorter = {}) => {
    const { current: page = 1, pageSize: limit = 10 } = newPagination;
    const { field: field_sort, order: sortOrder } = sorter;
    const sort = sortOrder === 'ascend' ? 'ascending' : 'descending';

    const clean = Formatter.cleanParamsFromTableToAPI({
      ...filters,
      limit,
      field_sort,
      sort,
      page: page - 1,
      dates: dates.current,
    });

    setValues({
      ...values,
      limit,
      page: page - 1,
    });

    fetchData(clean);
  };
  const datePicker = {
    marginBottom: 20,
    marginTop: 20,
  };
  const title = {
    marginBottom: 20,
  };

  return (
    <main>
      <Row style={title}>
        <Col
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 20,
          }}
          span={24}
        >
          <Col span={12}>
            <Title>Ofertas</Title>
          </Col>
          <Col span={12} style={{ width: 112, display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
            <ExportButtonElement
              type={"offers"}
              text={"Exportar"}
              filter={filters}
            />
          </Col>
        </Col>
      </Row>
      <Row style={datePicker}>
        <RangePicker onChange={onChange} />
      </Row>

      <Row>
        <Col span={24}>
          <InterfaceTable
            rowKey="id"
            onRow={(selected) => {
              return {
                onClick: () => {
                  getOffer(selected.id);
                  setVisible(true);
                },
              };
            }}
            data={offers}
            columns={columns}
            pagination={{
              current: values.page + 1,
              pageSize: values.limit,
              total: count_offers,
            }}
            loading={loading}
            onChange={handleRequest}
          />
        </Col>
      </Row>
      <OfferDetail
        onCancel={() => setVisible(false)}
        record={record}
        visible={visible}
        onOk={() => setVisible(false)}
      />
    </main>
  );
}
