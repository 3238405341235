import axios from "axios";
import CustomError from "../../Utils/Domain/CustomError";
import Storage from "./Storage";

class API {
  constructor(url, headers = {}) {
    this.url = url;
    this.config = {
      headers: {
        ...headers,
        Authorization: `Bearer ${new Storage("token").getItem()}`,
      },
    };
  }

  async post(object) {
    try {
      return await axios.post(this.url, object, this.config);
    } catch (error) {
      throw new CustomError(error);
    }
  }

  async get() {
    try {
      return await axios.get(this.url, this.config);
    } catch (error) {
      throw new CustomError(error);
    }
  }

  async put(object = {}) {
    try {
      return await axios.put(this.url, object, this.config);
    } catch (error) {
      throw new CustomError(error);
    }
  }

  async patch(object = {}) {
    try {
      return await axios.patch(this.url, object, this.config);
    } catch (error) {
      throw new CustomError(error);
    }
  }

  async delete() {
    try {
      return await axios.delete(this.url, this.config);
    } catch (error) {
      throw new CustomError(error);
    }
  }
}

export default API;
