
import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "project",
  initialState: {
    list: [],  
    count: 0,
  },

  reducers: {
    setOwnersByProject: (state, { payload: list }) => {
      return {
        ...state,
        list,
      };
    },
    countOwnersByProject: (state, {payload: count}) => {
      return {
        ...state,
        count,
      };
    },

  },
});

export const {
  setOwnersByProject,
  countOwnersByProject
} = slice.actions;

export default slice.reducer;

export const getsetOwnersByProjectSelector = (store) => store.project.list;
export const getOwnersByProjectCountSelector = (store) =>store.project.count;

