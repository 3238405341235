import store from "../../Redux/store";
import {
  setAdmins,
  selectAdmin,
  cancelSelectedAdmin,
} from "./Administrators.reducer";

export function saveListAdmins(records) {
  store.dispatch(setAdmins(records));
}

export function saveSelectedAdmin(admin) {
  store.dispatch(selectAdmin(admin));
}

export function deselectAdmin() {
  store.dispatch(cancelSelectedAdmin());
}
