import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import { getOffsetsSelector } from "../../DashboardOffset/Infrastructure/DashboardOffset.reducer";
import { Radio } from "antd";
import Date from "../../Data/Domain/CustomDate";

function LineChart() {
  const [series, setSeries] = useState([]);
  const [month, setMonth] = useState([]);
  const [labelMonth] = useState([
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ]);
  const [yearRange, setYearRange] = useState([""]);
  const emissions = useSelector(getOffsetsSelector);
  const [options, setOptions] = useState();
  const [colors] = useState(["#0319a3"]);

  useEffect(() => {
    let monthData = [],
      totalData = [];
    const emissionsData = emissions.emission_per_month;
    for (let obj in emissionsData) {
      let fecha = emissionsData[`${obj}`].date;
      monthData.push(new Date(fecha).formatDateForChart);
      totalData.push(emissionsData[`${obj}`].tons);
    }
    setYearsRange(emissionsData);
    setMonth(monthData);
    setSeries([
      {
        name: "Carbon Footprint",
        data: totalData,
      },
    ]);
  }, [emissions.emission_per_month]);

  useEffect(() => {
    if (month) {
      setOptions(_options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month, yearRange, series]);

  const setYearsRange = (emissionsData) => {
    let yearRange = [],
      yearRangeString = "",
      separador = "";

    for (let obj in emissionsData) {
      let fecha = new Date(emissionsData[`${obj}`].date);
      let year = fecha.getYear;
      yearRange.push(year);
    }
    yearRange = [...new Set(yearRange)];

    for (let i = 0; i < yearRange.length; i++) {
      separador = i > 0 ? "-" : "";
      yearRangeString += separador + yearRange[i];
    }
    setYearRange(yearRangeString);
  };

  const _options = {
    title: {
      text: "Emission per month",
      align: "left",
      margin: 80,
      offsetX: 0,
      offsetY: -30,
      floating: true,
      style: {
        fontSize: "1px",
        fontWeight: "bold",
        fontFamily: "MontserratBold",
        color: "#041370",
      },
    },
    colors: colors,
    tooltip: { enabled: true },
    chart: {
      height: 600,
      width: 600,
      type: "line",
      stacked: false,
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        tools: {
          download: false,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: false,
          reset: false,
        },
      },
    },
    forecastDataPoints: {
      count: 0,
    },
    stroke: {
      width: 2,
      curve: "smooth",
    },
    xaxis: {
      categories: month,
      title: {
        text: "Month " + yearRange,
        style: {
          fontWeight: "bold",
          fontFamily: "MontserratBold",
          color: "#041370",
        },
      },
      tickAmount: Math.min(11, month.length),

      labels: {
        formatter: function (value) {
          if (value && value.length > 3) {
            let arrayFecha = value.split("/");
            return labelMonth[Number(arrayFecha[1] - 1)];
          }
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        gradientToColors: ["#0319a3", "#ff2f00"],
        shadeIntensity: 1,
        type: "horizontal",
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100, 100, 100],
      },
    },
    yaxis: {
      min: 0,
      max: series.length > 0 ? Math.max(...series[0].data) + 5 : 0,
      title: {
        text: "Tons",
        style: {
          fontWeight: "bold",
          fontFamily: "MontserratBold",
          color: "#041370",
        },
      },
    },
  };

  return (
    <>
      <Radio.Button
        style={{
          borderRadius: "20px",
          height: "400px",
          width: "100%",
          margin: "0px",
        }}
        className="scope-container"
      >
        <div className="text-container">
          <div className="donut">
            {emissions.emission_per_month && options && series && month && (
              <Chart
                options={options}
                series={series}
                type="line"
                height={350}
                width={500}
              />
            )}
          </div>
        </div>
      </Radio.Button>
    </>
  );
}
export default LineChart;
