import {
  getUserRepository,
  getUsersRepository,
  deleteUserRepository,
  updateEnabledRepository,
  updateLumensRepository
} from "../Infrastructure/Users.repository";

export async function getUsersLogic(params) {
  return getUsersRepository(params);
}

export async function getUserLogic(id) {
  return getUserRepository(id);
}

export async function deleteUserLogic(id, params) {
  return deleteUserRepository(id, params);
}
export async function updateEnabledLogic(id, params) {
  return updateEnabledRepository(id, params);
}
export async function updateLumensLogic(id, params) {
  return updateLumensRepository(id, params);
}