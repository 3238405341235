import React from "react";
import { Radio } from "antd";
import "antd/dist/antd.min.css";
import "./Category.css";

const Category = ({ title, id }) => {
  return (
    <Radio.Button value={title} key={id} className="radio-btn-container">
      <div className="title-container">{title}</div>
    </Radio.Button>
  );
};

export default Category;
