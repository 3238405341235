import store from "../../Redux/store";
import {
  setCategories,
  countCategories,
  selectCategory,
  selectSubcategory,
  setSubcategories,
  countSubcategories,
  setSubcategoriesByCateg,
} from "./Categories.reducer";
import { setCategoriesByUser } from "../../DataUser/Infrastructure/DataUser.reducer";

export function saveListCategories(records) {
  store.dispatch(setCategories(records));
}

export function saveCountCategories(count) {
  store.dispatch(countCategories(count));
}

export function saveSelectedCategory(category) {
  store.dispatch(selectCategory(category));
}

export function saveSelectedSubcategory(subcategory) {
  store.dispatch(selectSubcategory(subcategory));
}

export function saveListSubcategories(records) {
  store.dispatch(setSubcategories(records));
}

export function saveCountSubcategories(count) {
  store.dispatch(countSubcategories(count));
}

export function saveListSubcategoriesByCateg(records) {
  store.dispatch(setSubcategoriesByCateg(records));
}

export function saveListCategoriesByUser(records) {
  store.dispatch(setCategoriesByUser(records));
}
