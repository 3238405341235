import API from "../../Data/Domain/API";
import URL from "../../Data/Domain/URL";

export async function getReport(type, filter) {
  let filterString = filter.toString()
    ? `?filter=${filter.toString()}`
    : "?filter{}";
  return await new API(
    URL.buildUrl(`reports/${type}${filterString}&is_report=true`)
  ).post();
}
