import {
  getCollaboratorsRepository,
  getByCompanyRepository,
  deleteCollaboratorRepository,
  revokeCollaboratorRepository,
} from "../Infrastructure/Collaborators.repository";

export async function getCollaboratorsLogic(params) {
  return getCollaboratorsRepository(params);
}

export async function getByCompanyLogic() {
  return getByCompanyRepository();
}

export async function deleteCollaboratorLogic(id) {
  return deleteCollaboratorRepository(id);
}
export async function revokeCollaboratorLogic(id, params) {
  return revokeCollaboratorRepository(id, params);
}
