import Response from "../../Data/Domain/Response";
import Stats from "../Domain/Dashboard";
import { getStats, updateStats } from "./DashBoard.service";

export async function getStatsRepository(params) {
  const data = await getStats(params);
  return new Response(data, Stats).getSingle();
}

export async function updateStatsRepository(params) {
  const data = await updateStats(params);
  return new Response(data, Stats).onlyStatus();
}
