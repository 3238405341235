import React from "react";
import ProgressBar from "../ProgressBar/ProgressBar";
import { Col, Row, Space, Typography, Collapse, Button } from "antd";
import ExpandibleData from "../OffssetDataTest/ExpandibleData";
import {
  ArrowDownOutlined,
  MinusCircleFilled,
  UnorderedListOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import "./OffsetData.css";
import Notification from "../Notification/Notification";
import Assignation from "../Assignation/Assignation";

const { Panel } = Collapse;
const { Text } = Typography;

const styles = {
  container: {},
  containerQuantity: {
    width: "50%",
  },
  containerName: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  name: {
    fontFamily: "MontserratMedium",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "17px",
    color: "#041370",
  },
  quantity: {
    fontFamily: "MontserratMedium",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "11px",
    lineHeight: "150%",
    letterSpacing: "0.02em",
    color: "#041370",
  },
  containerArrow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  migrations: {
    fontSize: 15,
    color: "#00A389",
    fontWeight: 600,
  },
};

const OffsetData = ({
  id,
  name,
  nameSecond,
  quantity,
  percent,
  arrowUp,
  arrowDown,
  expandible,
  icons = false,
  mitigations = false,
  actions = false,
  deleteIcon = false,
  handleDelete,
  handleMitigations,
  items = [],
  key,
  activePanelKey,
  handlePanelChange,

}) => {
  const offSetData = icons ? (
    <Row className="root-dash" gutter={8}>
      <Col style={styles.containerArrow} xs={12} sm={1} md={1} lg={1}>
        <Space direction="vertical" size="large" className="space-dash">
          {arrowUp}
        </Space>
        <Space direction="vertical" size="large" className="space-below">
          {arrowDown}
        </Space>
      </Col>
      <Col style={styles.containerName} xs={12} sm={9} md={7} lg={7}>
        <Row>
          <Text style={styles.name}>{name}</Text>
        </Row>
        <Row>
          <Text style={styles.name}>{nameSecond}</Text>
        </Row>
      </Col>
      <Col xs={12} sm={6} md={9} lg={10} style={styles.containerQuantity}>
        <Row>
          <Text style={styles.quantity}>{quantity}</Text>
        </Row>
        <Row>
          <ProgressBar percent={percent} />
        </Row>
      </Col>
      <Col
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
        xs={12}
        sm={8}
        md={7}
        lg={6}
      >
        {deleteIcon && (
          <Button
            onClick={handleDelete}
            shape="round"
            className="delete-dash"
            icon={<MinusCircleFilled />}
          />
        )}
        {actions && (
          <Space className="migrations-dash">
            <Notification
              icon={<UnorderedListOutlined />}
              type="text"
              description={
                <Assignation id={id} text={name} items={items} key={key} />
              }
            />
            <Text className={"text-migration"}>{"Actions"}</Text>
          </Space>
        )}
        {mitigations && (
          <Button
            className="migrations-dash"
            onClick={(e) => {
              e.stopPropagation();
              handleMitigations();
            }}
            icon={<PlusCircleOutlined style={styles.migrations} />}
          >
            <Text className={"text-migration"}>{"Mitigations"}</Text>
          </Button>
        )}
      </Col>
    </Row>
  ) : (
    <Row className="root-dash" gutter={8}>
      <Col style={styles.containerArrow} span={1}>
        <Space direction="vertical" size="large" className="space-dash">
          {arrowUp}
        </Space>
        <Space direction="vertical" size="large" className="space-below">
          {arrowDown}
        </Space>
      </Col>
      <Col style={styles.containerName} span={11}>
        <Row>
          <Text style={styles.name}>{name}</Text>
        </Row>
        <Row>
          <Text style={styles.name}>{nameSecond}</Text>
        </Row>
      </Col>
      <Col span={12} style={styles.containerQuantity}>
        <Row>
          <Text style={styles.quantity}>{quantity}</Text>
        </Row>
        <Row>
          <ProgressBar percent={percent} />
        </Row>
      </Col>
    </Row>
  );
  return (
    <div>
      {expandible ? (
        <Collapse accordion={true} size={"small"} bordered={true} ghost={true}
          activeKey={activePanelKey}
          onChange={handlePanelChange}
        >
          <Panel
            xs={24}
            sm={24}
            md={24}
            lg={24}
            showArrow={false}
            style={{ width: "100%" }}
            header={
              <Col xs={24} sm={24} md={24} lg={24}>
                {offSetData}
              </Col>
            }
            key={id}
          >
            {expandible && (
              <Col xs={24} sm={24} md={24} lg={24}>
                <ExpandibleData
                  id_subcategory={id}
                  name="Internet"
                  nameSecond=""
                  quantity="65% tons CO2"
                  percent={65}
                  arrowDown={<ArrowDownOutlined style={styles.down} />}
                  migrations="33 Tons CO2"
                />
              </Col>
            )}
          </Panel>
        </Collapse>
      ) : (
        <Panel
          xs={24}
          sm={24}
          md={24}
          lg={24}
          showArrow={false}
          style={{ width: "100%" }}
          header={
            <Col xs={24} sm={24} md={24} lg={24}>
              {offSetData}
            </Col>
          }
          key={id}
        />
      )}
    </div>
  );
};

export default OffsetData;
