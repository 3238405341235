import React, { useState, useEffect } from "react";
import { Card, Col, Row, Typography, message } from "antd";
import InterfaceTable from "../../Table/InterfaceTable";
import "./Owners.css";
import NewGeneralInfo from "./NewGeneralInfo";
import { useSelector } from "react-redux";
import { getProjectKeys } from "../../Keys/Application/Keys.business";
import { getKeysSelector } from "../../Keys/Infrastructure/Keys.reducer";
import {
  getProject,
  getProjects,
} from "../../Projects/Application/Projects.business";
import { getProjectsSelector } from "../../Projects/Infrastructure/Projects.reducer";
import Formatter from "../../Data/Domain/Formatter";
import InfoGeneral from "../../Projects/Domain/InfoGeneral";

const { Text } = Typography;

const Generals = ({ recordId }) => {
  const keys = useSelector(getKeysSelector);
  // console.log(JSON.stringify(keys) );
  const projects = useSelector(getProjectsSelector);
  // console.log(JSON.stringify(projects));
  useEffect(() => {
    getProjects();
    if (recordId) {
      getProject(recordId);
    }
  }, [recordId]);

  const [values, setValues] = useState({
    page: 0,
    limit: 5,
  });
  const [loading, setLoading] = useState(false);

  const [filters, setFilters] = useState("false");
  const [combinedData, setCombinedData] = useState([]);

  const columns = [
    {
      title: "Nombre",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Dato",
      dataIndex: "data",
      key: "data",
    },
    {
      title: "Tipo",
      dataIndex: "typeStr",
      key: "typeStr",
    },
  ];

  // Todo: Crear la infraestructura de Keys.
  // Todo: En un useEffect Obtener la data desde la store de Projects y Keys de  [Impacts], [Sdgs], [Key, ProjectDescription],
  // Todo: Combinar los 3 arrays en uno solo de tipo InfoGeneral
  const fetchData = async (params = {}) => {
    try {
      await getProjectKeys(recordId);
      const filtros = params.filter ? JSON.stringify(params.filter) : [""];
      setFilters(filtros);
      if (Object.entries(params).length > 0) message.success("Filtrado");
      setLoading(false);
      message.success("Filtrado");
    } catch (error) {
      message.error("error");
    }
  };

  useEffect(() => {
    getProjectKeys(recordId);
    fetchData({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRequest = (newPagination = {}, filters = {}, sorter = {}) => {
    const { current: page = 1, pageSize: limit = 5 } = newPagination;
    const { field: sortField, order: sortOrder } = sorter;
    const clean = Formatter.cleanParamsFromTableToAPI({
      ...filters,
      limit,
      sortField,
      sortOrder,
      page: page - 1,
      dates: [],
    });
    setValues({
      ...values,
      limit,
      page: page - 1,
    });
    fetchData(clean);
  };

  const combineData = (projects, keysData) => {
    if (!keysData) return [];
    return projects.reduce((result, project) => {
      const impacts = project.impacts.map((impact) => ({
        id: impact._id,
        title: impact.title,
        data: impact.subtitle,
        typeStr: "impact",
      }));

      const sdgs = project.sdgs.map((sdg) => ({
        id: sdg._id,
        title: sdg.title,
        data: sdg.data,
        typeStr: "sdg",
      }));

      const keys = keysData
        .filter((key) => key.project === project.id)
        .map((key) => ({
          id: key._id,
          title: key.data,
          data: key.data,
          typeStr: "key",
        }));

      const projectDescription = new InfoGeneral({
        id: project.id,
        title: "Project Description",
        data: project.description,
        typeStr: "projectDescription",
      });

      return result.concat(impacts, sdgs, keys, projectDescription);
    }, []);
  };
  useEffect(() => {
    const keysData = keys.resources;
    const combinedData = combineData(projects, keysData);
    console.log(JSON.stringify(combinedData));
    setCombinedData(combinedData);
  }, [projects, keys]);

  return (
    <div className="grid-owners">
      <div>
        <Card className="card-owners">
          <Row className="owners-title">
            <Col className="my-projects" span={24}>
              <Text className="text-my-projects">
                Información general del proyecto{" "}
              </Text>
              <NewGeneralInfo recordId={recordId} />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InterfaceTable
                data={combinedData}
                columns={columns}
                onChange={handleRequest}
                // pagination={{
                //   pageSize: values.limit,
                //   onChange: (current) => {},
                // }}
              />
            </Col>
          </Row>
        </Card>
      </div>
    </div>
  );
};

export default Generals;
