import {
    getProjectKeysLogic
  } from "./Keys.logic";
  import {
   
    saveKeys
  } from "../Infrastructure/Keys.store";

  export async function getProjectKeys(id) {
    const keys = await getProjectKeysLogic(id);
    saveKeys(keys);

  }

