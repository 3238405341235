import React, { useEffect } from "react";
import ProgressBar from "../../ProgressBar/ProgressBar";
import { Col, Row, Typography } from "antd";
import { Card } from "antd";
import "./EmissionCategory.css";
import { useSelector } from "react-redux";
import { getEmissionCategory } from "../Aplication/EmissionCategory.business";
import { getEmissionCategorySelector } from "../Infrastructure/EmissionCategory.reducer";
const { Text } = Typography;

const styles = {
  container: {
    marginBottom: "16px",
  },

  containerName: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
  },
  name: {
    fontFamily: "MontserratBold",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "18px",
    color: " #000000",
    flexBasis: "100%",
    textAlign: "right",
  },
  containerProgress: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  title: {
    fontFamily: "MontserratBold",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "21px",
    lineHeight: "24px",
    color: "#041370",
    padding: "10px 10px 20px 10px",
  },
  cardEmission: {
    background: "#FFFFFF",
    borderRadius: "20px",
    height: "400px",
    paddingTop: 14,
    overflowY: "auto",
  },
};

const EmissionCategory = () => {
  const emissions = useSelector(getEmissionCategorySelector);

  useEffect(() => {
    getEmissionCategory();
  }, []);

  return (
    <Card style={styles.cardEmission}>
      <Row style={styles.container}>
        <Col className="container-emission" xs={24} sm={24} md={24} lg={12}>
          <Text style={styles.title}>Emission per Category</Text>
        </Col>
      </Row>
      {emissions &&
        emissions.resources &&
        emissions.resources.map((emission, i) => {
          return (
            emission.name !== "NULL" && (
              <Row key={i} style={styles.container} gutter={16}>
                <Col style={styles.containerName} span={12}>
                  <Text
                    style={styles.name}
                  >{`${emission.name} (${emission.quantity} ton CO2)`}</Text>
                </Col>
                <Col style={styles.containerProgress} span={12}>
                  <ProgressBar percentSecond={emission.percent} />
                </Col>
              </Row>
            )
          );
        })}
    </Card>
  );
};

export default EmissionCategory;
