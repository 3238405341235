import Response from "../../Data/Domain/Response";
import {
    getProjectKeys
} from "./Keys.service";
import Key from "../Domain/Key";

export async function getProjectKeysRepository(id) {
    const data = await getProjectKeys(id);
    // console.log("data", data);
    return new Response(data, Key).getMultiple();
  }