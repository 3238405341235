import API from "../../Data/Domain/API";
import URL from "../../Data/Domain/URL";

export async function login({ client_id, password }) {
  return await new API(URL.buildUrl("login")).post({
    client_id,
    password,
  });
}
export async function resetPassword({ email }) {
  return await new API(URL.buildUrl("passwords")).post({
    email,
  });
}
export async function finalizeResetPassword({ email, code, new_password }) {
  return await new API(URL.buildUrl(`users/${email}/passwords`)).put({
    code,
    new_password,
  });
}