import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "repositoryProjects",
  initialState: {
    list: [],
    count: 0,
    selected: null,
  },
  reducers: {
    setRepositoryProjects: (state, { payload: list }) => {
      return {
        ...state,
        list,
      };
    },

    countRepositoryProjects: (state, { payload: count }) => {
      return {
        ...state,
        count,
      };
    },
    selectRepositoryProject: (state, { payload: selected }) => {
      return {
        ...state,
        selected,
      };
    },
    deleteRepositoryProject: (state, { payload: id }) => {
      return {
        ...state,
        list: [...state.list.filter((elem) => elem.id !== id)],
      };
    },

  },
});

export const {
  setRepositoryProjects,
  countRepositoryProjects,
  selectRepositoryProject,
  deleteRepositoryProject

} = slice.actions;
export default slice.reducer;

export const getRepositoryProjectsSelector = (store) =>
  store.repositoryProjects.list;
export const getRepositoryProjectsCountSelector = (store) =>
  store.repositoryProjects.count;
export const getSelectedRepositoryProjectSelector = (store) =>
  store.repositoryProjects.selected;
export const deleteRepositoryProjectSelector = (store) =>
  store.repositoryProjects.id;
