import React, { useEffect, useState, useRef } from "react";
import { Card, Row, Col } from "antd";
import SearcherScriptOffsets from "./SearcherScriptOffsets";
import { getUsers } from "../../Users/Application/Users.business";
import ScriptOffsetsBoard from "./ScriptOffsetsBoard";
import Storage from "../../Data/Domain/Storage";
import { getScriptOffsetByUser } from "../Application/ScriptOffsets.business";
import ScriptOffset from "../../Users/Domain/User";
import RangePicker from "../../Layout/DesignSystem/Picker/Picker";
import moment from "moment";
import "./ScriptOffsets.css";

const ScriptOffsets = () => {

  const [userId, setUserId] = useState(null);
  const [values, setValues] = useState({
    id: "",
    start_date: null,
    finish_date: null,
  });

  const dates = useRef([]);

  const onDateChange = (changed_values) => {
    dates.current = !!changed_values
      ? changed_values.map((value) => {
          return moment(value).format("YYYY-MM-DD");
        })
      : [];
    setValues({
      ...values,
      start_date: dates.current[0],
      finish_date: dates.current[1],
    });
  };

  useEffect(() => {
    getUsers();
    if (userId) {
      getScriptOffsetByUser(userId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="grid-script">
      <div>
        <Card className="card-content-script">
          <div className="grid-scriptOffsets">
            <div className="grid-searcher-script">
              <SearcherScriptOffsets
                setId={setUserId}
              />
              <div className="picker-container">
                <RangePicker onChange={onDateChange} />
              </div>
            </div>
          </div>
          {userId && (
            <ScriptOffsetsBoard
              userId={userId}
              ScriptOffset={ScriptOffset}
              getScriptOffsetByUser={getScriptOffsetByUser}
              startDate={values.start_date}
              finishDate={values.finish_date}
            />
          )}
        </Card>
      </div>
    </div>
  );
};

export default ScriptOffsets;