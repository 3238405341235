import API from "../../Data/Domain/API";
import URL from "../../Data/Domain/URL";
import Storage from "../../Data/Domain/Storage";

export async function getCategories(params) {
  return await new API(URL.setParams(URL.buildUrl("categories"), params)).get();
}
export async function getSubcategoriesData(id) {
  return await new API(URL.buildUrl(`categories/${id}/subcategories`)).get();
}
export async function updateSubcategories(id, data) {
  const url = URL.setId(URL.buildUrl("subcategories"), id);
  return await new API(url).put(data);
}

export async function getSubcategory(id) {
  return await new API(URL.setId(URL.buildUrl("subcategories"), id)).get();
}

export async function postCategories({ name, scope }) {
  return await new API(URL.buildUrl("categories")).post({
    name,
    scope,
  });
}
export async function postSubcategories({ category, name }) {
  return await new API(URL.buildUrl("subcategories")).post({
    category,
    name,
  });
}

export async function getSubcategoriesByCategAndUser({ id_category }) {
  const id = new Storage("id").getItem();
  return await new API(
    URL.buildUrl(`users/${id}/categories/${id_category}/subcategories`)
  ).get();
}
export async function getCategoriesByUser(id) {
  const internal_id = id || new Storage("id").getItem();
  return await new API(URL.buildUrl(`users/${internal_id}/categories`)).get();
}