import React, { useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Button, message, Upload } from "antd";
import URL from "../../Data/Domain/URL";
import { getDocumentById } from "../Application/DocumentUpload.business";
import { getOffsets } from "../../DashboardOffset/Application/DashboardOffset.business";
import { getAreasByUser } from "../../Areas/Application/Areas.business";
import { getSuppliersByUser } from "../../Suppliers/Application/Suppliers.business";
import { getCategoriesByUser } from "../../Categories/Application/Categories.business";
import { getRepositoryProjectByUser } from "../../RepositoryProjects/Application/RepositoryProjects.business";

const styles = {
  disabled: {
    color: "gray",
    cursor: "not-allowed",
    backgroundColor: "#f0f0f0",
  },
};

export default function DocumentUpload(props) {
  const [values] = useState({
    ...props,
    name: "file",
    action: `${URL.URL_SERVER}/documents`,
    method: "POST",
  });
  const acceptedFileTypes = [".webp", ".svg", ".pdf", ".xlsx"];
  let id;

  const onChange = async (info) => {
    const { status, name, response = {} } = info.file;
    id = response.id;
    switch (true) {
      case status === "done":
        const document = await getDocumentById(id);
        message.success(`${name} ha sido cargado con éxito.`);
        refreshDataAfterFileUpload();
        props.updateImage();
        return (
          props.type === "profile_picture" && values.setProfile(document.url)
        );
      case status === "error":
        return message.error(`${name} no ha podido ser cargado.`);
      default:
        break;
    }
  };

  const handleFileUpload = (file) => {
    const fileExtension = file.name
      .substring(file.name.lastIndexOf("."))
      .toLowerCase();

    if (!acceptedFileTypes.includes(fileExtension)) {
      message.error(
        "Tipo de archivo no válido. Solo se permiten archivos con extensión svg y webp."
      );
      return Upload.LIST_IGNORE;
    }

    return true;
  };

  const refreshDataAfterFileUpload = () => {
    getOffsets(id);
    getAreasByUser(id);
    getSuppliersByUser(id);
    getCategoriesByUser(id);
    getRepositoryProjectByUser(id);
  };
  const isAcceptedType = [
    "main_image_project",
    "first_image_sensors",
    "second_image_sensors",
    "third_image_sensors",
    "carbon_management_file",
  ].includes(props.type);

  return (
    <>
      <Upload
        onChange={onChange}
        name={values.name}
        action={values.action}
        method={values.method}
        disabled={props.disabled}
        accept={isAcceptedType ? ".webp,.svg,.pdf,.xlsx" : null}
        beforeUpload={isAcceptedType ? handleFileUpload : null}
        data={{
          ...props,
          description: props.description,
        }}
      >
        <Button
          style={props.disabled ? styles.disabled : {}}
          icon={<UploadOutlined />}
        >
          {/* Seleccionar Archivo */}
          {props.buttonText}
        </Button>
      </Upload>
    </>
  );
}
