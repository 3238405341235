import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "offers",
  initialState: {
    list: [],
    count: 0,
    selected: null,
  },
  reducers: {
    setOffers: (state, { payload: list }) => {
      return {
        ...state,
        list,
      };
    },
    countOffers: (state, { payload: count }) => {
      return {
        ...state,
        count,
      };
    },
    selectOffer: (state, { payload: selected }) => {
      return {
        ...state,
        selected,
      };
    },
  },
});

export const { setOffers, countOffers, selectOffer } = slice.actions;

export default slice.reducer;

export const getOffersSelector = (store) => store.offers.list;
export const getOffersCountSelector = (store) => store.offers.count;
export const getSelectedOfferSelector = (store) => store.offers.selected;
