import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SiteLayout from "./Layout/SiteLayout";
import Login from "./Login/UI/Login";
import { Provider } from "react-redux";
import store from "./Redux/store";
import { Authorization } from "./Login/Application/Authorization";
import User from "./Users/Domain/User";
import Storage from "./Data/Domain/Storage";

const id = new Storage("id").getItem();

const DASHBOARD = Authorization([User.SUPERADMIN]);
const USERS = Authorization([User.SUPERADMIN, User.ADMIN, User.CARBON_CREDITS]);
const LOGS = Authorization([User.SUPERADMIN, User.ADMIN]);
const OFFERS = Authorization([
  User.SUPERADMIN,
  User.ADMIN,
  User.CARBON_CREDITS,
]);
const TRANSACTIONS = Authorization([
  User.SUPERADMIN,
  User.ADMIN,
  User.CARBON_CREDITS,
]);
const PROJECTS = Authorization([
  User.SUPERADMIN,
  User.ADMIN,
  User.PROJECTS_VIEWER,
  User.CARBON_CREDITS,
]);
const ADMIN = Authorization([
  User.SUPERADMIN,
  User.ADMIN,
  User.CARBON_MANAGEMENT,
  User.CARBON_CREDITS,
]);
const COMPANIES = Authorization([
  User.LEGAL,
  User.ADMIN,
  User.CARBON_MANAGEMENT,
  User.CARBON_CREDITS,
]);
const ERROR = Authorization([User.SUPERADMIN, User.ADMIN, User.LEGAL]);
const COMMISSIONS = Authorization([
  User.SUPERADMIN,
  User.ADMIN,
  User.LEGAL,
  User.CARBON_CREDITS,
]);
const CRM = Authorization([
  User.SUPERADMIN,
  User.ADMIN,
  User.LEGAL,
  User.CARBON_CREDITS,
]);
const CATEGORIES = Authorization([
  User.SUPERADMIN,
  User.ADMIN,
  User.LEGAL,
  User.CARBON_MANAGEMENT,
]);
const SCOPES = Authorization([User.SUPERADMIN, User.ADMIN, User.PARTNER]);
const DASHBOARDOFFSET = Authorization([
  User.SUPERADMIN,
  User.ADMIN,
  User.LEGAL,
  User.PARTNER,
]);
const TEST = Authorization([User.SUPERADMIN, User.ADMIN, User.LEGAL]);
const SAVING = Authorization([
  User.SUPERADMIN,
  User.ADMIN,
  User.LEGAL,
  User.PARTNER,
]);
const REPORTS = Authorization([
  User.SUPERADMIN,
  User.ADMIN,
  User.LEGAL,
  User.PARTNER,
]);
const OFFSET = Authorization([
  User.SUPERADMIN,
  User.ADMIN,
  User.LEGAL,
  User.PARTNER,
]);
const COLLABORATORS = Authorization([
  User.SUPERADMIN,
  User.ADMIN,
  User.LEGAL,
  User.PARTNER,
]);

const SUPPLIERS = Authorization([
  User.SUPERADMIN,
  User.ADMIN,
  User.CARBON_MANAGEMENT,
]);

const AREAS = Authorization([
  User.SUPERADMIN,
  User.ADMIN,
  User.CARBON_MANAGEMENT,
]);

const REPOSITORYPROJECTS = Authorization([
  User.SUPERADMIN,
  User.ADMIN,
  User.CARBON_MANAGEMENT,
]);
const MANAGEDATA = Authorization([
  User.ADMIN,
  User.SUPERADMIN,
  User.CARBON_MANAGEMENT,
]);
const FETCHDATA = Authorization([
  User.ADMIN,
  User.SUPERADMIN,
  User.CARBON_MANAGEMENT,
]);
const OWNERS = Authorization([
  User.OWNER,
  User.SUPERADMIN,
  User.ADMIN,
  User.PROJECT_OWNER,
]);
const IPFS = Authorization([
  User.OWNER,
  User.SUPERADMIN,
  User.ADMIN,
  User.PROJECT_OWNER,
]);
const PERMISSIONS = Authorization([User.OWNER, User.ADMIN]);
const FILES = Authorization([
  User.SUPERADMIN,
  User.ADMIN,
  User.CARBON_MANAGEMENT,
  User.PARTNER,
]);
const SCRIPTOFFSETS = Authorization([
  User.SUPERADMIN,
  User.ADMIN,
  User.CARBON_MANAGEMENT,
]);

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path={"/login"} element={<Login />} />
          <Route path={"/logout"} element={<Login logout />} />
          {/* <Route
            path={"/"}
            element={USERS(SiteLayout, { defaultPage: "error" })}
          /> */}
          <Route path={"/"} element={USERS(SiteLayout)} />
          <Route
            path={"/logs"}
            element={LOGS(SiteLayout, { defaultPage: "logs" })}
          />
          <Route
            path={"/offers"}
            element={OFFERS(SiteLayout, { defaultPage: "offers" })}
          />
          <Route
            path={"/transactions"}
            element={TRANSACTIONS(SiteLayout, { defaultPage: "transactions" })}
          />
          <Route
            path="/users"
            element={USERS(SiteLayout, { defaultPage: "users" })}
          />
          <Route
            path={"/users/:id"}
            element={USERS(SiteLayout, { defaultPage: "users" })}
          />
          <Route
            path={"/projects"}
            element={PROJECTS(SiteLayout, { defaultPage: "projects" })}
          />
          <Route
            path={"/projects/:id"}
            element={PROJECTS(SiteLayout, { defaultPage: "projects" })}
          />
          <Route
            path={"/dashboard"}
            element={DASHBOARD(SiteLayout, { defaultPage: "dashboard" })}
          />
          <Route
            path={"/administrator"}
            element={ADMIN(SiteLayout, { defaultPage: "administrator" })}
          />
          <Route
            path={"/administrator/:id"}
            element={ADMIN(SiteLayout, { defaultPage: "administrator" })}
          />
          <Route
            path={"/companies"}
            element={COMPANIES(SiteLayout, { defaultPage: "companies" })}
          />
          <Route
            path={"/companies/:id"}
            element={COMPANIES(SiteLayout, { defaultPage: "companies" })}
          />
          <Route
            path={"/error"}
            element={ERROR(SiteLayout, { defaultPage: "error" })}
          />
          <Route
            path={"/commissions"}
            element={COMMISSIONS(SiteLayout, { defaultPage: "commissions" })}
          />
          <Route
            path={"/crm"}
            element={CRM(SiteLayout, { defaultPage: "crm" })}
          />
          <Route
            path={"/categories"}
            element={CATEGORIES(SiteLayout, { defaultPage: "categories" })}
          />
          <Route
            path={"/test"}
            element={TEST(SiteLayout, { defaultPage: "test" })}
          />
          <Route
            path={"/scopes"}
            element={SCOPES(SiteLayout, { defaultPage: "scopes" })}
          />
          <Route
            path={"/dashboardOffset"}
            element={DASHBOARDOFFSET(SiteLayout, {
              defaultPage: "dashboardOffset",
            })}
          />
          <Route
            path={"/saving"}
            element={SAVING(SiteLayout, { defaultPage: "saving" })}
          />
          <Route
            path={"/reports"}
            element={REPORTS(SiteLayout, { defaultPage: "reports" })}
          />
          <Route
            path={"/offset"}
            element={OFFSET(SiteLayout, { defaultPage: "offset" })}
          />
          <Route
            path={"/collaborators"}
            element={COLLABORATORS(SiteLayout, {
              defaultPage: "collaborators",
            })}
          />
          <Route
            path={"/suppliers"}
            element={SUPPLIERS(SiteLayout, { defaultPage: "suppliers" })}
          />
          <Route
            path={"/areas"}
            element={AREAS(SiteLayout, { defaultPage: "areas" })}
          />
          <Route
            path={"/repositoryProjects"}
            element={REPOSITORYPROJECTS(SiteLayout, {
              defaultPage: "repositoryProjects",
            })}
          />
          <Route
            path={"/manageData"}
            element={MANAGEDATA(SiteLayout, { defaultPage: "manageData" })}
          />
          <Route
            path={`/owners/${id}/projects`}
            element={OWNERS(SiteLayout, { defaultPage: "owners" })}
          />
          <Route
            path="/projects/:recordId/ipfs"
            element={IPFS(SiteLayout, { defaultPage: "ipfs" })}
          />
          {/* <Route
            path={"/permissions"}
            element={PERMISSIONS(SiteLayout, { defaultPage: "permissions" })}
          /> */}
          <Route
            path={`/owners/${id}/permissions`}
            element={PERMISSIONS(SiteLayout, { defaultPage: "permissions" })}
          />
          <Route
            path={"/fetchData"}
            element={FETCHDATA(SiteLayout, { defaultPage: "fetchData" })}
          />
          <Route
            path="/projects/:recordId/generals"
            element={IPFS(SiteLayout, { defaultPage: "generals" })}
          />
          <Route
            path="/projects/:recordId/images"
            element={IPFS(SiteLayout, { defaultPage: "images" })}
          />
          <Route
            path="/files"
            element={FILES(SiteLayout, { defaultPage: "files" })}
          />
           <Route
            path="/projects/:recordId/transactions"
            element={IPFS(SiteLayout, { defaultPage: "transactionsProjects" })}
          />
            <Route
            path={"/scriptOffsets"}
            element={SCRIPTOFFSETS(SiteLayout, {
              defaultPage: "scriptOffsets",
            })}
          />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
