import Date from "../../Data/Domain/CustomDate";
import Formatter from "../../Data/Domain/Formatter";

class TransactionProject {
 
  static get NFT() {
    return "buy_nft";
  }

  static get SBT() {
    return "buy_sbt";
  }
    constructor({ _id, state,total_coins,
      client_coins, carbon_credit_invested,project_invested
      ,payment_amount,date,
      amount_usd,type,user,
      price,fee,project
     }) {
      this.id = _id;
      this.state = state;
      this.total_coins = total_coins;
      this.client_coins = client_coins;
      this.carbon_credit_invested = carbon_credit_invested;
      this.project_invested= project_invested;
      this.payment_amount= payment_amount;
      this.date = new Date(date).formatted_date;
      this.amount_usd=amount_usd;
      this.type=type;
      this.user=user;
      this.price=price;
      this.fee=fee;
      this.project=project;

    }
    get typeDescription() {
      switch (this.type) {
        case TransactionProject.NFT:
          return "Compra de créditos de Carbono";
        case TransactionProject.SBT:
          return "Offset de créditos de Carbono";
        default:
          return "N/A";
      }
    }
  }
  export default TransactionProject;