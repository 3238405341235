import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "categories",
  initialState: {
    list: [],
    count: 0,
    selected: null,
    subcategoriesList: [],
    subcategoriesCount: 0,
    selectedSubcategory: null,
    subcategoriesByCategoryList: [],
  },
  reducers: {
    setCategories: (state, { payload: list }) => {
      return {
        ...state,
        list,
      };
    },
    setSubcategories: (state, { payload: subcategoriesList }) => {
      return {
        ...state,
        subcategoriesList,
      };
    },
    countCategories: (state, { payload: count }) => {
      return {
        ...state,
        count,
      };
    },
    countSubcategories: (state, { payload: subcategoriesCount }) => {
      return {
        ...state,
        subcategoriesCount,
      };
    },
    selectCategory: (state, { payload: selected }) => {
      return {
        ...state,
        selected,
      };
    },
    selectSubcategory: (state, { payload: selectedSubcategory }) => {
      return {
        ...state,
        selectedSubcategory,
      };
    },
    setSubcategoriesByCateg: (
      state,
      { payload: subcategoriesByCategoryList }
    ) => {
      return {
        ...state,
        subcategoriesByCategoryList,
      };
    },
  },
});

export const {
  setCategories,
  setSubcategories,
  countCategories,
  countSubcategories,
  selectCategory,
  selectSubcategory,
  setSubcategoriesByCateg,
} = slice.actions;

export default slice.reducer;

export const getCategoriesSelector = (store) => store.categories.list;
export const getSubcategoriesSelector = (store) =>
  store.categories.subcategoriesList;
export const getSelectedCategorySelector = (store) => store.categories.selected;
export const getSelectedSubcategorySelector = (store) =>
  store.categories.selectedSubcategory;
export const getCategoriesCountSelector = (store) => store.categories.count;
export const getSubcategoriesCountSelector = (store) =>
  store.categories.countSubcategories();
export const getSubcategoriesByCategSelector = (store) =>
  store.categories.subcategoriesByCategoryList;
