class EmissionCategory {
  constructor({ id, _id, name, quantity, percent }) {
    this.id = id || _id;
    this.name = name;
    this.quantity = quantity;
    this.percent = percent;
  }
}

export default EmissionCategory;
