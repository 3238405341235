import React, { useEffect, useState, useRef } from "react";
import InterfaceTable from "../../Table/InterfaceTable";
import Searcher from "./SearcherFetchData";
import { getUsers } from "../../Users/Application/Users.business";
import Button from "../../Layout/DesignSystem/Button/Button";
import {
  getTechnology,
  deleteTechnology,
  deleteAllTechnology,
} from "../Application/FetchData.bussines";
import {
  Select,
  Modal as ModalAntd,
  message,
  Row,
  Col,
  Popover,
  Form,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import RangePicker from "../../Layout/DesignSystem/Picker/Picker";
import moment from "moment";

const FetchData = () => {
  const [form] = Form.useForm();
  const dates = useRef([]);
  const [values, setValues] = useState({
    id: "",
    technology: "",
  });
  const [technologies, setTechnologies] = useState(null);
  const [count, setCount] = useState(null);

  const columns = [
    {
      title: "Nombre del proveedor",
      dataIndex: "providerName",
      key: "providerName",
    },
    {
      title: "Nombre del documento",
      dataIndex: "documentName",
      key: "documentName",
    },
    {
      title: "Nombre del negocio",
      dataIndex: "businessName",
      key: "businessName",
    },
    {
      title: "Monto total",
      dataIndex: "amountTotal",
      key: "amountTotal",
    },
    {
      title: "Nombre de la cuenta",
      dataIndex: "accountName",
      key: "accountName",
    },
    {
      title: "Nombre del centro de negocios",
      dataIndex: "businessCenterName",
      key: "businessCenterName",
    },
    {
      title: "Acciones",
      dataIndex: "",
      key: "",
      render: (record) => {
        return (
          <>
            <DeleteOutlined
              onClick={() => {
                handleDelete(record.id);
              }}
              style={{ color: "red" }}
            />
          </>
        );
      },
    },
  ];

  useEffect(() => {
    getUsers();
  }, []);

  const onChange = (changed_values) => {
    console.log(changed_values);
    dates.current = !!changed_values
      ? changed_values.map((value) => {
          return moment(value).format("YYYY-MM-DD");
        })
      : [];
    console.log(dates.current);
    setValues({
      ...values,
      start_date: dates.current[0],
      finish_date: dates.current[1],
    });
  };

  const onClick = async () => {
    const request = {
      id: values.id,
      technology: values.technology,
      start_date: values.start_date,
      finish_date: values.finish_date,
    };
    const { resources, count } = await getTechnology(request);
    setTechnologies(resources);
  };

  const deleteAll = async () => {
    try {
      ModalAntd.confirm({
        title:
          "Esto eliminará toda la data de este proyecto en base de datos. ¿Deseas continuar?",
        okText: "Si",
        okType: "danger",
        onOk: async () => {
          try {
            console.log(values);
            const { id, technology } = values;
            deleteAllTechnology({ id, technology });
            //
            message.success("Se eliminaron todos los registros");
          } catch (error) {
            message.error("Se produjo un error, vuelva a intentarlo");
          }
        },
      });
    } catch (error) {
      message.error("Se produjo un error, vuelva a intentarlo");
    }
  };

  const onHandleChange = async (value) => {
    console.log(value);

    setValues({
      ...values,
      technology: value,
    });
    console.log(values);
  };

  const handleDelete = async (id) => {
    try {
      ModalAntd.confirm({
        title: "Está seguro de que desea eliminar este registro?",
        okText: "Si",
        okType: "danger",
        onOk: async () => {
          try {
            const { technology } = values;
            deleteTechnology({ id, technology });
            //
            message.success("Se eliminó el registro");
          } catch (error) {
            message.error("Se produjo un error, vuelva a intentarlo");
          }
        },
      });
    } catch (error) {
      message.error("Se produjo un error, vuelva a intentarlo");
    }
  };

  return (
    <>
      <Row>
        <Col xl={6}>
          <Searcher
            userId={values.id}
            setId={(id) => {
              setValues({ ...values, id });
            }}
          />
        </Col>
        <Col xl={6}>
          <Select
            className="select-searcher custom-select"
            placeholder="Selector tecnologia"
            clearIcon
            showSearch
            onChange={onHandleChange}
            options={[
              { value: "defontana", label: "Defontana" },
              { value: "bigQuery", label: "Google cloud" },
            ]}
          ></Select>
        </Col>
        <Col xl={4} mar>
          <RangePicker onChange={onChange} />
        </Col>
        <Col xl={4}>
          <Button text={"Obtener datos"} block={true} onClick={onClick} />
        </Col>
        <Col xl={4}>
          <Popover
            placement="bottom"
            content={
              <div>
                <p>
                  Esta acción eliminará toda la data asociada a este usuario en
                  base de datos con la tecnología seleccionada.
                </p>
                <p>
                  Esto no afectará a la información que ya se muestra en el
                  dashboard.
                </p>
              </div>
            }
            title=""
          >
            <span>
              <Button
                text={"Eliminar Base de Datos"}
                block={true}
                onClick={deleteAll}
              />
            </span>
          </Popover>
        </Col>
      </Row>

      <InterfaceTable
        // rowKey={(record) =>
        rowKey={(record) => record._id}
        data={technologies}
        columns={columns}
        table={"table-manage-data"}
      />
    </>
  );
};

export default FetchData;
