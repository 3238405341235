class Contact {
  constructor({ _id, name, mail, position, phone }) {
    this.id = _id;
    this.name = name;
    this.email = mail;
    this.position = position;
    this.phone = phone;
  }
}
export default Contact;
