import React from 'react';
import { DownOutlined } from "@ant-design/icons";
import UserReferenceField from "../Users/UI/UserReferenceField";
import Notification from "../Notification/Notification";
import ButtonElement from "../Layout/DesignSystem/Button/Button";
import "./SiteLayout.css";


const UserMenu = () => {

    return (
        <>
            <Notification
                notificacionStyle="userDetails"
                icon={<DownOutlined className={"downOutlined"} />}
                type="text"
                message={
                    <UserReferenceField
                        stylesUsername="usernameSecond"
                        occupationStyle="occupationSecond"
                        dashboard={true}
                        container={"containerSecond"}
                        containerText={"containerTextSecond"}
                        occupation={"Dev"}
                        dot
                        size={64}
                        children={
                            <div>
                                <div className="buttonContent">
                                    <span className="first-button">
                                        <ButtonElement
                                            type={"button-white-blue"}
                                            block
                                            text={"Edit"}
                                        />
                                    </span>
                                    <span className="second-button">
                                        <ButtonElement
                                            block
                                            text={"Remove"}
                                            type={"button-disabled"}
                                            disabled
                                        />
                                    </span>
                                </div>
                            </div>
                        }
                    />
                }
            // description={
            //     <div>
            //         <div className="buttonContent">
            //             <span className="first-button">
            //                 <ButtonElement
            //                     type={"button-white-blue"}
            //                     block
            //                     text={"Edit"}
            //                 />
            //             </span>
            //             <span className="second-button">
            //                 <ButtonElement
            //                     block
            //                     text={"Remove"}
            //                     type={"button-disabled"}
            //                     disabled
            //                 />
            //             </span>
            //         </div>
            //     </div>
            // }
            />
        </>
    )
}

export default UserMenu