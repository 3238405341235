import moment from "moment";
class CustomDate {
  get MONTHS() {
    return [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
  }

  constructor(date) {
    this.date = date;
    this.formatted_date = this.buildDate();
    this.formatDateForChart = this.format_date_for_chart();
    this.getYear = this.get_year();
  }

  buildDate() {
    const date = new Date(this.date);
    return `${this.get_day()} de ${this.getMonthName()} del ${date.getFullYear()}`;
  }

  format_date_for_chart() {
    let day = this.get_day();
    let month = this.get_month();
    let year = this.get_year();
    return `${day}/${month}/${year}`;
  }

  get_day() {
    const date = new Date(this.date);
    return Number(moment(date).utc().format("DD"));
  }
  get_year() {
    const date = new Date(this.date);
    return `${date.getUTCFullYear()}`;
  }
  get_month() {
    const date = new Date(this.date);
    return Number(moment(date).utc().format("MM"));
  }
  getMonthName() {
    const monthIndex = this.get_month() - 1;
    return this.MONTHS[monthIndex];
  }
}

export default CustomDate;
