import store from "../../Redux/store";
import {
  setCollaborators,
  countCollaborators,
  deleteCollaborator,
  revokeCollaborator,
} from "./Collaborators.reducer";

export function saveListCollaborators(records) {
  store.dispatch(setCollaborators(records));
}
export function saveCountCollaborators(count) {
  store.dispatch(countCollaborators(count));
}
export function deleteSelectedCollaborator(id) {
  store.dispatch(deleteCollaborator(id));
}
export function revokeSelectedCollaborator(id) {
  store.dispatch(revokeCollaborator(id));
}
export function saveListCollaboratorsByCompany(records) {
  store.dispatch(setCollaborators(records));
}
