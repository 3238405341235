class Subcategory {
  constructor({
    id,
    _id,
    category,
    subcategory,
    name,
    aliases,
    factor,
    unit,
    scope,
    internal_id,
    quantity,
    percent,
    parent,
    subcategory_info = null,
  }) {
    this.id = id || _id;
    this.category = category;
    this.subcategory = subcategory;
    this.name = name;
    this.aliases = aliases;
    this.factor = factor;
    this.unit = unit;
    this.scope = scope;
    this.internal_id = internal_id;
    this.quantity = quantity;
    this.parent = parent;
    this.percent = percent;
    this.subcategory_info = subcategory_info;
  }
}

export default Subcategory;
