import { deleteMonthRepository } from "../Infrastructure/ManageData.repository";

// import {
//   getManageDataRepository,
//   patchManageDataRepository,
// } from "../Infrastructure/ManageData.repository";

// export async function getManageDataLogic(id) {
//   return getManageDataRepository(id);
// }
// export async function patchManageDataLogic(id, params) {
//   return patchManageDataRepository(id, params);
// }

export async function deleteMonthLogic(id, id_month) {
  return deleteMonthRepository(id, id_month);
}
