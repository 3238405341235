import store from "../../Redux/store";
import {
  deleteUser,
  setUsers,
  selectUser,
  cancelSelectedUser,
  countUsers,
  countWithoutTransactions,
} from "./Users.reducer";

export function saveListUsers(records) {
  store.dispatch(setUsers(records));
}

export function saveSelectedUser(user) {
  store.dispatch(selectUser(user));
}

export function deselectUser() {
  store.dispatch(cancelSelectedUser());
}

export function saveCountUsers(count) {
  store.dispatch(countUsers(count));
}

export function saveCountWithoutTransactions(count_without_transactions) {
  store.dispatch(countWithoutTransactions(count_without_transactions));
}

export function deleteSelectedUser(id) {
  store.dispatch(deleteUser(id));
}
