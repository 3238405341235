import store from "../../Redux/store";
import {
  setCompanies,
  selectCompany,
  cancelSelectedCompany,
} from "./Companies.reducer";

export function saveListCompanies(records) {
  store.dispatch(setCompanies(records));
}

export function saveSelectedCompany(company) {
  store.dispatch(selectCompany(company));
}

export function deselectCompany() {
  store.dispatch(cancelSelectedCompany());
}
