import { Form, Input } from "antd";
import Modal from "../../Modal/Modal";

export default function OfferDetail({ record, visible, onCancel, onOk }) {
  return (
    <Modal
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      title="Detalle de Oferta"
      width={500}
      children={
        <Form
          name="basic"
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 14,
          }}
          initialValues={{
            remember: true,
          }}
          autoComplete="off"
        >
          {" "}
          {record ? (
            <>
              <Form.Item label="Monto">
                <Input value={record.amount} disabled />
              </Form.Item>
              <Form.Item label="Code">
                <Input value={record.code} disabled />
              </Form.Item>
              <Form.Item label="Tipo">
                <Input value={record.typeValue} disabled />
              </Form.Item>
              <Form.Item label="Status">
                <Input value={record.statusValue} disabled />
              </Form.Item>
              <Form.Item label="Fecha">
                <Input value={record.date} disabled />
              </Form.Item>
              <Form.Item label="Descripción">
                <Input value={record.description} disabled />
              </Form.Item>
              <Form.Item label="Fee">
                <Input value={record.fee} disabled />
              </Form.Item>
              <Form.Item label="Precio">
                <Input value={record.price} disabled />
              </Form.Item>
              <Form.Item label="Total">
                <Input value={record.total} disabled />
              </Form.Item>
            </>
          ) : (
            ""
          )}
        </Form>
      }
    />
  );
}
