import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "offsets",
  initialState: {
    offsets: {},
    selected: null,
  },
  reducers: {
    setOffsets: (state, { payload: offsets }) => {
      return {
        ...state,
        offsets,
      };
    },
    selectManageData: (state, { payload: selected }) => {
      return {
        ...state,
        selected,
      };
    },
  },
});

export const { setOffsets, selectManageData } = slice.actions;
export default slice.reducer;
export const getOffsetsSelector = (store) => store.offsets.offsets;
