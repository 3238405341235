import {
  getByCompanyRepository,
  getBankAccountsRepository,
  deleteBankAccountRepository,
  postBankAccountsRepository,
  getBankAccountRepository,
} from "../Infrastructure/CompaniesBankDetails.repository";

export async function getBankAccountsLogic(params) {
  return getBankAccountsRepository(params);
}

export async function getByCompanyLogic(id) {
  return getByCompanyRepository(id);
}
export async function deleteBankAccountLogic(id) {
  return deleteBankAccountRepository(id);
}
export async function postBankAccountsLogic(params) {
  return postBankAccountsRepository(params);
}
export async function getBankAccountLogic(id) {
  return getBankAccountRepository(id);
}
