import {
  saveListCompanies,
  saveSelectedCompany,
} from "../Infrastructure/Companies.store";
import { getCompanyLogic, getCompaniesLogic } from "./Companies.logic";

export async function getCompanies(params) {
  const companies = await getCompaniesLogic(params);
  saveListCompanies(companies);
}

export async function getCompany(id) {
  const company = await getCompanyLogic(id);
  saveSelectedCompany(company);
}
