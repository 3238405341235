import { useState, useEffect, useRef } from "react";
import { Col, Row, Form, Input, message, InputNumber, Select, DatePicker } from "antd";
import moment from "moment";
import { PlusCircleOutlined } from "@ant-design/icons";
import "./NewProject.css";
import ModalInterface from "../../Modal/Modal";
import { postProject } from "../Infrastructure/Projects.service";
import { getProjects } from "../Application/Projects.business";


const iconStyle = {
  fontSize: "32px",
};
const separatorStyle = {
  color: "#08c",
  marginLeft: "12px",
};

const NewProject = () => {
  const [form] = Form.useForm();
  const dates = useRef([]);

  const [values, setValues] = useState({
    name: "",
    is_private: false,
    country: "",
    co2Goal: 0,
    ton_price: "",
    add_on: "",
    technology: "",
    vintage: "",
    url: "",
    url_file: "",
    standard_id: "",
    area: "",
    biomass: "",
    co2_annual_estimated: "",
    contract_start_date: null,
    contract_end_date: null,
    description: "",
    green_project_type: "",
    region: "",
    volume: "",
    ton_co2_issued: "",
    province: ""
  });
  const [showPrivate, setShowPrivate] = useState(false);
  const [showPublic, setShowPublic] = useState(false);

  const onHandleChange = (input) => (e) => {
    let value;
    if (typeof e === 'string') {
      value = e;
    } else {
      value = e.target.value;
    }

    setValues({
      ...values,
      [input]: value,
    });
    dates.current = value;
  };

  useEffect(() => {
    setValues(values);
    getProjects();
  }, [values]);

  const handleChange = (value) => {
    if (value === "private") {
      setShowPrivate(true);
      setShowPublic(false);
    } else if (value === "public") {
      setShowPrivate(false);
      setShowPublic(true);
    }

    setValues({
      ...values,
      is_private: value === "private",
    });

    dates.current = !!values.contract_start_date && !!values.contract_end_date
      ? [moment(values.contract_start_date).format("YYYY-MM-DD"), moment(values.contract_end_date).format("YYYY-MM-DD")]
      : [];
  };

  async function handleSubmit() {
    const {
      name,
      is_private,
      country,
      co2Goal,
      ton_price,
      standard_id,
      add_on,
      technology,
      vintage,
      url,
      url_file,
      area,
      biomass,
      co2_annual_estimated,
      contract_start_date,
      contract_end_date,
      description,
      green_project_type,
      region,
      volume,
      ton_co2_issued,
      province
    } = values;

    try {
      let projectData = {};

      if (is_private) {
        projectData = {
          add_on,
          technology,
          vintage,
          url,
          url_file
        };
      } else {
        projectData = {
          area,
          biomass,
          co2_annual_estimated,
          contract_start_date,
          contract_end_date,
          description,
          green_project_type,
          region,
          volume,
          ton_co2_issued,
          province
        };
      }

      await postProject({
        name,
        is_private,
        country,
        co2Goal,
        ton_price,
        standard_id,
        ...projectData
      });

      setValues({ ...values, visible: false });
      getProjects();
      message.success("Se agregó un nuevo Proyecto");
    } catch (error) {
      message.error("Se produjo un error, vuelve a intentarlo");
    }
  }
  const onReset = () => {
    form.resetFields();
  };


  return (
    <div>
      <ModalInterface
        children={
          <div className="form-container">
            <Form
              layout="vertical"
              responsive={"true"}
              onFinish={handleSubmit}
              form={form}
              onReset={onReset}
              initialValues={{
                type: ""
              }}
            >
              <Row gutter={8}>
                <Col span={12}>
                  <Form.Item
                    label="Nombre:"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingrese su nombre",
                      },
                    ]}
                  >
                    <Input
                      name={"name"}
                      value={values.name}
                      onChange={onHandleChange("name")}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="standard_id:"
                    name="standard_id"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingrese su nombre",
                      },
                    ]}
                  >
                    <Input
                      name={"standard_id"}
                      value={values.standard_id}
                      onChange={onHandleChange("standard_id")}
                    />
                  </Form.Item>
                </Col>

              </Row>

              <Row gutter={8}>
                <Col span={12}>
                  <Form.Item
                    label="Tipo:"
                    name="type"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingrese un tipo",
                      },
                    ]}
                  >
                    <Select
                      style={{ width: "100%" }}
                      onChange={handleChange}
                      options={[
                        { value: "private", label: "Privado" },
                        { value: "public", label: "Público" },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="País:"
                    name="country"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingrese su país",
                      },
                    ]}
                  >
                    <Input
                      name={"country"}
                      value={values.country}
                      onChange={onHandleChange("country")}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={12}>
                  <Form.Item
                    label="Meta de CO2:"
                    name="co2Goal"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingrese una meta de CO2",
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder="co2Goal"
                      name={"co2Goal"}
                      style={{ width: "100%" }}
                      value={values.co2Goal}
                      onChange={onHandleChange("co2Goal")}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Precio por tonelada:"
                    name="ton_price"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingrese un precio por tonelada",
                      },
                    ]}
                  >
                    <Input
                      placeholder="ton_price"
                      name={"ton_price"}
                      value={values.ton_price}
                      onChange={onHandleChange("ton_price")}
                    />
                  </Form.Item>
                </Col>

              </Row>

              {showPrivate && (
                <>
                  <Row gutter={8}>
                    <Col span={12}>
                      <Form.Item
                        label="Adicional:"
                        name="add_on"
                        rules={[
                          {
                            required: true,
                            message: "Por favor ingrese un adicional",
                          },
                        ]}
                      >
                        <Input
                          placeholder="add_on"
                          name={"add_on"}
                          value={values.add_on}
                          onChange={onHandleChange("add_on")}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>

                      <Form.Item
                        label="Tecnología:"
                        name="technology"
                        rules={[
                          {
                            required: true,
                            message: "Por favor ingrese una tecnología",
                          },
                        ]}
                      >
                        <Input
                          name={"technology"}
                          value={values.technology}
                          onChange={onHandleChange("technology")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={8}>
                    <Col span={12}>
                      <Form.Item
                        label="Vintage:"
                        name="vintage"
                        rules={[
                          {
                            required: true,
                            message: "Por favor ingrese su vintage",
                          },
                        ]}
                      >
                        <Input
                          placeholder="vintage"
                          name={"vintage"}
                          value={values.vintage}
                          onChange={onHandleChange("vintage")}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>

                      <Form.Item
                        label="URL:"
                        name="url"
                        rules={[
                          {
                            required: true,
                            message: "Por favor ingrese una URL",
                          },
                          {
                            type: "url",
                            message: "La ulr no es valida",
                            warningOnly: true,
                          },
                        ]}
                      >
                        <Input
                          name={"url"}
                          value={values.url}
                          onChange={onHandleChange("url")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item
                    label="Archivo de URL:"
                    name="url_file"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingrese un archivo de URL",
                      },
                      {
                        type: "url",
                        message: "La ulr no es valida",
                        warningOnly: true,
                      },
                    ]}
                  >
                    <Input
                      name={"url_file"}
                      value={values.url_file}
                      onChange={onHandleChange("url_file")}
                    />
                  </Form.Item>
                </>

              )}
              {showPublic && (
                <>
                  <Row gutter={8}>
                    <Col span={12}>
                      <Form.Item
                        label="Área:"
                        name="area"
                        rules={[
                          {
                            required: true,
                            message: "Por favor ingrese el área",
                          },
                        ]}
                      >
                        <Input
                          name={"area"}
                          value={values.area}
                          onChange={onHandleChange("area")}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Biomasa :"
                        name="biomass"
                        rules={[
                          {
                            required: true,
                            message: "Por favor ingrese su biomasa",
                          },
                        ]}
                      >
                        <Input
                          placeholder="biomass"
                          name={"biomass"}
                          value={values.biomass}
                          onChange={onHandleChange("biomass")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={8}>
                    <Col span={12}>
                      <Form.Item
                        label="Estimación Anual de CO2:"
                        name="co2_annual_estimated"
                        rules={[
                          {
                            required: true,
                            message: "Por favor ingrese una estimación anual de CO2",
                          },
                        ]}
                      >
                        <Input
                          placeholder="co2_annual_estimated"
                          name={"co2_annual_estimated"}
                          value={values.co2_annual_estimated}
                          onChange={onHandleChange("co2_annual_estimated")}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Fecha inicial del contrato :"
                        name="contract_start_date"
                        rules={[
                          {
                            required: true,
                            message: "Por favor ingrese una fecha de inicio del contrato",
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          name={"contract_start_date"}
                          value={values.contract_start_date ? moment(values.contract_start_date) : null}
                          onChange={(date, dateString) => onHandleChange('contract_start_date')(dateString)}
                        />

                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={8}>
                    <Col span={12}>
                      <Form.Item
                        label="Fecha final del contrato:"
                        name="contract_end_date"
                        rules={[
                          {
                            required: true,
                            message: "Por favor ingrese una fecha de finalización del contrato",
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          name={"contract_end_date"}
                          value={values.contract_end_date ? moment(values.contract_end_date) : null}
                          onChange={(date, dateString) => onHandleChange('contract_end_date')(dateString)}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Descripción :"
                        name="description"
                        rules={[
                          {
                            required: true,
                            message: "Por favor ingrese una descripción",
                          },
                        ]}
                      >
                        <Input
                          name={"description"}
                          value={values.description}
                          onChange={onHandleChange("description")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={8}>
                    <Col span={12}>
                      <Form.Item
                        label="Tipo de proyecto:"
                        name="green_project_type"
                        rules={[
                          {
                            required: true,
                            message: "Por favor ingrese un tipo de proyecto",
                          },
                        ]}
                      >
                        <Input
                          name={"green_project_type"}
                          value={values.green_project_type}
                          onChange={onHandleChange("green_project_type")}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Región :"
                        name="region"
                        rules={[
                          {
                            required: true,
                            message: "Por favor ingrese una región",
                          },
                        ]}
                      >
                        <Input
                          name={"region"}
                          value={values.region}
                          onChange={onHandleChange("region")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={8}>
                    <Col span={12}>
                      <Form.Item
                        label="Volumen:"
                        name="volume"
                        rules={[
                          {
                            required: true,
                            message: "Por favor ingrese un volumen",
                          },
                        ]}
                      >
                        <Input
                          name={"volume"}
                          value={values.volume}
                          onChange={onHandleChange("volume")}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Toneladas de CO2 emitidas :"
                        name="ton_co2_issued"
                        rules={[
                          {
                            required: true,
                            message: "Por favor ingrese su toneladas de CO2 emitidas",
                          },
                        ]}
                      >
                        <Input
                          name={"ton_co2_issued"}
                          value={values.ton_co2_issued}
                          onChange={onHandleChange("ton_co2_issued")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={8}>
                    <Col span={12}>
                      <Form.Item
                        label="Provincia:"
                        name="province"
                        rules={[
                          {
                            required: true,
                            message: "Por favor ingrese una provincia",
                          },
                        ]}
                      >
                        <Input
                          name={"province"}
                          value={values.province}
                          onChange={onHandleChange("province")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>

              )}

            </Form>
          </div>
        }
        icon={<PlusCircleOutlined style={iconStyle} />}
        style={separatorStyle}
        title="Nombre del proyecto"
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        text="Agregar"
        handleSubmit={handleSubmit}
        form={form}
      />
    </div>
  );
};

export default NewProject;
