import Response from "../../Data/Domain/Response";
import FetchData from "../Domain/FetchData";
import { getTechnology, deleteTechnology, deleteAllTechnology } from "./FetchData.service";

export async function getTechnologyRepository(params) {
  const data = await getTechnology(params);
  return new Response(data, FetchData).getMultiple();
}

export async function deleteTechnologyRepository(params) {
  const data = await deleteTechnology(params);
  return new Response(data, FetchData).getSingle();
}

export async function deleteAllTechnologyRepository(params) {
  const data = await deleteAllTechnology(params);
  return new Response(data, FetchData).getSingle();
}
