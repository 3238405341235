import Date from "../../Data/Domain/CustomDate";
import Formatter from "../../Data/Domain/Formatter";

class Offer {
  static get BUY() {
    return "BUY";
  }

  static get SELL() {
    return "SELL";
  }

  static get STATUS_PENDING() {
    return "pending";
  }

  static get STATUS_OPEN() {
    return "open";
  }

  static get STATUS_DONE() {
    return "done";
  }

  static get STATUS_CLOSE() {
    return "close";
  }

  constructor({
    _id,
    amount,
    code,
    date,
    description,
    fee,
    price,
    status,
    total,
    total_without_fee,
    type,
    user,
  }) {
    this.id = _id;
    this.amount = amount;
    this.code = code;
    this.date = new Date(date).formatted_date;
    this.description = description;
    this.fee = fee;
    this.price = price;
    this.status = status;
    this.total = total;
    this.total_without_fee = total_without_fee;
    this.type = type;
    this.user = user;
  }
  get typeValue() {
    switch (this.type) {
      case Offer.BUY:
        return "COMPRA";
      case Offer.SELL:
        return "VENTA";
      default:
        return "N/A";
    }
  }
  get statusValue() {
    switch (this.status) {
      case Offer.STATUS_PENDING:
        return "PENDIENTE";
      case Offer.STATUS_OPEN:
        return "ABIERTO";
      case Offer.STATUS_DONE:
        return "HECHO";
      case Offer.STATUS_CLOSE:
        return "CERRADO";
      default:
        return "N/A";
    }
  }
  get calculateAmount() {
    return `${Formatter.round(this.amount)}`;
  }
  get calculateTotal() {
    return `${Formatter.round(this.total)}`;
  }
  get calculateFee() {
    return `${Formatter.round(this.fee)}`;
  }
  get calculatePrice() {
    return `${Formatter.round(this.price)}`;
  }
}
export default Offer;
