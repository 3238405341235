import store from "../../Redux/store";
import {
  setPermissions,
  countPermissions,
  selectPermission
} from "./Permissions.reducer";

export function saveListPermissions(records) {
  store.dispatch(setPermissions(records));
}
export function saveCountPermissions(count) {
  store.dispatch(countPermissions(count));
}
export function saveSelectedPermission(permission) {
  store.dispatch(selectPermission(permission));
}
