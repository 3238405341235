import CustomDate from "../../Data/Domain/CustomDate";

class Document {
  static get PROFILE_PICTURE() {
    return "profile_picture";
  }

  static get MAIN_IMAGE_PROJECT() {
    return "main_image_project";
  }
  static get FIRST_IMAGE_SENSORS() {
    return "first_image_sensors";
  }

  static get SECOND_IMAGE_SENSORS() {
    return "second_image_sensors";
  }

  static get THIRD_IMAGE_SENSORS() {
    return "third_image_sensors";
  }

  static get IMAGE_PROJECT() {
    return "image_project";
  }

  static get COMPANY_ID() {
    return "company_id";
  }

  static get LOCAL_TAX_AUTHORITY() {
    return "local_tax_authority";
  }

  static get REGISTRATION() {
    return "registration";
  }

  static get LEGAL_REPRESENTATION() {
    return "legal_representation";
  }

  static get LAST_STATEMENT() {
    return "last_statement";
  }

  static get PERSONAL_ID() {
    return "personal_id";
  }

  static get BANK_ACCOUNT_DETAILS() {
    return "bank_account_details";
  }
  static get CARBON_MANAGEMENT_FILE() {
    return "carbon_management_file";
  }
  static get SCRIPT_OFSSET() {
    return "script_offset";
  }
  constructor({
    user,
    type,
    url,
    status,
    _id,
    id,
    was_approved,
    description,
    created_at,
    email,
    username,
  }) {
    this.user = user;
    this.type = type;
    this.url = url;
    this.status = status;
    this.description = description;
    this.created_at = created_at;
    this.was_approved = was_approved;
    this.key = _id || id;
    this.id = _id || id;
    this.email = email;
    this.username = username;
  }

  get formatDate() {
    return (date) => new CustomDate(date).formatted_date;
  }

  get documentType() {
    switch (this.type) {
      case Document.COMPANY_ID:
        return "ID de Compañía";
      case Document.LOCAL_TAX_AUTHORITY:
        return "Inicio de actividades";
      case Document.REGISTRATION:
        return "Acta constitutiva";
      case Document.LEGAL_REPRESENTATION:
        return "Documento legal que acredita la representación legal";
      case Document.LAST_STATEMENT:
        return "Certificado de vigencia de Sociedad";
      case Document.PERSONAL_ID:
        return "Cédula de identidad de representante legal";
      case Document.BANK_ACCOUNT_DETAILS:
        return "Datos de cuenta bancaria";
      case Document.PROFILE_PICTURE:
        return "Foto de perfil";
      case Document.CARBON_MANAGEMENT_FILE:
        return "Archivo de gestión de carbono";
      default:
        return "No hay tipo";
    }
  }

  get documentStatus() {
    switch (this.status) {
      case "pending":
        return "Pendiente";
      case "rejected":
        return "Rechazado";
      case "verified":
        return "Aprobado";
      default:
        return "No iniciado";
    }
  }

  get usuario() {
    return `${this.username} / ${this.email}`;
  }
}

export default Document;
