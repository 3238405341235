import {
  getByCompanyRepository,
  deleteDocumentRepository,
  PostDocumentRepository,
  getDocumentByIdRepository,
  getByUserRepository,getAllRepository
} from "../Infrastructure/DocumentUpload.repository";

export async function getDocumentByIdLogic(id) {
  return getDocumentByIdRepository(id);
}

export async function PostDocumentLogic(params) {
  return PostDocumentRepository(params);
}

export async function getByCompanyLogic(id) {
  return getByCompanyRepository(id);
}

export async function deleteDocumentLogic(id) {
  return deleteDocumentRepository(id);
}
export async function getByUserLogic(id, params) {
  return getByUserRepository(id, params);
}

export async function getAllLogic(type,params) {
  return getAllRepository(type,params);
}