import React from "react";
import { Card, Col, Row, Typography } from "antd";
import "./RepositoryProjects.css";
import NewRepositoryProject from "./NewRepositoryProject";
import RepositoryProjectBoard from "./RepositoryProjectBoard";

const { Text } = Typography;

const styles = {
  text: {
    fontFamily: "MontserratBold",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "24px",
    color: "#041370",
  },
};

const RepositoryProjects = () => {
  return (
    <div>
      <div className="grid-emission">
        <div>
          <Card className="card-content">
            <Row>
              <Col
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 20,
                }}
                span={24}
              >
                <Text style={styles.text}>Repositorio de Proyectos</Text>
                <NewRepositoryProject />
              </Col>
            </Row>
            <RepositoryProjectBoard />
          </Card>
        </div>
      </div>
    </div>
  );
};

export default RepositoryProjects;
