import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Form, Input, message, Row, Col, Select } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import Modal from "../../Modal/Modal";
import { getCategories } from "../Application/Categories.business";
import { postCategories } from "../Infrastructure/Categories.service";

const CategoriesDetail = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [values, setValues] = useState({
    name: "",
  });

  useEffect(() => {
    getCategories();
  }, [values]);

  const onHandleChange = (input) => (e) => {
    setValues({
      ...values,
      [input]: e.target.value,
    });
  };

  const handleSelectorChange = (value: string) => {
    console.log(`selected ${value}`);
    setValues({
      ...values,
      scope: value,
    });
  };

  const handleSubmit = async () => {
    const { name, scope } = values;
    try {
      await postCategories({
        name,
        scope,
      });
      setValues({ ...values });
      message.success("Se agregó un nueva Categoría");
    } catch (error) {
      message.error(" Se produjo un error, vuelve a intentarlo");
    }
    form.resetFields();
    dispatch(getCategories());
  };

  return (
    <>
      <Modal
        children={
          <Form layout="vertical" onFinish={handleSubmit} form={form}>
            <Row gutter={15}>
              <Col xs={24} xl={24} span={12}>
                <Select
                  label={"Scope"}
                  style={{ width: "100%" }}
                  options={[
                    { value: "scope 1", label: "Scope 1" },
                    { value: "scope 2", label: "Scope 2" },
                    { value: "scope 3", label: "Scope 3" },
                  ]}
                  onChange={handleSelectorChange}
                />
              </Col>
              <Col xs={24} xl={24} span={12}>
                <Form.Item
                  label="Nombre"
                  colon={false}
                  name="name"
                  rules={[
                    {
                      required: "true",
                      message: "Nombre no válido",
                      pattern: new RegExp(
                        /^[a-zA-Z@~`!@#$%^&*()_=+\\\\';:"\\/?>.<,-, ]+$/i
                      ),
                    },
                  ]}
                  hasFeedback
                >
                  <Input
                    placeholder=""
                    value={values.name}
                    onChange={onHandleChange("name")}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24} type="flex" align="right" />
            </Row>
          </Form>
        }
        icon={<PlusCircleOutlined style={{ fontSize: "32px" }} />}
        style={{
          color: "#08c",
          marginLeft: "12px",
        }}
        title="Agregar Categoría"
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        text="Agregar"
        handleSubmit={handleSubmit}
        form={form}
      />
    </>
  );
};

export default CategoriesDetail;
