import React, { useEffect, useState } from "react";
import { Form, InputNumber, message, Modal } from "antd";
import Button from "../../Layout/DesignSystem/Button/Button";
import Searcher from "../../ManageData/UI/SearcherManageData";
import { postReduction } from "../../Saving/Application/Saving.business";

const styles = {
  container: {
    width: "100%",
  },
  input: {
    width: "100%",
    minWidth: 400,
  },
};
export default function SuppliersAssociation({ form, record }) {
  const [loading, setLoading] = useState(false);
  const [nameSupplier, setNameSupplier] = useState(record.name);
  const [id, setId] = useState(null);
  const [tons, setTons] = useState(null);
  const [enabledButton, setEnabledButton] = useState(false);


  useEffect(() => {
    setEnabledButton(id && tons);
  }, [id, tons]);

  const onHandleChange = () => (value) => {
    setTons(value);
  };

  const handleUpdate = () => {
    setLoading(true);
    Modal.confirm({
      title: "Está seguro de asociar este supplier?",
      okText: "Si",
      okType: "danger",
      onOk: async () => {
        try {
          await postReduction({
            parent: record.id,
            quantity: tons,
            type: "supplier",
            id_user: id,
            up: true,
            percent: 100,
          });
          message.success("Supplier asociado con éxito");
          setLoading(false);
          form.resetFields([nameSupplier]);
        } catch (error) {
          message.error("Error al asociar el Supplier");
          setLoading(false);
          form.resetFields([nameSupplier]);
        }
      },
      onCancel: () => { setLoading(false); },
    });
  };
  const onReset = () => {
    form.resetFields();
  };
  return (
    <Form
      name="basic"
      labelCol={{
        span: 4,
      }}
      wrapperCol={{
        span: 20,
      }}
      initialValues={{
        nameSupplier: nameSupplier,
        remember: false,
      }}
      autoComplete="off"
      form={form}
      onReset={onReset}
    >
      {record ? (
        <>
          <div className="manageData">
            <div className="grid-Association">
              <div>
                <Searcher
                  userId={id}
                  setId={(id) => {
                    setId(id);
                  }}
                />
              </div>
            </div>
          </div>

          <Form.Item style={styles.container} label={"Toneladas"}>
            <Form.Item>
              <InputNumber
                  style={styles.input}
                  placeholder="tons"
                  name={"tons"}
                  min={1}
                  // style={{ width: "100%" }}
                  value={tons}
                  onChange={onHandleChange("tons")}
              />
            </Form.Item>

            <Form.Item>
              {loading ? (
                <Button loading={loading} text={"Cargando"} />
              ) : (
                <Button
                  onClick={handleUpdate}
                  disabled={!enabledButton}
                  text={"Agregar"}
                />
              )}
            </Form.Item>
          </Form.Item>
        </>
      ) : (
        ""
      )}
    </Form>
  );
}
