import API from "../../Data/Domain/API";
import URL from "../../Data/Domain/URL";

export async function getCompanies(params) {
  return await new API(URL.setParams(URL.buildUrl("companies"), params)).get();
}

export async function getCompany(id) {
  return await new API(URL.setId(URL.buildUrl("companies"), id)).get();
}

export async function postCompany({ name, email, lastname, type = "company" }) {
  return await new API(URL.buildUrl("users")).post({
    name,
    email,
    lastname,
    type,
  });
}

export async function updateCompany(id, data) {
  const url = URL.setId(URL.buildUrl("companies"), id);
  return await new API(url).put(data);
}
