import Response from "../../Data/Domain/Response";
import Reduction from "../Domain/Saving";

import {
  getReductions,
  deleteReduction,
  getReduction,
  postReduction,
} from "./Saving.service";

export async function getReductionsRepository(params) {
  const data = await getReductions(params);
  return new Response(data, Reduction).getMultiple();
}
export async function deleteReductionRepository(id) {
  const data = await deleteReduction(id);
  return new Response(data).onlyStatus();
}
export async function getReductionRepository(id) {
  const data = await getReduction(id);
  return new Response(data, Reduction).getSingle();
}

export async function postReductionRepository(params) {
  const data = await postReduction(params);
  return new Response(data, Reduction).getMultiple().resources;
}
