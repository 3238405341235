import API from "../../Data/Domain/API";
import URL from "../../Data/Domain/URL";

export async function getSubcategories(params) {
  return await new API(
    URL.setParams(URL.buildUrl("subcategories"), params)
  ).get();
}

export async function getSubcategoryById(id) {
  return await new API(URL.setId(URL.buildUrl("subcategories"), id)).get();
}
