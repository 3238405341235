import React, { useState, useEffect, useRef } from "react";
import { Progress, Tooltip } from "antd";
import "./ProgressBar.css";

const ProgressBar = (props) => {
  const [percent, setPercent] = useState({ ...props.percent });
  const [percentSecond, setPercentSecond] = useState({
    ...props.percentSecond,
  });

  const nodeProgress = useRef(
    <Progress
      percent={props.percent}
      showInfo={false}
      className="my-progress-bar"
    />
  );

  const porcentaje = useRef(props.percent);

  useEffect(() => {
    setPercent(props.percent);
    setPercentSecond(props.percentSecond);
  }, [props.percent, props.percentSecond]);
  const styles = {
    containerQuantity: {
      width: "68%",
    },
  };
  return (
    <>
      {percent ? (
        <Tooltip title={`${percent}% en progreso`}>
          <div>{nodeProgress.current}</div>
        </Tooltip>
      ) : (
        <Tooltip title={`${percentSecond}% en progreso`}>
          <Progress
            style={styles.containerQuantity}
            // className="root-quantity"
            percent={props.percentSecond}
            showInfo={false}
            trailColor={"#F4F4F4"}
            strokeColor={"#2953E8"}
            strokeWidth={15}
            size="small"
          />
        </Tooltip>
      )}
    </>
  );
};

export default ProgressBar;
