import { useEffect } from "react";
import { Col, Layout, Row } from "antd";
import { getTransactions } from "../Application/Transactions.business";
import TransactionsBoard from "./TransactionsBoard";

const { Content } = Layout;

export default function Transactions() {
  useEffect(() => {
    getTransactions();
  }, []);

  return (
    <Content
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Row
        justify="space-between"
        style={{
          width: "100%",
        }}
      >
        <Col span={24}>
          <Row>
            <Col span={24}>
              <TransactionsBoard />
            </Col>
          </Row>
        </Col>
      </Row>
    </Content>
  );
}
