import {createSlice} from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "collaborators",
  initialState: {
    list: [],
    count: 1,
    selected: null,
  },
  reducers: {
    setCollaborators: (state, {payload: list}) => {
      return {
        ...state,
        list,
      };
    },
    /*     revokeCollaborator: (state, {payload: list}) => {
      return {
        ...state,
        list,
      };
    }, */

    countCollaborators: (state, {payload: count}) => {
      return {
        ...state,
        count,
      };
    },
    selectCollaborator: (state, {payload: selected}) => {
      return {
        ...state,
        selected,
      };
    },
    revokeCollaborator: (state, {payload: selected}) => {
      return {
        ...state,
        selected,
      };
    },
    deleteCollaborator: (state, {payload: id}) => {
      return {
        ...state,
        list: [...state.list.filter((elem) => elem.id !== id)],
      };
    },
  },
});

export const {
  setCollaborators,
  countCollaborators,
  selectCollaborator,
  deleteCollaborator,
  revokeCollaborator,
} = slice.actions;
export default slice.reducer;

export const getsetCollaboratorsSelector = (store) => store.collaborators.list;
export const getCollaboratorsCountSelector = (store) =>
  store.collaborators.count;
export const getSelectedCollaboratorSelector = (store) =>
  store.collaborators.selected;
export const deleteCollaboratorSelector = (store) => store.collaborators.id;
export const revokeCollaboratorSelector = (store) => store.collaborators.list;
