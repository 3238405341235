import {
  saveListCollaborators,
  saveCountCollaborators,
  deleteSelectedCollaborator,
  saveListCollaboratorsByCompany,
  revokeSelectedCollaborator,
} from "../Infrastructure/Collaborators.store";
import {
  getByCompanyLogic,
  getCollaboratorsLogic,
  deleteCollaboratorLogic,
  revokeCollaboratorLogic,
} from "./Collaborators.logic";

export async function getCollaborators(params) {
  const {count, resources: collaborators} = await getCollaboratorsLogic(params);
  saveListCollaborators(collaborators);
  saveCountCollaborators(count);
}

export async function getByCompany() {
  const collaborators = await getByCompanyLogic();
  saveListCollaboratorsByCompany(collaborators);
}

export async function deleteCollaborator(id) {
  await deleteCollaboratorLogic(id);
  deleteSelectedCollaborator(id);
}
export async function revokeCollaborator(id, params) {
  await revokeCollaboratorLogic(id, params);
  revokeSelectedCollaborator(id);
}
