import Response from "../../Data/Domain/Response";
import Permission from "../Domain/Permission";
import {
  getPermissions,
  getPermission,
  updatePermissionKey,
  getPermissionsByOwner,
} from "./Permissions.service";

export async function getPermissionsRepository(params) {
  const data = await getPermissions(params);
  return new Response(data, Permission).getMultiple();
}
export async function getPermissionsByOwnerRepository(params) {
  const data = await getPermissionsByOwner(params);
  return new Response(data, Permission).getMultiple();
}
export async function getPermissionRepository(id) {
  const data = await getPermission(id);
  return new Response(data, Permission).getSingle();
}
export async function updatePermissionRepository(id, params) {
  const data = await updatePermissionKey(id, params);
  return new Response(data, Permission).onlyStatus();
}
// export async function getPermissionRepository(id) {
//   const data = await getPermission(id);
//   return new Response(data, Permission).getMultiple();
// }
