import {
  saveListOffsets,
  saveSelectedManageData,
} from "../Infrastructure/DashboardOffset.store";
import { getOffsetsLogic, patchManageDataLogic } from "./DashboardOffset.logic";
import Storage from "../../Data/Domain/Storage";
export async function getOffsets(id) {
  const internal_id = new Storage("id").getItem();
  const offsets = await getOffsetsLogic(id || internal_id);
  saveListOffsets(offsets);
}
export async function patchManageData(id, params) {
  await patchManageDataLogic(id, params);
  const offset = await getOffsetsLogic(id);
  saveSelectedManageData(offset);
}
