import API from "../../Data/Domain/API";
import URL from "../../Data/Domain/URL";
import Storage from "../../Data/Domain/Storage";

export async function getReductions(params) {
  return await new API(URL.setParams(URL.buildUrl("reductions"), params)).get();
}
export async function deleteReduction(id) {
  return await new API(URL.setId(URL.buildUrl("reductions"), id)).delete();
}

export async function updateSaving(id, data, params = null) {
  let url = URL.setId(URL.buildUrl("reductions"), id);
  if (params) url += params;
  console.log({ url });
  return await new API(url).put(data);
}
// export async function getReduction(id, data, params = null) {
//   let url = URL.setId(URL.buildUrl("reductions"), id);
//   if (params) url += params;
//   console.log({ url });
//   return await new API(url).get();

export async function getReduction(id) {
  return await new API(URL.setId(URL.buildUrl("reductions"), id)).get();
}

export async function postReduction(reduction) {
  return await new API(URL.buildUrl(`dataUsers`)).post(reduction);
}

export async function removeCollaborator(id, id_collaborator) {
  return await new API(
    URL.buildUrl(`reductions/${id}/collaborators/${id_collaborator}`)
  ).delete();
}
