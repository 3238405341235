import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DocumentUpload from "../../DocumentUpload/UI/DocumentUpload";
import InterfaceTable from "../../Table/InterfaceTable";
import NewManageData from "./NewManageData";
import NewMonthsManageData from "./NewMonthsManageData";
import NewScopeManageData from "./NewScopeManageData";
import Searcher from "./SearcherManageData";
import { getOffsets } from "../../DashboardOffset/Application/DashboardOffset.business";
import { getOffsetsSelector } from "../../DashboardOffset/Infrastructure/DashboardOffset.reducer";
import { getUser, getUsers } from "../../Users/Application/Users.business";
import { DeleteOutlined } from "@ant-design/icons";
import { Tooltip, message, Modal as ModalAntd } from "antd";
import { deleteMonth } from "../Application/ManageData.business";
import SuppliersByUser from "./SuppliersByUser";
import AreasByUser from "./AreasByUser";
import CategoriesByUser from "./CategoriesByUser";
import RepositoryProjectsByUser from "./RepositoryProjectsByUser";
import Document from "../../DocumentUpload/Domain/Document";

const flex = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const columns = [
  {
    title: "Año",
    dataIndex: "year",
    key: "year",
  },
  {
    title: "Emisiones",
    dataIndex: "emission",
    key: "emission",
  },
];

const columnsEmissions = [
  {
    title: "Scope",
    dataIndex: "scope",
    key: "scope",
  },
  {
    title: "Emisiones",
    dataIndex: "emissions",
    key: "emissions",
  },
  {
    title: "Porcentaje",
    dataIndex: "percent",
    key: "percent",
  },
];

const ManageData = () => {
  const [id, setId] = useState(null);
  const offsets = useSelector(getOffsetsSelector);
  const [emissionPerYearData, setEmissionPerYearData] = useState([]);
  const [scopesData, setScopesData] = useState([]);
  console.log(JSON.stringify(scopesData));
  const [emissionPerDateData, setEmissionPerDateData] = useState([]);
  const [categoriesEmissionData, setCategoriesEmissionData] = useState([]);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    getUsers();
    if (id) {
      getUser(id);
      getOffsets(id);
      setDisabled(false);
    }
  }, [id]);

  useEffect(() => {
    if (offsets && offsets.emission_per_year) {
      setEmissionPerYearData(offsets.formatterYearForTable());
    }
    if (offsets && offsets.scopes) {
      setScopesData(offsets.scopes_for_table());
    }
    if (offsets && offsets.emission_per_month) {
      setEmissionPerDateData(offsets.formattedEmissionForTable());
    }
    if (offsets && offsets.data && offsets.data.categories_emission) {
      setCategoriesEmissionData(offsets.data.categories_emission);
    }
  }, [offsets]);

  const handleDelete = async (id_month) => {
    try {
      ModalAntd.confirm({
        title: "Está seguro de que desea eliminar el mes?",
        okText: "Si",
        okType: "danger",
        onOk: async () => {
          try {
            await deleteMonth(id, id_month);
            getOffsets(id);
            message.success("Se eliminó el mes");
          } catch (error) {
            message.error("Se produjo un error, vuelva a intentarlo");
          }
        },
      });
    } catch (error) {
      message.error("Se produjo un error, vuelva a intentarlo");
    }
  };

  const columnsMonthsEmissions = [
    {
      title: "Fecha",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Emisiones",
      dataIndex: "tons",
      key: "tons",
    },
    {
      title: "Acciones",
      dataIndex: "",
      key: "",
      render: (_, record) => {
        return (
          <>
            <div style={flex}>
              <div>
                <Tooltip title="Eliminar un mes">
                  <DeleteOutlined
                    onClick={() => {
                      handleDelete(record._id);
                    }}
                    style={{ color: "red" }}
                  />
                </Tooltip>
              </div>
            </div>
          </>
        );
      },
    },
  ];
  return (
    <div className="manageData">
      <div className="grid-NewManageData">
        <div>
          <Searcher
            userId={id}
            setId={(id) => {
              setId(id);
            }}
          />
        </div>
        <div>
          <DocumentUpload
            type={Document.SCRIPT_OFSSET}
            id={id}
            disabled={disabled}
            buttonText="Seleccionar Archivo "
          />
        </div>
      </div>
      <NewManageData userId={id} />
      <InterfaceTable
        // rowKey={(record) =>
        rowKey={(record) => record._id}
        data={emissionPerYearData}
        columns={columns}
        table={"table-manage-data"}
      />
      <NewScopeManageData userId={id} />
      <InterfaceTable
        rowKey={(record) => record.id}
        data={scopesData}
        columns={columnsEmissions}
        table={"table-manage-data"}
      />
      <NewMonthsManageData userId={id} />
      <InterfaceTable
        rowKey={(record) => record._id}
        data={emissionPerDateData}
        columns={columnsMonthsEmissions}
        table={"table-manage-data"}
      />
      <SuppliersByUser userId={id} />
      <AreasByUser userId={id} />
      <CategoriesByUser userId={id} />
      <RepositoryProjectsByUser userId={id} />
    </div>
  );
};

export default ManageData;
