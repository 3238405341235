import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Formatter from "../../Data/Domain/Formatter";
import {
  Card,
  Col,
  Row,
  Typography,
  message,
  Tooltip,
  Modal as ModalAntd,
} from "antd";
import InterfaceTable from "../../Table/InterfaceTable";
import NewIPFSPage from "./NewIPFSPage";
import "./Owners.css";
import { getProjectIpfs } from "../../Projects/Application/Projects.business";
import { getIpfsSelector } from "../../Projects/Infrastructure/Projects.reducer";
import { DeleteOutlined } from "@ant-design/icons";
import { deleteFileIpfs } from "../../Projects/Application/Projects.business";

const { Text } = Typography;

const IPFSPage = ({ recordId }) => {
  const ipfsFile = useSelector(getIpfsSelector);
  // console.log(JSON.stringify(ipfsFile));
  // console.log("recordId" + recordId);

  const [values, setValues] = useState({
    page: 0,
    limit: 5,
  });
  const [loading, setLoading] = useState(false);

  const [filters, setFilters] = useState("false");

  const handleDelete = async (recordId, type) => {
    try {
      ModalAntd.confirm({
        title: "¿Está seguro de que desea eliminar una carpeta?",
        okText: "Si",
        okType: "danger",
        onOk: async () => {
          try {
            console.log("Data enviada:", recordId, type);
            await deleteFileIpfs(recordId, type);
            message.success("Se eliminó la carpet");
          } catch (error) {
            message.error("Se produjo un error, vuelva a intentarlo");
          }
        },
      });
    } catch (error) {
      message.error("Se produjo un error, vuelva a intentarlo");
    }
  };
  const columns = [
    {
      title: "Nombre de la carpeta",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Tipo",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Hash",
      dataIndex: "hash",
      key: "hash",
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "",
      render: (_, record) => {
        console.log(JSON.stringify(record.type));
        return (
          <>
            <div className="delete-ipfs">
              <div>
                <Tooltip title="Eliminar la carpeta">
                  <DeleteOutlined
                    onClick={() => {
                      handleDelete(recordId, record.type);
                    }}
                    style={{ color: "red" }}
                  />
                </Tooltip>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const fetchData = async (params = {}) => {
    try {
      await getProjectIpfs(recordId);
      const filtros = params.filter ? JSON.stringify(params.filter) : [""];
      setFilters(filtros);
      if (Object.entries(params).length > 0) message.success("Filtrado");
      setLoading(false);
      message.success("Filtrado");
    } catch (error) {
      message.error("error");
    }
  };

  useEffect(() => {
    getProjectIpfs(recordId);
    fetchData({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRequest = (newPagination = {}, filters = {}, sorter = {}) => {
    const { current: page = 1, pageSize: limit = 5 } = newPagination;
    const { field: sortField, order: sortOrder } = sorter;
    const clean = Formatter.cleanParamsFromTableToAPI({
      ...filters,
      limit,
      sortField,
      sortOrder,
      page: page - 1,
      dates: [],
    });
    setValues({
      ...values,
      limit,
      page: page - 1,
    });
    fetchData(clean);
  };

  return (
    <div className="grid-owners">
      <div>
        <Card className="card-owners">
          <Row className="owners-title">
            <Col className="my-projects" span={24}>
              <Text className="text-my-projects">Mis Carpetas</Text>
              <NewIPFSPage recordId={recordId} />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InterfaceTable
                data={ipfsFile.resources}
                columns={columns}
                onChange={handleRequest}
                pagination={{
                  pageSize: values.limit,
                  onChange: (current) => {},
                }}
              />
            </Col>
          </Row>
        </Card>
      </div>
    </div>
  );
};

export default IPFSPage;
