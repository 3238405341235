import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Radio, Row, Col } from "antd";
import Category from "../DesignSystem/Category/Category";
import "./Categories.css";
import CategorySection from "../DesignSystem/CategorySection/CategorySection";
import { getCategoriesSelector } from "../../Categories/Infrastructure/Categories.reducer";
import { getCategories } from "../../Categories/Application/Categories.business";

const Categories = ({ stream }) => {
  let records = useSelector(getCategoriesSelector);
  const [active, setActive] = useState("");

  useEffect(() => {
    if (records.length > 0) {
      setActive(records[0].name);
    }
  }, [records]);

  useEffect(() => {
    async function fetchData() {
      await getCategories({
        filter: { scope: "scope 3", [`${stream}`]: true },
      });
    }
    fetchData();
  }, [stream]);

  const onChange = ({ target: { value } }) => {
    setActive(value);
  };

  return (
    <div>
      <Row gutter={5} className="categories-container">
        <Col span={24}>
          <Radio.Group value={active} onChange={onChange} buttonStyle="solid">
            {records.map(({ name, id }) => {
              return <Category key={id} id={id} title={name} />;
            })}
          </Radio.Group>
        </Col>
      </Row>

      {records.map(({ id, name }) => {
        return (
          <div key={id}>
            {active === name && (
              <CategorySection key={id} id={id} title={name} />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Categories;
