import { useState } from "react";
import { Form, Input, Button, message } from "antd";
import ProjectReferenceField from "../../Projects/UI/ProjectReferenceField";
import { patchTransaction } from "../Application/Transactions.business";
import UserReferenceField from "../../Users/UI/UserReferenceField";
import Modal from "../../Modal/Modal";
import "./Transactions.css";

export default function TransactionDetail({
  record,
  visible,
  onClose,
  onCancel,
}) {
  const [loading, setLoading] = useState(false);

  const handleOk = () => {
    setLoading(true);
    patchTransaction(record.id)
      .then(() => {
        setLoading(false);
        message.success("Transacción cerrada con éxito");
      })
      .catch(() => {
        setLoading(false);
        message.error("Error cerrando transacción");
      });
  };


  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title="Transacciones"
      width={500}
      footer={[
        <Button key="back" onClick={onClose}>
          Volver
        </Button>,
        <>
          {record.isPending && (
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={handleOk}
            >
              Finalizar
            </Button>
          )}
          ,
        </>,
      ]}
      children={
        <Form
          name="basic"
          initialValues={{
            remember: true,
          }}
          autoComplete="off"
        >
          {" "}
          {record ? (
            <>
              <Form.Item label="Usuario">
                <a href={`/users/${record.id_user}`}>
                  <UserReferenceField
                    container="containerTransaction"
                    id={record.id_user}
                    dropdown={<></>}
                  />
                </a>
              </Form.Item>
              <Form.Item label="Tipo">
                <Input value={record.typeValue} disabled />
              </Form.Item>
              <Form.Item label="Metodo de Pago">
                <Input value={record.paymentMethodValue} disabled />
              </Form.Item>
              <Form.Item label="Estado">
                <Input value={record.stateValue} disabled />
              </Form.Item>
              <Form.Item label="Fee">
                <Input value={record.calculateFee} disabled />
              </Form.Item>
              <Form.Item label="Pago">
                <Input value={record.calculatePayment} disabled />
              </Form.Item>
              {record.isBonds() && (
                <>
                  <Form.Item label="Total de monedas">
                    <Input value={record.total_coins} disabled />
                  </Form.Item>
                  <Form.Item label="Credito de carbon invertido">
                    <Input value={record.carbon_credit_invested} disabled />
                  </Form.Item>
                  <Form.Item label="Monto Usd">
                    <Input value={record.amount_usd} disabled />
                  </Form.Item>
                  <Form.Item label="Proyecto">
                    <ProjectReferenceField id={record.project_id} />
                  </Form.Item>
                </>
              )}
              {record.isUSD() && (
                <>
                  <Form.Item label="Monto Usd">
                    <Input value={record.amount_usd} disabled />
                  </Form.Item>
                </>
              )}
            </>
          ) : (
            ""
          )}
        </Form>
      }
    />
  );
}
