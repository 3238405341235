import store from "../../Redux/store";
import {
  countReductions,
  setReductions,
  deleteReduction,
  selectReduction,
  addReduction,
} from "./Saving.reducer";

export function saveListReductions(records) {
  store.dispatch(setReductions(records));
}

export function saveCountReductions(count) {
  store.dispatch(countReductions(count));
}

export function deleteSelectedReduction(id) {
  store.dispatch(deleteReduction(id));
}

export function saveSelectedReduction(reduction) {
  store.dispatch(selectReduction(reduction));
}

export function savePostListReduction(records) {
  store.dispatch(addReduction(records));
}
