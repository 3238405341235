import { createSlice } from "@reduxjs/toolkit";

const getRolesLocalStorage = () => {
  const rolesData = localStorage.getItem("rol");
  return rolesData ? rolesData : null;
};

export const slice = createSlice({
  name: "users",
  initialState: {
    list: [],
    selected: null,
    count: 0,
    count_without_transactions: 0,
    roles: getRolesLocalStorage(),
  },
  reducers: {
    setUsers: (state, { payload: list }) => {
      return {
        ...state,
        list,
      };
    },
    countUsers: (state, { payload: count }) => {
      return {
        ...state,
        count,
      };
    },
    selectUser: (state, { payload: selected }) => {
      return {
        ...state,
        selected,
      };
    },
    cancelSelectedUser: (state) => {
      return {
        ...state,
        selected: null,
      };
    },
    countWithoutTransactions: (
      state,
      { payload: count_without_transactions }
    ) => {
      return {
        ...state,
        count_without_transactions,
      };
    },
    deleteUser: (state, { payload: id }) => {
      return {
        ...state,
        list: [...state.list.filter((elem) => elem.id !== id)],
      };
    },
    setLocalRoles: (state, action) => {
      state.roles = action.payload;
    },
  },
});

export const {
  setUsers,
  countUsers,
  selectUser,
  cancelSelectedUser,
  countWithoutTransactions,
  deleteUser,
  setLocalRoles
} = slice.actions;

export default slice.reducer;

export const getUsersSelector = (store) => store.users.list;
export const getSelectedUserSelector = (store) => store.users.selected;
export const getUsersCountSelector = (store) => store.users.count;
export const getcountWithoutTransactionsSelector = (store) =>
  store.users.count_without_transactions;
export const deleteUserSelector = (store) => store.users.id;
