import { useState } from "react";
import { Form, Input, message } from "antd";

import { PlusCircleOutlined } from "@ant-design/icons";
import "./Owners.css";
import Modal from "../../Modal/Modal";

const NewOwners = () => {
  const [form] = Form.useForm();

  const [values, setValues] = useState({
    name: "",
  });

  const onHandleChange = (input) => (e) => {
    setValues({
      ...values,
      [input]: e.target.value,
    });
  };
  const handleSubmit = () => {
    console.log("Nombre agregado:", values.name);
  };

  return (
    <div>
      <Modal
        children={
          <div className="form-owners">
            <Form layout="vertical" responsive={"true"} form={form}>
              <Form.Item
                label="Nombre :"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese su nombre",
                  },
                ]}
              >
                <Input
                  name={"name"}
                  value={values.name}
                  onChange={onHandleChange("name")}
                />
              </Form.Item>
            </Form>
          </div>
        }
        icon={<PlusCircleOutlined className="icon-style" />}
        className="icon-style"
        title="Nombre del propietario del proyecto"
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        handleSubmit={handleSubmit}
        text="Agregar"
        form={form}
      />
    </div>
  );
};

export default NewOwners;
