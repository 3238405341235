import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Col, Form, Row, Select } from "antd";
import { getCompaniesSelector } from "../Infrastructure/Companies.reducer";
import { getCompanies, getCompany } from "../Application/Companies.business";
import Button from "../../Layout/DesignSystem/Button/Button";

const { Option } = Select;

const styles = {
  container: {},
  background: {
    margin: "0.7em 0em",
    padding: "1em",
    background: "#fafafa",
    position: "relative",
    color: "rgba(0, 0, 0, 0.85)",
    fontWeight: 500,
    textAlign: "left",
    paddingTop: 30,
  },
  select: {
    width: 450,
    maxWidth: "100%",
    minWidth: 280,
  },
};

export default function Searcher() {
  const records = useSelector(getCompaniesSelector);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const onFinish = async ({ id }) => {
    getCompany(id);
    navigate(`/companies/${id}`);
  };

  const onReset = () => {
    form.resetFields();
  };

  function onSearch(val) {
    getCompanies({ filter: { q: val } });
  }

  return (
    <div style={styles.container}>
      <Row>
        <Col span={24}>
          <div style={styles.background}>
            <Form
              layout="horizontal"
              form={form}
              onFinish={onFinish}
              onReset={onReset}
            >
              <Row gutter={10}>
                <Col xs={24} xl={12}>
                  <Form.Item name={"id"} label="Compañia">
                    <Select
                      style={styles.select}
                      clearIcon
                      showSearch
                      onSearch={onSearch}
                      placeholder="Selecciona una compañia"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {records?.map((record) => (
                        <Option value={record.id} key={record.id}>
                          {record.companyNameForSearcher}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} xl={2}>
                  <Form.Item colon={false}>
                    <Button htmlType="submit" text={"Seleccionar"} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
}
