import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "documents",
  initialState: {
    list: [],
    count: 0,
    selected: null,
  },
  reducers: {
    setDocuments: (state, { payload: list }) => {
      return {
        ...state,
        list,
      };
    },
    countDocuments: (state, { payload: count }) => {
      return {
        ...state,
        count,
      };
    },
    selectDocument: (state, { payload: selected }) => {
      return {
        ...state,
        selected,
      };
    },
    deleteDocument: (state, { payload: id }) => {
      return {
        ...state,
        list: [...state.list.filter((elem) => elem.id !== id)],
      };
    },
  },
});

export const { setDocuments, countDocuments, selectDocument, deleteDocument } =
  slice.actions;

export default slice.reducer;

export const getDocumentsSelector = (store) => store.documents.list;
export const getDocumentsCountSelector = (store) => store.documents.count;
export const getSelectedDocumentSelector = (store) => store.documents.selected;
export const deleteDocumentSelector = (store) => store.documents.id;
