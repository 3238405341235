import React from "react";
import { Card, Typography, Row, Col } from "antd";
import "antd/dist/antd.min.css";
import "./InfoCard.css";

const { Text } = Typography;

const InfoCard = ({ statistic }) => {
  return (
    <Card
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      bodyStyle={{ paddingLeft: "7px", paddingRight: "0" }}
      className={statistic.type ? [statistic.type] : ["white-card"]}
    >
      <Row>
        <Col>
          <div className="value-container">
            <Text className="value-text">{statistic.value}</Text>
            <Text className="unit-text">
              {statistic.unit ? statistic.unit : <></>}
            </Text>
          </div>
          <Text className="description-text">{statistic.title}</Text>
        </Col>
      </Row>
    </Card>
  );
};

export default InfoCard;
