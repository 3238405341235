import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "transactions",
  initialState: {
    list: [],
    count: 0,
    total_transactions: 0,
    total_usd: 0,
    selected: null,
  },
  reducers: {
    setTransactions: (state, { payload: list }) => {
      return {
        ...state,
        list,
      };
    },
    addTransaction: (state, { payload: list }) => {
      return {
        ...state,
        list: [...state.list, list],
      };
    },
    countTransactions: (state, { payload: count }) => {
      return {
        ...state,
        count,
      };
    },
    selectTransaction: (state, { payload: selected }) => {
      return {
        ...state,
        selected,
      };
    },
    totalTransactions: (state, { payload: total_transactions }) => {
      return {
        ...state,
        total_transactions,
      };
    },
    totalUsd: (state, { payload: total_usd }) => {
      return {
        ...state,
        total_usd,
      };
    },
  },
});

export const {
  setTransactions,
  countTransactions,
  selectTransaction,
  addTransaction,
  totalTransactions,
  totalUsd,
} = slice.actions;

export default slice.reducer;

export const getTransactionsSelector = (store) => store.transactions.list;
export const getTransactionsCountSelector = (store) => store.transactions.count;
export const getSelectedTransactionSelector = (store) =>
  store.transactions.selected;
export const postTransactionSelector = (store) => store.transactions.list;
export const getTotalTransactionsSelector = (store) =>
  store.transactions.total_transactions;
export const getTotalUsdSelector = (store) => store.transactions.total_usd;
