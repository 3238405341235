import {
  getTransactionsRepository,
  getTransactionRepository,
  patchTransactionRepository,
  postTransactionRepository,
} from "../Infrastructure/Transactions.repository";

export async function getTransactionsLogic(params) {
  return getTransactionsRepository(params);
}

export async function getTransactionLogic(id) {
  return getTransactionRepository(id);
}

export async function patchTransactionLogic(id) {
  return patchTransactionRepository(id);
}

export async function postTransactionLogic(params) {
  return postTransactionRepository(params);
}
