import Response from "../../Data/Domain/Response";
import Offset from "../Domain/DashboardOffset";
import {
  getOffsets,
  patchManageData
} from "./DashboardOffset.service";

export async function getOffsetsRepository(id) {
  const data = await getOffsets(id);
  return new Response(data, Offset).getSingle();
}

export async function patchManageDataRepository(id, params) {
  const data = await patchManageData(id, params);
  return new Response(data, Offset).onlyStatus();
}
