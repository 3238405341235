import { useState } from "react";
import { useDispatch } from "react-redux";
import { Form, Input, message } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import "./Areas";
import ModalInterface from "../../Modal/Modal";
import { postAreas } from "../Infrastructure/Areas.service";
import { getAreas } from "../Application/Areas.business";
import Storage from "../../Data/Domain/Storage";

const iconStyle = {
  color: "green",
  fontSize: "24px",
};
const separatorStyle = {
  marginLeft: "4px",
};
const NewAreas = () => {
  const [form] = Form.useForm();

  const [values, setValues] = useState({
    name: "",
    company: new Storage("id").getItem(),
  });
  const dispatch = useDispatch();

  const onHandleChange = (input) => (e) => {
    setValues({
      ...values,
      [input]: e.target.value,
    });
  };

  async function handleSubmit() {
    const { name } = values;
    try {
      await postAreas({
        name,
      });
      setValues({ ...values, visible: false });
      message.success("Se agregó una nueva area");
    } catch (error) {
      message.error(" Se produjo un error, vuelve a intentarlo");
    }
    dispatch(getAreas());
  }

  const onReset = () => {
    form.resetFields();
  };

  return (
    <div>
      <ModalInterface
        children={
          <div className="form-container">
            <Form
              layout="vertical"
              responsive={"true"}
              onFinish={handleSubmit}
              form={form}
              onReset={onReset}
            >
              <Form.Item
                label="Nombre :"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese su nombre",
                  },
                ]}
              >
                <Input
                  name={"name"}
                  value={values.name}
                  onChange={onHandleChange("name")}
                />
              </Form.Item>
            </Form>
          </div>
        }
        icon={<PlusCircleOutlined style={iconStyle} />}
        style={separatorStyle}
        title="Nombre del área"
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        text="Agregar"
        handleSubmit={handleSubmit}
        form={form}
      />
    </div>
  );
};

export default NewAreas;
