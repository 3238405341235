import { useSelector } from "react-redux";
import { useEffect } from "react";
import { Col, Layout, Row } from "antd";
import { getOffers } from "../Application/Offers.bussines";
import { getOffersSelector } from "../Infrastructure/Offers.reducer";
import OffersBoard from "./OffersBoard";

const { Content } = Layout;

export default function Offers() {
  const offers = useSelector(getOffersSelector);

  useEffect(() => {
    getOffers();
  }, []);

  return (
    <Content
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Row
        justify="space-between"
        style={{
          width: "100%",
        }}
      >
        <Col span={24}>
          <Row>
            <Col span={24}>
              <OffersBoard data={offers} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Content>
  );
}
