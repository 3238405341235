import API from "../../Data/Domain/API";
import URL from "../../Data/Domain/URL";
import Storage from "../../Data/Domain/Storage";

export async function getPermissions(params) {
  return await new API(
    URL.setParams(URL.buildUrl("permissions"), params)
  ).get();
}
export async function getPermissionsByOwner(params) {
  const internal_id = new Storage("id").getItem();
  return await new API(URL.buildUrl(`owners/${internal_id}/permissions`)).get();
}

export async function updatePermissionKey(id, data) {
  return await new API(URL.buildUrl(`permissions/${id}/keys/${data.key}`)).put({
    access: data.access,
  });
}

// export async function getPermission(id) {
//   return await new API(URL.setId(URL.buildUrl("permissions"), id)).get();
// }
export async function getPermission(id) {
  const internal_id = id || new Storage("id").getItem();
  return await new API(URL.buildUrl(`owners/${internal_id}/permissions`)).get();
}
