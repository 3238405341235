import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "reductions",
  initialState: {
    list: [],
    count: 1,
    selected: null,
  },
  reducers: {
    setReductions: (state, { payload: list }) => {
      return {
        ...state,
        list,
      };
    },
    countReductions: (state, { payload: count }) => {
      return {
        ...state,
        count,
      };
    },
    deleteReduction: (state, { payload: id }) => {
      return {
        ...state,
        list: [...state.list.filter((elem) => elem.id !== id)],
      };
    },
    selectReduction: (state, { payload: selected }) => {
      return {
        ...state,
        selected,
      };
    },
    addReduction: (state, { payload: list }) => {
      return {
        ...state,
        list: [...state.list, list],
      };
    },
  },
});

export const {
  setReductions,
  countReductions,
  deleteReduction,
  selectReduction,
  addReduction,
} = slice.actions;

export default slice.reducer;

export const getReductionsSelector = (store) => store.reductions.list;
export const getReductionsCountSelector = (store) => store.reductions.count;
export const deleteReductionSelector = (store) => store.reductions.id;
export const getSelectedReductionSelector = (store) =>
  store.reductions.selected;
export const postReductionSelector = (store) => store.reductions.list;
