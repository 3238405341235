import Formatter from "../../Data/Domain/Formatter";
import Contact from "./Contact";

class Project {
  constructor({
    _id,
    project_name,
    country,
    latitude,
    longitude,
    ton_price,
    images,
    co2Goal,
    real_name,
    comercial_name,
    contacts = [],
    hash,
    publicKey,
    secretKey,
    is_private,
    standard_id,
    add_on,
    technology,
    vintage,
    url,
    url_file,
    enabled,
    area,
    biomass,
    co2_annual_estimated,
    contract_start_date,
    contract_end_date,
    description,
    green_project_type,
    region,
    volume,
    ton_co2_issued,
    province,
    data = {},
    info = {},
    impacts=[],
    sdgs=[]
  }) {
    this.id = _id;
    this.project_name = this.projectName(project_name);
    this.country = this.countryValue(country);
    this.latitude = latitude;
    this.longitude = longitude;
    this.ton_price = ton_price;
    this.images = images;
    this.co2Goal = co2Goal;
    this.real_name = real_name;
    this.comercial_name = comercial_name;
    // this.contacts = contacts;
    // Todo: Llamar al método mencionado abajo
    this.contacts = this.setContacts(contacts);
    this.hash = hash;
    this.publicKey = publicKey;
    this.secretKey = secretKey;
    this.is_private = is_private;
    this.standard_id = standard_id;
    this.add_on = add_on;
    this.technology = technology;
    this.vintage = vintage;
    this.url = url;
    this.url_file = url_file;
    this.enabled = enabled;
    this.area = area;
    this.biomass = biomass;
    this.co2_annual_estimated = co2_annual_estimated;
    this.contract_start_date = contract_start_date;
    this.contract_end_date = contract_end_date;
    this.description = description;
    this.green_project_type = this.greenProjectTypeValue(green_project_type);
    this.region = region;
    this.volume = volume;
    this.ton_co2_issued = this.tonCo2Issued(ton_co2_issued);
    this.province = province;
    this.data = data;
    this.info = info;
    this.impacts = impacts;
    this.sdgs = sdgs;

  }

  /* Todo: un método que basado en los contactos de la BD convierta a la clase Contact
    Recorrer contacts y por cada posición: new Contact(contact)
  */
  setContacts(contacts) {
    return contacts.map((contact) => new Contact(contact));
  }
  get mainImage() {
    const [
      main_image = {
        url: "https://cdn.impactinit.com/resizenp/600x600/x@7c62feb0ce/smss53/smsimg27/pv1000/isignstockcontributors/iss_2756_00053.jpg",
      },
    ] = this.images;
    return main_image.url;
  }

  get projectNameForSearcher() {
    return this.project_name;
  }

  get tonPrice() {
    return `${Formatter.round(this.ton_price / 100)} USD`;
  }

  get statusValue() {
    return this.enabled === true ? "Activo" : "Desactivado";
  }

  get privateValue() {
    return this.is_private === true ? "Privado" : "Publico";
  }

  get infos() {
    const { info } = this.info;
    return info;
  }

  set availableBonds(available_bonds) {
    this.data = available_bonds;
  }

  projectName(project_name) {
    return project_name.toUpperCase();
  }

  countryValue(country) {
    return country;
  }

  greenProjectTypeValue(green_project_type) {
    return green_project_type;
  }

  tonCo2Issued(ton_co2_issued) {
    return ton_co2_issued;
  }

  dataAvailableBonds() {
    return this.data.available_bonds;
  }
  toUpdate() {
    return { project_name: this.project_name };
  }

  get availableBonds() {
    return this.is_private ? this.co2Goal : this.dataAvailableBonds();
  }
}
export default Project;
