import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "dataUsers",
  initialState: {
    areas: [],
    offsets: [],
    suppliers: [],
    categories: [],
    repositoryProjects: [],
  },

  reducers: {
    setAreasByUser: (state, { payload: areas }) => {
      return {
        ...state,
        areas,
      };
    },
    setOffsets: (state, { payload: offsets }) => {
      return {
        ...state,
        offsets,
      };
    },
    setSuppliersByUser: (state, { payload: suppliers }) => {
      return {
        ...state,
        suppliers,
      };
    },
    setCategoriesByUser: (state, { payload: categories }) => {
      return {
        ...state,
        categories,
      };
    },
    setRepositoryProjectsByUser: (state, { payload: repositoryProjects }) => {
      return {
        ...state,
        repositoryProjects,
      };
    },
  },
});

export const {
  setAreasByUser,
  setOffsets,
  setSuppliersByUser,
  setCategoriesByUser,
  setRepositoryProjectsByUser,
} = slice.actions;

export default slice.reducer;

export const getAreasByUserSelector = (store) => store.dataUsers.areas;
export const getOffsetsSelector = (store) => store.dataUsers.offsets;
export const getSuppliersByUserSelector = (store) => store.dataUsers.suppliers;
export const getCategoriesByUserSelector = (store) =>
  store.dataUsers.categories;
export const getRepositoryProjectsByUserSelector = (store) =>
  store.dataUsers.repositoryProjects;
