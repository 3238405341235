import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Col, Row, Table, Form, Tooltip, message } from "antd";
import Formatter from "../../Data/Domain/Formatter";
import { Title } from "../../Utils/UI/Text";
import {
  FileImageOutlined,
  PlusSquareOutlined,
  GlobalOutlined,
  ProfileOutlined,
  LinkOutlined,
  FileWordOutlined,
  ContactsOutlined,
  EnvironmentOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  getProjectsCountSelector,
  getProjectsSelector,
} from "../Infrastructure/Projects.reducer";
import { getProjects, getProject } from "../Application/Projects.business";
import ProjectDetail from "./ProjectDetail";
import ProjectUploadImage from "./ProjectUploadImage";
import ProjectLocation from "./ProjectLocation";
import ProjectIPFS from "./ProjectIPFS";
import Modal from "../../Modal/Modal";
import ProjectsTranslations from "./ProjectsTranslations";
import URL from "../../Data/Domain/URL";
import NewProject from "./NewProject";
import NewContact from "./NewContact";
import ProjectEdit from "./ProjectEdit";
import ExportButtonElement from "../../Layout/DesignSystem/ExportButton/Button";
import Storage from "../../Data/Domain/Storage";
import User from "../../Users/Domain/User";

export default function ProjectsBoard({ data }) {
  const project = useSelector(getProjectsSelector);
  const update_bonds = "update_bonds";
  const count = useSelector(getProjectsCountSelector);
  const [loading, setLoading] = useState(false);
  const [rol] = useState(new Storage("rol").getItem());
  const [values, setValues] = useState({
    page: 0,
    limit: 5,
  });
  const [filters, setFilters] = useState("false");

  const [form] = Form.useForm();

  const fetchData = async (params = {}) => {
    try {
      await getProjects(params);
      const filtros = params.filter ? JSON.stringify(params.filter) : [""];
      setFilters(filtros);
      if (Object.entries(params).length > 0) message.success("Filtrado");
      setLoading(false);
    } catch (error) {
      message.error("error");
    }
  };

  useEffect(() => {
    fetchData({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRequest = (newPagination = {}, filters = {}, sorter = {}) => {
    const { current: page = 1, pageSize: limit = 5 } = newPagination;
    const { field: sortField, order: sortOrder } = sorter;
    const clean = Formatter.cleanParamsFromTableToAPI({
      ...filters,
      limit,
      sortField,
      sortOrder,
      page: page - 1,
      dates: [],
    });
    setValues({
      ...values,
      limit,
      page: page - 1,
    });
    fetchData(clean);
  };

  const title = {
    marginBottom: 20,
  };

  const flex = {
    display: "flex",
  };

  const linkIcon = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "24px",
    marginTop: "2px",
  };

  const isPublic = (record) => {
    return (
      (rol !== User.PROJECTS_VIEWER || rol !== User.PROJECTS_SELLER) && (
        <>
          <div>
            {
              <Modal
                children={<ProjectLocation record={record} />}
                icon={
                  <Tooltip title="Ubicación del Proyecto">
                    <EnvironmentOutlined />
                  </Tooltip>
                }
                style={{ color: "red" }}
                title="Ubicación del Proyecto"
              />
            }
          </div>
          
          <div>
            {
              <Modal
                children={<ProjectUploadImage record={record} />}
                icon={
                  <Tooltip title="Imagen del Proyecto">
                    <FileImageOutlined />
                  </Tooltip>
                }
                style={{ color: "red" }}
                title="Imagen del Proyecto"
              />
            }
          </div>
          <div>
            {
              <Modal
                children={
                  <ProjectDetail
                    record={record}
                    update_bonds={update_bonds}
                    form={form}
                  />
                }
                icon={
                  <Tooltip title="Detalle de Proyectos">
                    <PlusSquareOutlined />
                  </Tooltip>
                }
                style={{ color: "red" }}
                title="Detalle de Proyectos"
                resetFields={update_bonds}
                form={form}
                cancelButtonProps={{ style: { display: "none" } }}
                okText="Cerrar"
              />
            }
          </div>
          <div>
            {
              <Modal
                icon={
                  <Tooltip title="Traducciones">
                    <GlobalOutlined />
                  </Tooltip>
                }
                style={{ color: "red" }}
                title="Traducciones"
                width={1000}
                cancelButtonProps={{ style: { display: "none" } }}
                okButtonProps={{ style: { display: "none" } }}
                children={<ProjectsTranslations record={record} />}
              />
            }
          </div>
          <div style={linkIcon}>
            {
              <Tooltip title="Descripción del Proyecto">
                <a
                  href={
                    record
                      ? `${URL.EGREEN_NEW_PAGE}/project/${record.id}`
                      : null
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ProfileOutlined style={{ color: "red", fontSize: "16px" }} />
                </a>
              </Tooltip>
            }
          </div>
          <div>
            {
              <Modal
                children={<ProjectEdit record={record} form={form} />}
                icon={
                  <Tooltip title="Editar Proyecto">
                    <EditOutlined />
                  </Tooltip>
                }
                style={{ color: "red" }}
                title="Editar Proyecto"
              />
            }
          </div>
          <div>
            {
              <Modal
                width="600px"
                children={<ProjectIPFS record={record} />}
                icon={
                  <Tooltip title="Hashes in IPFS">
                    <EnvironmentOutlined />
                  </Tooltip>
                }
                style={{ color: "red" }}
                title="Hashes in IPFS"
              />
            }
          </div>
        </>
      )
    );
  };
  const isPrivate = (record) => {
    return (
      <>
        {
          <div style={linkIcon}>
            <Tooltip title="URL del Proyecto">
              <a
                href={record ? `${record.url}` : null}
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkOutlined style={{ color: "red", fontSize: "16px" }} />
              </a>
            </Tooltip>
          </div>
        }
        {
          <div style={linkIcon}>
            <Tooltip title="Documento del Proyecto">
              <a
                href={record ? `${record.url_file}` : null}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FileWordOutlined style={{ color: "red", fontSize: "16px" }} />
              </a>
            </Tooltip>
          </div>
        }

        {rol !== User.PROJECTS_SELLER && rol !== User.PROJECTS_VIEWER && (
          <div>
            <Modal
              children={<ProjectLocation record={record} />}
              icon={
                <Tooltip title="Ubicación del Proyecto">
                  <EnvironmentOutlined />
                </Tooltip>
              }
              style={{ color: "red" }}
              title="Ubicación del Proyecto"
            />
          </div>
        )}
        {rol !== User.PROJECTS_SELLER && rol !== User.PROJECTS_VIEWER && (
          <div>
            <Modal
              children={<ProjectEdit record={record} form={form} />}
              icon={
                <Tooltip title="Editar Proyecto">
                  <EditOutlined />
                </Tooltip>
              }
              style={{ color: "red" }}
              title="Editar Proyecto"
            />
          </div>
        )}
      </>
    );
  };

  const columns = [
    {
      title: "Nombre",
      dataIndex: "project_name",
      key: "project_name",
    },
    {
      title: "ID",
      dataIndex: "standard_id",
      key: "standard_id",
    },
    {
      title: "Tipo",
      dataIndex: "privateValue",
      key: "privateValue",
      filters: [
        {
          text: "Publico",
          value: false,
        },
        {
          text: "Privado",
          value: true,
        },
      ],
    },
    {
      title: "Add On",
      dataIndex: "add_on",
      key: "add_on",
    },
    {
      title: "Ubicación",
      dataIndex: "country",
      key: "country",
    },
    {
      title: "Tecnologia",
      dataIndex: "technology",
      key: "technology",
    },
    {
      title: "Vintage",
      dataIndex: "vintage",
      key: "vintage",
    },
    {
      title: "CO2",
      dataIndex: "availableBonds",
      key: "availableBonds",
    },
    {
      title: "Precio",
      dataIndex: "tonPrice",
      key: "tonPrice",
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      onCell: (record) => {
        return {
          onClick: () => {
            getProject(record.id);
          },
        };
      },
      render: (record) => {
        return (
          <div style={flex}>
            {record.is_private && isPrivate(record)}{" "}
            {!record.is_private && isPublic(record)}{" "}
            <div>
              {rol !== User.PROJECTS_SELLER && rol !== User.PROJECTS_VIEWER && (
                <Modal
                  children={<NewContact record={record} form={form} />}
                  icon={
                    <Tooltip title="Nuevo Contacto">
                      <ContactsOutlined />
                    </Tooltip>
                  }
                  style={{ color: "red" }}
                  title="Nuevo Contacto"
                  resetFields={""}
                  form={form}
                  cancelButtonProps={{ style: { display: "none" } }}
                  okText="Cerrar"
                  width="auto"
                />
              )}
            </div>
          </div>
        );
      },
    },
  ];

  const columnsBasedOnRole = () => {
    const inner = columns.filter((element) => element.title !== "Precio");
    return rol === User.PROJECTS_SELLER ? inner : columns;
  };

  return (
    <main>
      <Row style={title}>
        <Col
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 20,
          }}
          span={24}
        >
          <Col
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 20,
            }}
            span={12}
          >
            <Title>Proyectos</Title>
            <NewProject />
          </Col>
          <Col
            span={12}
            style={{
              width: 112,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <ExportButtonElement
              type={"projects"}
              text={"Exportar"}
              filter={filters}
            />
          </Col>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            rowKey="id"
            pagination={{
              pageSize: values.limit,
              total: count,
              onChange: (current) => {
                //getProjects({ page: current - 1, values.limit });
              },
            }}
            onChange={handleRequest}
            dataSource={project}
            columns={columnsBasedOnRole()}
          />
        </Col>
      </Row>
    </main>
  );
}
