import {
  getRepositoryProjectsRepository,
  getRepositoryProjectByUserRepository,
  deleteRepositoryProjectRepository
} from "../Infrastructure/RepositoryProjects.repository";

export async function getRepositoryProjectsLogic(params) {
  return getRepositoryProjectsRepository(params);
}
export async function getRepositoryProjectByUserLogic(id) {
  return getRepositoryProjectByUserRepository(id);
}
export async function deleteRepositoryProjectLogic(id) {
  return deleteRepositoryProjectRepository(id);
}
