import React from "react";
import { Row, Col, Avatar, Typography, Button } from "antd";
import { UserOutlined, DeleteOutlined } from "@ant-design/icons";

const { Text } = Typography;

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    marginTop: "12px",
  },
  text: {
    fontFamily: "MontserratRegular",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    color: "#041370",
    marginLeft: "10px",
  },
  deleteIcon: {
    color: "#ff1e46",
    border: "solid 0px #f0f7fb",
    borderRadius: "0px",
  },
  emailContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
};

const UserBadge = ({ email, handleDelete }) => {
  return (
    <>
      <Row style={styles.container}>
        <Col span={20} style={styles.emailContainer}>
          <Avatar size={30} icon={<UserOutlined />} />
          <Text style={styles.text}>{email}</Text>
        </Col>
        <Col span={4}>
          <Button
            onClick={handleDelete}
            style={styles.deleteIcon}
            icon={<DeleteOutlined />}
          />
        </Col>
      </Row>
    </>
  );
};

export default UserBadge;
