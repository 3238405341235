import {
  getSuppliersLogic,
  getSuppliersByUserLogic,
  updateSupplierLogic,
  deleteSupplierLogic,
  getSubcategoriesBySuppliersAndUserLogic,
} from "./Suppliers.logic";
import {
  saveListSuppliers,
  saveListSuppliersBySubcategory,
  saveListSuppliersByUser,
} from "../Infrastructure/Suppliers.store";

export async function getSuppliers() {
  const { resources: suppliers } = await getSuppliersLogic();
  saveListSuppliers(suppliers);
}
export async function getSuppliersByUser(id) {
  const suppliers = await getSuppliersByUserLogic(id);
  saveListSuppliersByUser(suppliers);
}
export async function updateSupplier(id, params) {
  await updateSupplierLogic(id, params);
}

export async function deleteSupplier(id, params) {
  await deleteSupplierLogic(id, params);
}

export async function getSubcategoriesBySuppliersAndUser(id) {
  const suppliers = await getSubcategoriesBySuppliersAndUserLogic(id);
  saveListSuppliersBySubcategory(suppliers);
}
