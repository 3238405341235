import React, { useState, useEffect } from "react";
import { DatePicker, Input, message } from "antd";
import moment from "moment";
import Button from "../../Layout/DesignSystem/Button/Button";
import {
  getOffsets,
  patchManageData,
} from "../../DashboardOffset/Application/DashboardOffset.business";

const NewManageData = (props) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [emissionValue, setEmissionValue] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  useEffect(() => {
    setButtonDisabled(
      props.userId === undefined ||
      !selectedDate ||
      isNaN(parseFloat(emissionValue)) ||
      emissionValue === null
    );
  }, [props.userId, selectedDate, emissionValue]);

  const onChange = (date) => {
    setSelectedDate(date);
  };

  const handleUpdate = async () => {
    let data;
    const emission = parseFloat(emissionValue);

    try {
      data = [
        {
          op: "add",
          path: "/emission_per_year",
          value: {
            year: selectedDate
              ? parseInt(selectedDate.format("YYYY"), 10)
              : null,
            emission,
          },
        },
      ];
      await patchManageData(props.userId, data);
      // getOffsets(props.userId);
      message.success("Se actualizó con éxito");
    } catch (error) {
      message.error("Se produjo un error, vuelve a intentarlo");
    }
  };

  return (
    <div className="grid-NewManageData">
      <div className="item-NewManageData">
        <DatePicker
          className="picker-NewManageDate custom-date-picker"
          placeholder="Seleccionar año"
          onChange={onChange}
          picker="year"
          disabledDate={(current) => {
            return current && current > moment().endOf("year");
          }}
        />
      </div>
      <div className="item-NewManageData">
        <Input
          type="number"
          min={0}
          className="input-NewManageData custom-input"
          placeholder="Emisión(es)"
          onChange={(e) => {
            setEmissionValue(e.target.value);
          }}
        />
      </div>
      <div>
        <Button
          htmlType="submit"
          text="Agregar"
          onClick={handleUpdate}
          block={true}
          disabled={buttonDisabled}
        />
      </div>
    </div>
  );
};

export default NewManageData;
