import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Spin } from "antd";
import { getProject } from "../Application/Projects.business";
import { getSelectedProjectSelector } from "../Infrastructure/Projects.reducer";

const styles = {
  container: {
    maxWidth: "1200px",
    display: "flex",
    alignItems: "center",
    webkitJustifyContent: "center",
    justifyContent: "center",
    border: "1px solid #d9d9d9",
    borderRadius: "1em 1em 1em 1em",
  },
  item: {
    padding: "10px",
  },
  item2: {
    padding: "5px 10px 6px 0px",
    height: "100px",
    lineHeight: "100px",
    textAlign: "left",
  },
  image: {
    width: "100%",
    height: "auto",
    display: "block",
  },
  text: {
    color: "rgb(133, 133, 133)",
    display: "inline-block",
    verticalAlign: "middle",
    lineHeight: "normal",
  },
};
export default function ProjectReferenceField(props) {
  const { id } = props;

  useEffect(() => {
    getProject(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const project = useSelector(getSelectedProjectSelector);

  return (
    <div>
      {project ? (
        <div style={styles.container}>
          <div style={styles.item}>
            <img
              src={project.mainImage}
              alt={"main_image"}
              style={styles.image}
            />
          </div>
          <div style={styles.item2}>
            <p style={styles.text} color="blue">
              {" "}
              {project.project_name}
            </p>
          </div>
        </div>
      ) : (
        <Spin />
      )}
    </div>
  );
}
