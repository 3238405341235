import Storage from "../../Data/Domain/Storage";
import { login as loginService, resetPassword as resetPasswordService, finalizeResetPassword as finalizeResetPasswordService } from "../Infrastructure/Login.service";
import API from "../../Data/Domain/API";
import URL from "../../Data/Domain/URL";
import Response from "../../Data/Domain/Response";
import Role from "../../Utils/Domain/Role";

export async function login({client_id, password}) {
  const data = await loginService({
    client_id,
    password,
  });
  const {token, id} = data.data;
  new Storage("token").setItem(token);
  new Storage("id").setItem(id);
}
export async function resetPassword({ email }) {
  return await resetPasswordService({ email });
}
export async function logout() {
  new Storage("token").removeItem();
  new Storage("id").removeItem();
}

export async function auth() {
  return await new API(URL.buildUrl("auth"), {}).post({});
}

export async function role() {
  const data = await new API(URL.buildUrl("role"), {}).post({});
  return new Response(data, Role).getSingle();
}
export async function finalizeResetPassword({ email, code, new_password }) {
  return await finalizeResetPasswordService({ email, code, new_password });
}
