import React from "react";
import SectionUnderConstruction from "../SectionUnderConstruction/SectionUnderConstruction";

const Reports = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "calc(100vh - 21vh)",
      }}
    >
      <SectionUnderConstruction />
    </div>
  );
};

export default Reports;
