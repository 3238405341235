import React from "react";
import { Col, Row } from "antd";
import InfoCard from "../Layout/DesignSystem/InfoCard/InfoCard";

const Statistics = ({ statistics }) => {
  return (
    <>
      <Row style={{ marginLeft: 3 }} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        {statistics.map((statistic, index) => (
          <Col key={index} span={5} align="left">
            <InfoCard statistic={statistic} />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default Statistics;
