import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Col, Row } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { PlusCircleOutlined } from "@ant-design/icons";
import Viewer from "../../Viewer/Viewer";
import "./companiesBankDetails.css";
import InterfaceTable from "../../Table/InterfaceTable";
import { getBankAccountsCountSelector } from "../Infrastructure/CompaniesBankDetails.reducer";
import {
  getBankAccounts,
  getByCompany,
  deleteBankAccount,
} from "../Application/CompaniesBankDetails.business";
import { getBankAccountsSelector } from "../Infrastructure/CompaniesBankDetails.reducer";
import UploadCompaniesBankDetails from "./UploadCompaniesBankDetails";
import Modal from "../../Modal/Modal";

const CompaniesBankDetails = (props) => {
  const { user } = props;
  // Table
  const bankAccounts = useSelector(getBankAccountsSelector);
  useEffect(() => {
    getByCompany(user.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const count = useSelector(getBankAccountsCountSelector);
  const limit = 5;
  const onDeleteBankAccount = (key) => {
    Modal.confirm({
      title:
        "Está seguro de que desea eliminar este documento de cuentas bancarias?",
      okText: "Si",
      okType: "danger",
      onOk: () => {
        deleteBankAccount(key);
      },
    });
  };

  const filterBankAccounts = (bankAccounts) => () =>
    bankAccounts.map((bankAccount) => ({
      text: bankAccount.owner,
      value: bankAccount.owner,
    }));

  const columns = [
    {
      title: "Owner",
      dataIndex: "owner",
      key: "owner",
      responsive: ["xxl", "xl", "lg", "md", "sm", "xs"],
      filters: filterBankAccounts(bankAccounts)((i) => i.owner),
      onFilter: (value, record) => record.owner.indexOf(value) === 0,
    },
    {
      title: "Bank Name",
      dataIndex: "bank_name",
      key: "bank_name",
      responsive: ["xxl", "xl", "lg", "md", "sm", "xs"],
    },
    {
      title: "Archivo",
      dataIndex: "url",
      key: "url",
      responsive: ["xxl", "xl", "lg", "md", "sm", "xs"],
      render: (_, record) => <Viewer link={record.url} title={"ABRIR"} />,
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      responsive: ["xxl", "xl", "lg", "md", "sm", "xs"],

      render: (_, record) => {
        return (
          <>
            <DeleteOutlined
              onClick={() => {
                onDeleteBankAccount(record.key);
              }}
              style={{ color: "red" }}
            />
          </>
        );
      },
    },
  ];

  return (
    <>
      <Row className="create">
        <Col />
        <Col>
          <Modal
            width="auto"
            children={
              <Row type="flex" justify="center" align="middle">
                <UploadCompaniesBankDetails />
              </Row>
            }
            icon={<PlusCircleOutlined style={{ fontSize: "32px" }} />}
            style={{
              color: "#08c",
              marginLeft: "12px",
            }}
            title="Agregar documento de Cuentas Bancarias"
            cancelButtonProps={{ style: { display: "none" } }}
            okText="Cerrar"
          />
        </Col>
      </Row>
      <InterfaceTable
        pageSize={limit}
        total={count}
        onChange={(current) => {
          getBankAccounts({ page: current - 1, limit });
        }}
        data={bankAccounts}
        columns={columns}
      />
    </>
  );
};

export default CompaniesBankDetails;
