import React from "react";
import { useState } from "react";
import { Form, Input, Row, message } from "antd";
import Button from "../../Layout/DesignSystem/Button/Button";
import "./RepositoryProjects.css";
import { updateRepositoryProject } from "../Infrastructure/RepositoryProjects.service";
import { useDispatch } from "react-redux";
import { getRepositoryProjects } from "../Application/RepositoryProjects.business";

const { TextArea } = Input;

const RepositoryProjectDetail = ({ form, record }) => {
  const [name, setName] = useState(record.name);

  const dispatch = useDispatch();

  const onHandleChange = (event) => {
    setName(event.target.value);
  };

  const handleUpdate = async () => {
    try {
      console.log("Update repository");
      await updateRepositoryProject(record.id, { name });
      message.success("Se modificaron los datos correctamente");
      form.resetFields([name]);
    } catch (error) {
      message.error("Se produjo un error, vuelve a intentarlo");
      console.log(name);
      form.resetFields([name]);
    }
    dispatch(getRepositoryProjects());
  };
  const onReset = () => {
    form.resetFields();
  };

  return (
    <Row type="flex" justify="center" align="middle">
      <Form layout="vertical" responsive={"true"} form={form} onReset={onReset}>
        {" "}
        {record ? (
          <>
            <Form.Item label="Nombre:">
              <TextArea onChange={onHandleChange} value={name} />
            </Form.Item>

            <div className="update">
              <Button onClick={handleUpdate} text={"Actualizar"} />
            </div>
          </>
        ) : (
          ""
        )}
      </Form>
    </Row>
  );
};

export default RepositoryProjectDetail;
