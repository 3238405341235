import React, { useState } from "react";
import ModalInterface from "antd/lib/modal/Modal";
import { Button } from "antd";

const Modal = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    if (props.onCancel) {
      props.onCancel();
    }
  };

  const handleOk = () => {
    if (props.onOk) {
      props.onOk();
    }
    // close();
  };

  const handleSubmit = () => {
    if (props.form) {
      props.handleSubmit();
      props.form.resetFields([props.resetFields]);
    }
    handleOk();
  };

  const textFont = {
    fontFamily: "MontserratMedium",
  };

  const cancelButtonProps = props.cancelButtonProps
    ? props.cancelButtonProps
    : props.footer;
  const okButtonProps = props.okButtonProps
    ? props.okButtonProps
    : props.footer;

  return (
    <div>
      <Button
        type="link"
        icon={props.icon}
        style={props.style}
        onClick={showModal}
      />
      <ModalInterface
        title={props.title}
        style={textFont}
        visible={isModalVisible ? isModalVisible : props.visible}
        onCancel={handleCancel}
        width={props.width}
        cancelButtonProps={cancelButtonProps}
        okButtonProps={okButtonProps}
        centered
        onOk={handleOk}
        okText={props.okText}
        footer={
          props.footer || [
            <Button
              key="submit"
              type="primary"
              htmlType="submit"
              onClick={handleSubmit}
            >
              {props.text}
            </Button>,
          ]
        }
        onClose={props.onClose}
      >
        {props.children}
      </ModalInterface>
    </div>
  );
};

export default Modal;
