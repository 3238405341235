import { useState } from "react";
import { useDispatch } from "react-redux";
import { Form, Input, message } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import "./Collaborators.css";
import ModalInterface from "../../Modal/Modal";
import { postCollaborators } from "../Infrastructure/Collaborators.service";
import { getByCompany } from "../Application/Collaborators.business";
import Storage from "../../Data/Domain/Storage";

const iconStyle = {
  color: "green",
  fontSize: "24px",
};
const separatorStyle = {
  marginLeft: "4px",
};
const NewCollaborators = () => {
  const [form] = Form.useForm();

  const [values, setValues] = useState({
    name: "",
    lastname: "",
    email: "",
    password: "",
    company: new Storage("id").getItem(),
  });
  const dispatch = useDispatch();

  const onHandleChange = (input) => (e) => {
    setValues({
      ...values,
      [input]: e.target.value,
    });
  };

  async function handleSubmit() {
    const { name, lastname, email, password, company } = values;
    try {
      await postCollaborators({
        name,
        lastname,
        email,
        password,
        company,
      });
      setValues({ ...values, visible: false });
      message.success("Se agregó un nuevo Colaborador");
    } catch (error) {
      message.error(" Se produjo un error, vuelve a intentarlo");
    }
    dispatch(getByCompany(values.company));
  }

  const onReset = () => {
    form.resetFields();
  };

  return (
    <div>
      <ModalInterface
        children={
          <div className="form-container">
            <Form
              layout="vertical"
              responsive={"true"}
              onFinish={handleSubmit}
              form={form}
              onReset={onReset}
            >
              <Form.Item
                label="Nombre :"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese su nombre",
                  },
                ]}
              >
                <Input
                  name={"name"}
                  value={values.name}
                  onChange={onHandleChange("name")}
                />
              </Form.Item>
              <Form.Item
                label="Apellido :"
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese su apellido",
                  },
                ]}
              >
                <Input
                  name={"lastname"}
                  value={values.lastname}
                  onChange={onHandleChange("lastname")}
                />
              </Form.Item>
              <Form.Item
                label="Email :"
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "Por favor ingrese un email valido",
                  },
                  {
                    required: true,
                    message: "Por favor ingrese su email",
                  },
                ]}
              >
                <Input
                  name={"email"}
                  value={values.email}
                  onChange={onHandleChange("email")}
                />
              </Form.Item>
              <Form.Item
                label="Contraseña :"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese su contraseña",
                  },
                  {
                    min: 8,
                    message: "La contraseña debe tener al menos 8 caracteres",
                  },
                  // {
                  //     pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
                  //     message: 'La contraseña debe contener al menos una letra mayuscula, una letra minuscula y un número'
                  // }
                ]}
                hasFeedback
              >
                <Input.Password
                  name={"password"}
                  value={values.password}
                  onChange={onHandleChange("password")}
                />
              </Form.Item>
            </Form>
          </div>
        }
        icon={<PlusCircleOutlined style={iconStyle} />}
        style={separatorStyle}
        title="Nombre de los Colaboradores"
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        text="Agregar"
        handleSubmit={handleSubmit}
        form={form}
      />
    </div>
  );
};

export default NewCollaborators;
