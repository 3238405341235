class DataUser {
    constructor({
        assigned,
        category,
        down,
        final_date,
        initial_date,
        items,
        name,
        parent,
        percent,
        quantity,
        subcategory,
        type,
        up,
        user,
        scope,
        subScope,
    }) {
        this.assigned = assigned;
        this.category = category;
        this.down = down;
        this.final_date = final_date;
        this.initial_date = initial_date;
        this.items = items;
        this.name = name;
        this.parent = parent;
        this.percent = percent;
        this.quantity = quantity;
        this.subcategory = subcategory;
        this.type = type;
        this.up = up;
        this.user = user;
        this.scope = scope;
        this.subScope = subScope;
    }
}
export default DataUser;
