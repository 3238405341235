import { getOffersLogic, getOfferLogic } from "./Offers.logic";
import {
  saveCountOffers,
  saveListOffers,
  saveSelectedOffer,
} from "../Infrastructure/Offers.store";

export async function getOffers(params) {
  const { count, resources: offers } = await getOffersLogic(params);
  saveListOffers(offers);
  saveCountOffers(count);
}

export async function getOffer(id) {
  const offer = await getOfferLogic(id);
  saveSelectedOffer(offer);
}
