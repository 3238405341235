import React from "react";
// import Switch from "./Switch/Switch";
import Assignation from "./Assignation/Assignation";
import ExportButtonElement from "./Layout/DesignSystem/ExportButton/Button";
// import { PlusOutlined } from "@ant-design/icons";
// import { Button, Divider, Input, Select, Space } from "antd";
// import { useRef, useState } from "react";
// let index = 0;

const Test = () => {
  // const [items, setItems] = useState([
  //   {
  //     label: "comentario",
  //     value: "comentario",
  //   },
  //   {
  //     label: "tarea 2",
  //     value: "tarea 2",
  //   },
  //   {
  //     label: "tarea 3",
  //     value: "tarea 3",
  //   },

  // ]);
  // const [name, setName] = useState('');
  // const inputRef = useRef(null);
  // const onNameChange = (event) => {
  //   setName(event.target.value);
  // };
  // const addItem = (e) => {
  //   e.preventDefault();
  //   setItems([...items, name || `New item ${index++}`]);
  //   setName('');
  //   setTimeout(() => {
  //     inputRef.current?.focus();
  //   }, 0);
  // };

  return (
    <div>
      <span style={{ width: 112 }}>
        <ExportButtonElement
          type={"projetcs"}
          text={"Exportar"}
        />
      </span>
      <Assignation text="BGI Desarrollo Inmobiliario Spa" />
    </div>
  );
};

export default Test;
