class FetchData {
    constructor({
        providerName,
        documentName,
        businessName,
        amountTotal,
        accountName,
        businessCenterName
    }) {
        this.providerName = providerName;
        this.documentName = documentName;
        this.businessName = businessName;
        this.amountTotal = amountTotal;
        this.accountName = accountName;
        this.businessCenterName = businessCenterName;
    }
  }
  export default FetchData;