import React, { useState } from "react";
import { Form, Row, Select } from "antd";
import DocumentUpload from "../../DocumentUpload/UI/DocumentUpload";
import Document from "../../DocumentUpload/Domain/Document";

export default function ProjectUploadImage({ form, record }) {
  const [type, setType] = useState(Document.MAIN_IMAGE_PROJECT);

  const onHandleChange = (input) => (value) => {
    console.log("tipo:", value);
    setType(value);
  };
  return (
    <Row type="flex" justify="center" align="middle">
      <Form
        name="basic"
        labelCol={{
          span: 12,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
          update_bonds: 0,
        }}
        autoComplete="off"
        form={form}
      >
        {" "}
        {record && (
          <>
            <Select
              defaultValue={Document.MAIN_IMAGE_PROJECT}
              name={"type"}
              style={{ width: "100%" }}
              onChange={onHandleChange(type)}
              options={[
                {
                  value: Document.MAIN_IMAGE_PROJECT,
                  label: "Imagen principal",
                },
                {
                  value: Document.FIRST_IMAGE_SENSORS,
                  label: "Primer imagen de sensores",
                },
                {
                  value: Document.SECOND_IMAGE_SENSORS,
                  label: "Segunda imagen de sensores",
                },
                {
                  value: Document.THIRD_IMAGE_SENSORS,
                  label: "Tercer imagen de sensores",
                },
              ]}
            />
            <DocumentUpload
              type={type}
              id={record.id}
              buttonText="Seleccionar Archivo "
            />
          </>
        )}
      </Form>
    </Row>
  );
}
