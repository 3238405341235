import {
  getAreasRepository,
  getAreasByUserRepository,
  updateAreaRepository,
  deleteAreaRepository,
} from "../Infrastructure/Areas.repository";

export async function getAreasLogic(params) {
  return getAreasRepository(params);
}
export async function getAreasByUserLogic(id) {
  return getAreasByUserRepository(id);
}
export async function updateAreaLogic(id, params) {
  return updateAreaRepository(id, params);
}

export async function deleteAreaLogic(id) {
  return deleteAreaRepository(id);
}
