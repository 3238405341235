class RepositoryProject {
  constructor({ _id, name, quantity, percent }) {
    this.id = _id;
    this.name = name;
    this.quantity = quantity;
    this.percent = percent;
  }
}

export default RepositoryProject;
