import { postImpacts, postSdgs, postKeys } from "../Infrastructure/Projects.service";

class GeneralInformationMaker {
  constructor({ title, data, type, id }) {
    this.title = title;
    this.data = data;
    this.type = type;
    this.id = id; // project
    this.services = {
      impact: postImpacts,
      sdg: postSdgs,
      key: postKeys,
      projectDescription: postKeys,
    };
  }

  setServices({ key, service }) {
    this.services = { ...this.services, [key]: service };
    return this;
  }

  setup() {
    switch (this.type) {
      case "impact":
        return {
          id: this.id,
          title: this.title,
          subtitle: this.data,
        };
      case "sdg":
        return {
          id: this.id,
          title: this.title,
          data: this.data,
        };
      case "key":
        return {
          id: this.id,
          name: this.title,
          data: this.data,
          type: "key",
        };
      case "projectDescription":
        return {
          id: this.id,
          name: this.title,
          data: this.data,
          type: "projectDescription",
        };
       default:
        return "Error";
    }
  }

  async getService() {
    return this.services[this.type];
  }
}

export default GeneralInformationMaker;
