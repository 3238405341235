import API from "../../Data/Domain/API";
import URL from "../../Data/Domain/URL";
import Storage from "../../Data/Domain/Storage";

export async function getOffsets(id) {
  return await new API(URL.buildUrl(`users/${id}/offsets`)).get();
}
export async function patchManageData(id, data) {
  return await new API(URL.buildUrl(`users/${id}/offsets`)).patch(data);
}
