import API from "../../Data/Domain/API";
import URL from "../../Data/Domain/URL";

export async function getScriptOffsetByUser(userId, params) {
  const url = URL.setParams(URL.buildUrl(`users/${userId}/scriptOffsets`), params);
  console.log("url:", url);
  try {
    const response = await new API(url).get();
    console.log("DATA:", response);
    return response;
  } catch (error) {
    console.error("Service Error:", error);
    throw error;
  }
}

export async function updateScriptOffset(id, data) {
  const url = URL.setId(URL.buildUrl("scriptOffsets"), id);
  console.log("url:", data);
  try {
    const response = await new API(url).put(data);
    return response.data;
    
  } catch (error) {
    console.error("Service Error:", error);
    throw error;
  }
}