import {
  saveListUsers,
  saveSelectedUser,
  saveCountUsers,
  saveCountWithoutTransactions,
} from "../Infrastructure/Users.store";
import {
  deleteUserLogic,
  getUserLogic,
  getUsersLogic,
  updateEnabledLogic,
  updateLumensLogic
} from "./Users.logic";

export async function getUsers(params) {
  const {
    count_without_transactions,
    count,
    resources: users,
  } = await getUsersLogic(params);
  saveListUsers(users);
  saveCountUsers(count);
  saveCountWithoutTransactions(count_without_transactions);
}

export async function getUser(id) {
  const user = await getUserLogic(id);
  saveSelectedUser(user);
}

export async function deleteUser(id, params) {
  await deleteUserLogic(id, params);
  const user = await getUserLogic(id);
  saveSelectedUser(user);
}

export async function updateEnabledUser(id, params) {
  await updateEnabledLogic(id, params);
  const user = await getUserLogic(id);
  saveSelectedUser(user);
}
export async function updateLumens(id, params) {
  await updateLumensLogic(id, params);
  const user = await getUsersLogic(id);
  saveSelectedUser(user);
}