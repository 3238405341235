import { Input, Form, Select, Row, Col, Tooltip } from "antd";
import { React, useEffect, useState } from "react";
import { getSubcategoryByIdRepository } from "../Infrastructure/Subcategories.repository";

const EditableItem = ({ id }) => {
  const [data, setData] = useState({});
  const [total, setTotal] = useState(0);
  const [form] = Form.useForm();

  useEffect(() => {
    getSubcategoryByIdRepository(id).then((dataFromService) => {
      console.log({ data });
      setData(dataFromService);
      form.setFieldsValue(dataFromService);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChangeAmount(e) {
    const amount = e.target.value;
    const two_percent = amount * 0.02;
    const tons = two_percent / 5;
    const total_kilos = tons * 1000;
    setTotal(total_kilos);
  }

  return (
    <Form
      form={form}
      style={{ maxWidth: "100%" }}
      layout={"inline"}
      initialValues={data}
    >
      <Row>
        <Col span={4}>
          <Form.Item name="name" label="name">
            <Input placeholder="Subcategory Name" disabled />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item label="factor" name="factor">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="scope" label="scope">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item label="unit" name={"unit"}>
            <Input placeholder="unit" disabled />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Cálculo">
            <Select defaultValue={"DEFRA"}>
              <Select.Option value="DEFRA">DEFRA</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Cantidad">
            <Tooltip title="Cantidad tota de factura en USD o cantidad total de unidad">
              <Input
                onChange={onChangeAmount}
                defaultValue={0}
                placeholder="Cantidad"
              />
            </Tooltip>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Offset (Kilos)">
            <Input placeholder="Offset (Kilos)" value={total} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default EditableItem;
