class BankAccount {
  constructor({
    _id,
    owner,
    bank_name,
    sort_code,
    account_number,
    swiftbic,
    iba,
    bank_account_details,
    user,
    url,
  }) {
    this.id = _id;
    this.key = _id;
    this.owner = owner;
    this.bank_name = bank_name;
    this.sort_code = sort_code;
    this.account_number = account_number;
    this.swiftbic = swiftbic;
    this.iba = iba;
    this.bank_account_details = bank_account_details;
    this.user = user;
    this.url = url;
  }
}
export default BankAccount;
