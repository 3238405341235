class Collaborator {
  constructor({
    _id,
    type,
    access_level,
    // withdrew_usd,
    // available_usd,
    enabled,
    verified,
    profile_picture,
    name,
    lastname,
    email,
    publicKey,
    // account_stripe_connect,
    company,
    __v,
  }) {
    this.id = _id;
    this.type = type;
    this.access_level = access_level;
    this.withdrew_usd = 0;
    this.available_usd = 0;
    this.enabled = enabled;
    this.verified = verified;
    this.profile_picture = profile_picture;
    this.name = name;
    this.lastname = lastname;
    this.email = email;
    this.publicKey = publicKey;
    this.account_stripe_connect = null;
    this.company = company;
    this.__v = __v;
  }
}

export default Collaborator;
