import {
  saveListRepositoryProjects,
  saveCountRepositoryProjects,
  deleteSelectedRepositoryProject,
  saveListRepositoryProjectsByUser
} from "../Infrastructure/RepositoryProjects.store";
import {
  getRepositoryProjectsLogic,
  getRepositoryProjectByUserLogic,
  deleteRepositoryProjectLogic
} from "./RepositoryProjects.logic";

export async function getRepositoryProjects(params) {
  const { count, resources: repositoryProjects } =
    await getRepositoryProjectsLogic(params);
  saveListRepositoryProjects(repositoryProjects);
  saveCountRepositoryProjects(count);
}
export async function getRepositoryProjectByUser(id) {
  const repositoryProjects = await getRepositoryProjectByUserLogic(id);
  saveListRepositoryProjectsByUser(repositoryProjects);
}
export async function deleteRepositoryProject(id) {
  await deleteRepositoryProjectLogic(id);
  deleteSelectedRepositoryProject(id);
}
