import moment from "moment";

class Company {
  constructor({
    id,
    _id,
    type,
    access_level,
    name,
    email,
    lastname,
    profile_picture,
    verified,
    enabled,
    publicKey,
    withdrew_usd,
    extras = {},
    account = {},
  }) {
    this.id = id || _id;
    this.name = name;
    this.email = email;
    this.lastname = lastname;
    this.profile_picture = profile_picture;
    this.type = type;
    this.access_level = access_level;
    this.verified = verified;
    this.enabled = enabled;
    this.publicKey = publicKey;
    this.withdrew_usd = withdrew_usd;
    this.extras = extras;
    this.account = account;
  }

  get verifiedValue() {
    switch (this.verified) {
      case "starter":
        return "No iniciado";
      case "pending":
        return "Pendiente";
      case "rejected":
        return "Rechazado";
      case "succeeded":
        return "Verificado";
      case "data_loaded":
        return "Información inicial cargada";
      default:
        return "No iniciado";
    }
  }

  get enabledValue() {
    return this.enabled ? "Activado" : "Desactivado";
  }

  get companyNameForSearcher() {
    return `${this.email} (${this.name})`;
  }

  get companyExtras() {
    const {
      legal_name = "",
      country = "",
      operation_place = "",
      business_type = "",
      employees_number = "",
      website = "",
      description = "",
      company_id_code,
      company_id,
      registration,
      bank_account_details,
      legal_representation,
      personal_id,
      last_statement,
      local_tax_authority,
      date = moment(),
    } = this.extras;
    return {
      legal_name,
      country,
      operation_place,
      business_type,
      employees_number,
      website,
      description,
      company_id_code,
      company_id,
      registration,
      bank_account_details,
      legal_representation,
      personal_id,
      last_statement,
      local_tax_authority,
      date: moment(date),
    };
  }

  get companyAccount() {
    const {
      owner = "",
      bank_name = "",
      sort_code = "",
      account_number = "",
      swiftbic = "",
      iba = "",
    } = this.account;
    return {
      owner,
      bank_name,
      sort_code,
      account_number,
      swiftbic,
      iba,
    };
  }
}

export default Company;
